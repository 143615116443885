import { sortBy, intersection } from 'lodash';
import { SubscriptionContract } from '@/gen';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';
import { getSubscriptionPackageEndDt } from '@/common/subscriptionUtils';
import dayjs from 'dayjs';

export class PremiumExpendableCoupon {
  constructor(
    public id: string,
    public name: string,
    public summary: string,
    public explanation: string,
    public caution: string | undefined,
    public authCode: string | undefined,
    public availableQuantityPerDay: number | undefined,
    public usageAmountForToday: number,
    public thumbnailURL: string,
    public mainImageURL: string,
    public logoURL: string,
    public subscriptionPackages: { id: string; name: string }[],
    public stores: { id: string; name: string }[] | undefined,
    public onceFlg: boolean,
    public distributionStartDt: Date,
    public distributionEndDt: Date,
    public absoluteExpirationDt: Date,
    public validityMonths: number,
    public distributionInterval: number,
    public distributionQuantity: number,
    public distributions: {
      subscriptionPackage: { id: string; name: string };
      activationDt: Date;
      expirationDt: Date;
      remainingQuantity: number;
    }[],
    public nextDistributions: {
      subscriptionPackage: { id: string; name: string };
      dt: Date;
    }[],
    public firstDistributionDt: string,
    public hideRemainingFlg: boolean
  ) {}

  get nextExpiration(): { dt: Date; count: number } | undefined {
    if (this.distributions.length === 0) {
      return undefined;
    }

    const distributions = sortBy(this.distributions, d =>
      d.expirationDt.getTime()
    );
    const dist = distributions[0];
    const count = distributions
      .filter(d => d.expirationDt.getTime() === dist.expirationDt.getTime())
      .reduce((acc, cur) => acc + cur.remainingQuantity, 0);
    return { dt: dist.expirationDt, count };
  }

  get nextDistributionDt(): Date | undefined {
    if (this.nextDistributions.length === 0) {
      return undefined;
    }

    const distributions = sortBy(this.nextDistributions, d => d.dt.getTime());
    return distributions[0].dt;
  }

  get expenditureCodeRequired(): boolean {
    return !!this.authCode;
  }

  get remainingQuantity(): number {
    return this.distributions.reduce(
      (acc, cur) => acc + cur.remainingQuantity,
      0
    );
  }

  get availableQuantityForToday(): number {
    const availableQuantityPerDay =
      typeof this.availableQuantityPerDay === 'number'
        ? this.availableQuantityPerDay
        : Number.POSITIVE_INFINITY;
    return Math.max(
      0,
      Math.min(
        this.remainingQuantity,
        availableQuantityPerDay - this.usageAmountForToday
      )
    );
  }

  getSubscriptionPackages(
    packageList: Array<SubscriptionPackageItem>
  ): { id: string; name: string }[] {
    return this.subscriptionPackages.filter(
      p => !dayjs().isAfter(getSubscriptionPackageEndDt(packageList, p.id))
    );
  }

  getSubscriptionPackageExample(
    packageList: Array<SubscriptionPackageItem>
  ): { id: string; name: string } {
    const subscriptionPackages = this.getSubscriptionPackages(packageList);
    if (subscriptionPackages.length === 0) {
      return { id: '', name: '' };
    }
    return subscriptionPackages[0];
  }

  subFromAvailableQuantity(count: number) {
    const distributions = sortBy(this.distributions, [
      d => d.expirationDt.getTime(),
      d => d.subscriptionPackage.id
    ]);
    // 有効期限が短いものから順に、残数を減らしていく。
    // この関数のみを見ると総残数が利用数を下回ることも有り得るが、呼び出し元で制御されていることを前提とし、ここでは考慮しない。
    for (let i = 0; i < distributions.length && count > 0; i++) {
      const dist = distributions[i];
      if (dist.remainingQuantity > count) {
        dist.remainingQuantity -= count;
        count = 0;
      } else {
        count -= dist.remainingQuantity;
        dist.remainingQuantity = 0;
      }
    }
    for (const dist of distributions) {
      const target = this.distributions.find(
        t => t.subscriptionPackage.id === dist.subscriptionPackage.id
      );
      if (target) {
        target.remainingQuantity = dist.remainingQuantity;
      }
    }
    // 残数が0になった配布は削除する。
    // この操作によりdistributionsが空になり、かつnextDistributionsも空であった場合、一覧に表示しなくなる。
    // ただし、消し込みクーポンのインスタンス自体は削除せず、あくまで表示をしないだけであるため、
    // 画面で保持しているクーポン情報に不整合は発生せず、ページネーションの挙動などに影響を与えることはない。
    this.distributions = this.distributions.filter(
      d => d.remainingQuantity > 0
    );
  }

  authorize(expenditureCode: string): boolean {
    if (!this.expenditureCodeRequired) {
      return true;
    }

    return !!this.authCode && expenditureCode.startsWith(this.authCode);
  }

  hasSubscriptionPackage(subscriptionPackageID: string): boolean {
    const subscriptionPackageIDs = this.subscriptionPackages.map(p => p.id);
    return subscriptionPackageIDs.includes(subscriptionPackageID);
  }

  hasStore(storeID: string): boolean {
    if (!this.stores) {
      return false;
    }

    const storeIDs = this.stores.map(store => store.id);
    return storeIDs.includes(storeID);
  }

  isAvailable(activeSubscriptionPackageIDs: string[]): boolean {
    const targetIDs = this.subscriptionPackages.map(p => p.id);
    return intersection(activeSubscriptionPackageIDs, targetIDs).length > 0;
  }

  // nextDistributionsには退会後に配布されるクーポンも配布対象として含まれる。
  // そのため、退会フラグが立っているサブスクパッケージのうち、配布日時が退会後のクーポンを削除する。
  removeUnsubscribedNextDistributions(
    contractsToBeUnsubscribed: Array<SubscriptionContract>
  ) {
    this.nextDistributions = this.nextDistributions.filter(d => {
      const unsubscribeDt = contractsToBeUnsubscribed.find(
        c => c.subscription_package_id === d.subscriptionPackage.id
      )?.end_dt;
      // 配布対象パッケージの退会予約がなければ削除しない。
      if (!unsubscribeDt) {
        return true;
      }
      // 配布予定日が配布対象パッケージの退会予約日よりも前であれば削除しない。
      return d.dt < new Date(unsubscribeDt);
    });
  }
}
