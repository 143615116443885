





























import { Component, Prop, Vue } from 'vue-property-decorator';
import IntroOdekakePointPanel from '@/components/SignupComplete/Intro/IntroOdekakePointPanel.vue';
import IntroOneIdPanel from '@/components/SignupComplete/Intro/IntroOneIdPanel.vue';
import IntroOnePanel from '@/components/SignupComplete/Intro/IntroOnePanel.vue';
import WarnUnsyncedPointOnlyRealCardPanel from '@/components/SignupComplete/WarnCardType/WarnUnsyncedPointOnlyRealCardPanel.vue';
import WarnCreditCardPanel from '@/components/SignupComplete/WarnCardType/WarnCreditCardPanel.vue';

@Component({
  components: {
    IntroOdekakePointPanel,
    IntroOneIdPanel,
    IntroOnePanel,
    WarnUnsyncedPointOnlyRealCardPanel,
    WarnCreditCardPanel
  }
})
export default class SubscriptionPanel extends Vue {
  @Prop()
  readonly warnCardTypeComponent?: string;

  @Prop()
  readonly subscriptionPackageId?: string;
}
