// tslint:disable
/**
 * backend-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.2-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 小田急でんき契約（管理画面用）
 * @export
 * @interface AdminElectricityContract
 */
export interface AdminElectricityContract {
    /**
     * ■CISお客様番号
     * @type {string}
     * @memberof AdminElectricityContract
     */
    cis_customer_no?: string;
    /**
     * ■契約番号
     * @type {string}
     * @memberof AdminElectricityContract
     */
    contract_no?: string;
    /**
     * 契約ステータス区分
     * @type {string}
     * @memberof AdminElectricityContract
     */
    contract_status_type?: string;
    /**
     * 供給地点特定番号
     * @type {string}
     * @memberof AdminElectricityContract
     */
    supply_point_no?: string;
    /**
     * 契約メニュー名
     * @type {string}
     * @memberof AdminElectricityContract
     */
    contract_menu_name?: string;
    /**
     * 契約申込日
     * @type {string}
     * @memberof AdminElectricityContract
     */
    contract_entry_ymd?: string | null;
    /**
     * 供給開始日
     * @type {string}
     * @memberof AdminElectricityContract
     */
    supply_start_ymd?: string | null;
    /**
     * 契約開始日
     * @type {string}
     * @memberof AdminElectricityContract
     */
    contract_start_ymd?: string | null;
    /**
     * 契約終了日
     * @type {string}
     * @memberof AdminElectricityContract
     */
    contract_end_ymd?: string | null;
    /**
     * 契約者氏名
     * @type {string}
     * @memberof AdminElectricityContract
     */
    contractor_name?: string;
    /**
     * 契約者氏名（カナ）
     * @type {string}
     * @memberof AdminElectricityContract
     */
    contractor_kana_name?: string;
    /**
     * 契約者電話番号
     * @type {string}
     * @memberof AdminElectricityContract
     */
    contractor_phone?: string;
    /**
     * 契約者郵便番号
     * @type {string}
     * @memberof AdminElectricityContract
     */
    contractor_zipcode?: string;
    /**
     * 契約者住所
     * @type {string}
     * @memberof AdminElectricityContract
     */
    contractor_addr?: string;
    /**
     * 契約者メールアドレス
     * @type {string}
     * @memberof AdminElectricityContract
     */
    contractor_mail_address?: string;
    /**
     * 需要者氏名
     * @type {string}
     * @memberof AdminElectricityContract
     */
    consumer_name?: string;
    /**
     * 需要者氏名（カナ）
     * @type {string}
     * @memberof AdminElectricityContract
     */
    consumer_kana_name?: string;
    /**
     * 需要者電話番号
     * @type {string}
     * @memberof AdminElectricityContract
     */
    consumer_phone?: string;
    /**
     * 需要者郵便番号
     * @type {string}
     * @memberof AdminElectricityContract
     */
    consumer_zipcode?: string;
    /**
     * 需要者住所
     * @type {string}
     * @memberof AdminElectricityContract
     */
    consumer_addr?: string;
    /**
     * 設備情報取得状況
     * @type {string}
     * @memberof AdminElectricityContract
     */
    facility_info_obtain_situation?: string;
    /**
     * スイッチング廃止取次登録状況
     * @type {string}
     * @memberof AdminElectricityContract
     */
    sw_disuse_direction_create_situation?: string;
    /**
     * スイッチング開始申請状況
     * @type {string}
     * @memberof AdminElectricityContract
     */
    sw_start_request_situation?: string;
    /**
     * 再点申請状況
     * @type {string}
     * @memberof AdminElectricityContract
     */
    counter_check_request_situation?: string;
    /**
     * アンペア変更申請状況
     * @type {string}
     * @memberof AdminElectricityContract
     */
    ampere_chg_request_situation?: string;
    /**
     * 契約アンペア変更予定日
     * @type {string}
     * @memberof AdminElectricityContract
     */
    contract_ampere_chg_expected_ymd?: string | null;
    /**
     * 需要者情報変更申請状況
     * @type {string}
     * @memberof AdminElectricityContract
     */
    consumer_info_chg_request_situation?: string;
    /**
     * 廃止・撤去申請状況
     * @type {string}
     * @memberof AdminElectricityContract
     */
    disuse_request_situation?: string;
    /**
     * 登録トランザクションID
     * @type {string}
     * @memberof AdminElectricityContract
     */
    create_transaction_id?: string;
    /**
     * 更新トランザクションID
     * @type {string}
     * @memberof AdminElectricityContract
     */
    update_transaction_id?: string;
}
/**
 * ガス契約
 * @export
 * @interface AdminGasContract
 */
export interface AdminGasContract {
    /**
     * CISお客様番号
     * @type {string}
     * @memberof AdminGasContract
     */
    cis_customer_no?: string;
    /**
     * 契約ステータス区分
     * @type {string}
     * @memberof AdminGasContract
     */
    contract_status_type?: string;
    /**
     * 供給地点特定番号
     * @type {string}
     * @memberof AdminGasContract
     */
    supply_point_no?: string;
    /**
     * 契約メニュー名
     * @type {string}
     * @memberof AdminGasContract
     */
    contract_menu_name?: string;
    /**
     * 契約開始日
     * @type {string}
     * @memberof AdminGasContract
     */
    contract_start_ymd?: string | null;
    /**
     * 契約終了日
     * @type {string}
     * @memberof AdminGasContract
     */
    contract_end_ymd?: string | null;
    /**
     * 需要者氏名
     * @type {string}
     * @memberof AdminGasContract
     */
    consumer_name?: string;
    /**
     * 需要者氏名（カナ）
     * @type {string}
     * @memberof AdminGasContract
     */
    consumer_kana_name?: string;
    /**
     * 需要者電話番号
     * @type {string}
     * @memberof AdminGasContract
     */
    consumer_phone?: string;
    /**
     * 需要者FAX番号
     * @type {string}
     * @memberof AdminGasContract
     */
    consumer_fax_no?: string;
    /**
     * 需要者郵便番号
     * @type {string}
     * @memberof AdminGasContract
     */
    consumer_zipcode?: string;
    /**
     * 需要者住所
     * @type {string}
     * @memberof AdminGasContract
     */
    consumer_address?: string;
    /**
     * 需要者メールアドレス
     * @type {string}
     * @memberof AdminGasContract
     */
    consumer_mail_address?: string;
    /**
     * 契約者住所区分
     * @type {string}
     * @memberof AdminGasContract
     */
    contractor_addr_typ?: string;
    /**
     * 契約者氏名
     * @type {string}
     * @memberof AdminGasContract
     */
    contractor_name?: string;
    /**
     * 契約者氏名（カナ）
     * @type {string}
     * @memberof AdminGasContract
     */
    contractor_kana_name?: string;
    /**
     * 契約者電話番号
     * @type {string}
     * @memberof AdminGasContract
     */
    contractor_phone?: string;
    /**
     * 契約者FAX番号
     * @type {string}
     * @memberof AdminGasContract
     */
    contractor_fax_no?: string;
    /**
     * 契約者郵便番号
     * @type {string}
     * @memberof AdminGasContract
     */
    contractor_zipcode?: string;
    /**
     * 契約者住所
     * @type {string}
     * @memberof AdminGasContract
     */
    contractor_address?: string;
    /**
     * 契約者メールアドレス
     * @type {string}
     * @memberof AdminGasContract
     */
    contractor_mail_address?: string;
    /**
     * 請求先住所区分
     * @type {string}
     * @memberof AdminGasContract
     */
    billing_dest_addr_typ?: string;
    /**
     * 請求先氏名
     * @type {string}
     * @memberof AdminGasContract
     */
    billing_dest_name?: string;
    /**
     * 請求先氏名（カナ）
     * @type {string}
     * @memberof AdminGasContract
     */
    billing_dest_kana_name?: string;
    /**
     * 請求先電話番号
     * @type {string}
     * @memberof AdminGasContract
     */
    billing_dest_phone?: string;
    /**
     * 請求先FAX番号
     * @type {string}
     * @memberof AdminGasContract
     */
    billing_dest_fax_no?: string;
    /**
     * 請求先郵便番号
     * @type {string}
     * @memberof AdminGasContract
     */
    billing_dest_zipcode?: string;
    /**
     * 請求先住所
     * @type {string}
     * @memberof AdminGasContract
     */
    billing_dest_address?: string;
    /**
     * 請求先メールアドレス
     * @type {string}
     * @memberof AdminGasContract
     */
    billing_dest_mail_address?: string;
    /**
     * エリアID
     * @type {string}
     * @memberof AdminGasContract
     */
    area_name?: string;
    /**
     * 支払方法
     * @type {string}
     * @memberof AdminGasContract
     */
    payment_method_name?: string;
    /**
     * 提供状況
     * @type {string}
     * @memberof AdminGasContract
     */
    offer_situation_name?: string;
    /**
     * 契約状況
     * @type {string}
     * @memberof AdminGasContract
     */
    contract_situation_name?: string;
    /**
     * 接続供給開始日
     * @type {string}
     * @memberof AdminGasContract
     */
    connection_supply_start_ymd?: string | null;
    /**
     * 登録トランザクションID
     * @type {string}
     * @memberof AdminGasContract
     */
    create_transaction_id?: string;
    /**
     * 更新トランザクションID
     * @type {string}
     * @memberof AdminGasContract
     */
    update_transaction_id?: string;
}
/**
 * イベント内選択肢
 * @export
 * @interface EventOption
 */
export interface EventOption {
    /**
     * イベント内選択肢ID
     * @type {string}
     * @memberof EventOption
     */
    event_option_id: string;
    /**
     * 大人申込人数
     * @type {number}
     * @memberof EventOption
     */
    adult_num: number;
    /**
     * 子供申込人数 0の時は子供の参加人数が0人 null(key自体がない)の場合はそもそもadult/childの申込区分け自体がないイベント
     * @type {number}
     * @memberof EventOption
     */
    child_num?: number;
    /**
     * 合計金額
     * @type {number}
     * @memberof EventOption
     */
    pay_amount: number;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 時刻の操作 - `fix`     時刻を固定する操作（時刻が止まる） - `set`     時刻を指定する操作（時刻が進む） - `add`     設定されている時刻に時間を足す操作 - `restore` 時刻を現在時刻にする操作
     * @type {string}
     * @memberof InlineObject
     */
    operation: InlineObjectOperationEnum;
    /**
     * 時刻の設定値（タイムゾーンも指定すること） - `2022-02-09T08:00:00` operation が fix, set の場合はこの形式 - `2h`                  operation が add の場合は golang の duration にパースできる形式 - operation が restore の場合は入力不要（入力してもパラメタが使われない）
     * @type {string}
     * @memberof InlineObject
     */
    time?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineObjectOperationEnum {
    Fix = 'fix',
    Restore = 'restore',
    Set = 'set',
    Add = 'add'
}

/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject2
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 変更予定の新しいメールアドレス
     * @type {string}
     * @memberof InlineObject3
     */
    mail_address?: string;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * サブスクパッケージID
     * @type {string}
     * @memberof InlineObject4
     */
    subscription_package_id: string;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 決済トークン
     * @type {string}
     * @memberof InlineObject5
     */
    pay_token: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    message?: InlineResponse200MessageEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse200MessageEnum {
    OK = 'OK'
}

/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    time?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    message?: InlineResponse2003MessageEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse2003MessageEnum {
    Success = 'Success'
}

/**
 * 
 * @export
 * @interface InlineResponse500
 */
export interface InlineResponse500 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse500
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * Error Code
     * @type {number}
     * @memberof ModelError
     */
    code?: number;
    /**
     * Error Message
     * @type {string}
     * @memberof ModelError
     */
    message?: string;
}
/**
 * ユーザ情報取得（管理画面用）
 * @export
 * @interface ReqAdminUserUpdate
 */
export interface ReqAdminUserUpdate {
    /**
     * 氏
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    last_name?: string;
    /**
     * 名
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    first_name?: string;
    /**
     * 氏（ひらがな）
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    hira_last_name?: string;
    /**
     * 名（ひらがな）
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    hira_first_name?: string;
    /**
     * 氏（カナ）
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    kana_last_name?: string;
    /**
     * 名（カナ）
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    kana_first_name?: string;
    /**
     * 郵便番号（前半3桁）
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    zipcode1?: string;
    /**
     * 郵便番号（後半4桁）
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    zipcode2?: string;
    /**
     * 住所（都道府県）
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    pref?: string;
    /**
     * 住所（市町村）
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    city?: string;
    /**
     * 住所（番号）
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    town?: string;
    /**
     * 住所（マンション名、部屋番号）
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    building?: string;
    /**
     * 電話番号1_1
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    phone1_1?: string;
    /**
     * 電話番号1_2
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    phone1_2?: string;
    /**
     * 電話番号1_3
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    phone1_3?: string;
    /**
     * 電話番号2_1
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    phone2_1?: string;
    /**
     * 電話番号2_2
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    phone2_2?: string;
    /**
     * 電話番号2_3
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    phone2_3?: string;
    /**
     * 生年月日（年）
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    birth_year?: string;
    /**
     * 生年月日（月）
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    birth_month?: string;
    /**
     * 生年月日（日）
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    birth_day?: string;
    /**
     * 性別区分
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    gender_typ?: string;
    /**
     * 職業区分
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    shokugyou_typ?: string;
    /**
     * ONEからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ReqAdminUserUpdate
     */
    promo_mail_from_one_receive_flg?: boolean;
    /**
     * OPカードからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ReqAdminUserUpdate
     */
    promo_mail_from_op_card_receive_flg?: boolean;
    /**
     * メールアドレス
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    mail_address?: string;
    /**
     * 小田急お客さま番号1
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    odakyu_customer_no1?: string;
    /**
     * 小田急お客さま番号2
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    odakyu_customer_no2?: string;
    /**
     * 小田急お客さま番号3
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    odakyu_customer_no3?: string;
    /**
     * 小田急お客さま番号確認ステータス
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    odakyu_customer_no_confirm_status?: string;
    /**
     * 管理画面対応メモ
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    admin_client_relation_memo?: string;
    /**
     * 管理画面更新ユーザー名
     * @type {string}
     * @memberof ReqAdminUserUpdate
     */
    admin_update_username?: string;
}
/**
 * 提携サービス
 * @export
 * @interface ReqAllianceService
 */
export interface ReqAllianceService {
    /**
     * 提携サービスクライアントID
     * @type {string}
     * @memberof ReqAllianceService
     */
    alliance_service_client_id?: string;
    /**
     * 提携サービス名
     * @type {string}
     * @memberof ReqAllianceService
     */
    alliance_service_name?: string;
    /**
     * 提携サービス説明
     * @type {string}
     * @memberof ReqAllianceService
     */
    alliance_service_explanation?: string;
    /**
     * サービス紹介画面URL
     * @type {string}
     * @memberof ReqAllianceService
     */
    service_intro_window_url?: string;
    /**
     * ログイン画面URL
     * @type {string}
     * @memberof ReqAllianceService
     */
    login_window_url?: string;
    /**
     * トップ画面URL
     * @type {string}
     * @memberof ReqAllianceService
     */
    top_window_url?: string;
    /**
     * 連携画面URL
     * @type {string}
     * @memberof ReqAllianceService
     */
    linkage_window_url?: string;
    /**
     * コールバックURL
     * @type {string}
     * @memberof ReqAllianceService
     */
    callback_url?: string;
    /**
     * サービスサムネイルパス
     * @type {string}
     * @memberof ReqAllianceService
     */
    service_thumbnail_pass?: string;
    /**
     * サービスレコメンドコード
     * @type {number}
     * @memberof ReqAllianceService
     */
    service_recommend_cd?: number;
    /**
     * サービスロゴパス
     * @type {string}
     * @memberof ReqAllianceService
     */
    service_logo_pass?: string;
    /**
     * カテゴリ区分
     * @type {string}
     * @memberof ReqAllianceService
     */
    category_typ?: string;
    /**
     * カテゴリクラス区分
     * @type {string}
     * @memberof ReqAllianceService
     */
    category_class_typ?: string;
}
/**
 * 提携サービス利用実績
 * @export
 * @interface ReqAllianceServiceUsageRecord
 */
export interface ReqAllianceServiceUsageRecord {
    /**
     * UID
     * @type {string}
     * @memberof ReqAllianceServiceUsageRecord
     */
    uid?: string;
    /**
     * 提携サービスクライアントID
     * @type {string}
     * @memberof ReqAllianceServiceUsageRecord
     */
    alliance_service_client_id?: string;
    /**
     * 利用内容
     * @type {string}
     * @memberof ReqAllianceServiceUsageRecord
     */
    usage_content?: string;
    /**
     * 利用日時
     * @type {string}
     * @memberof ReqAllianceServiceUsageRecord
     */
    usage_dt?: string;
    /**
     * 取引合計金額
     * @type {number}
     * @memberof ReqAllianceServiceUsageRecord
     */
    trade_total_amount?: number;
    /**
     * 取引コード
     * @type {string}
     * @memberof ReqAllianceServiceUsageRecord
     */
    trade_cd?: string;
    /**
     * 取消取引コード
     * @type {string}
     * @memberof ReqAllianceServiceUsageRecord
     */
    trade_cancel_cd?: string;
}
/**
 * Auth0 Management API request
 * @export
 * @interface ReqAuth0Admin
 */
export interface ReqAuth0Admin {
    /**
     * 
     * @type {string}
     * @memberof ReqAuth0Admin
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof ReqAuth0Admin
     */
    method?: ReqAuth0AdminMethodEnum;
    /**
     * 
     * @type {object}
     * @memberof ReqAuth0Admin
     */
    body?: object;
}

/**
    * @export
    * @enum {string}
    */
export enum ReqAuth0AdminMethodEnum {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH'
}

/**
 * カレントユーザー
 * @export
 * @interface ReqCurrentUser
 */
export interface ReqCurrentUser {
    /**
     * 氏
     * @type {string}
     * @memberof ReqCurrentUser
     */
    last_name: string;
    /**
     * 名
     * @type {string}
     * @memberof ReqCurrentUser
     */
    first_name: string;
    /**
     * 氏（ひらがな）
     * @type {string}
     * @memberof ReqCurrentUser
     */
    hira_last_name: string;
    /**
     * 名（ひらがな）
     * @type {string}
     * @memberof ReqCurrentUser
     */
    hira_first_name: string;
    /**
     * 氏（カナ）
     * @type {string}
     * @memberof ReqCurrentUser
     */
    kana_last_name: string;
    /**
     * 名（カナ）
     * @type {string}
     * @memberof ReqCurrentUser
     */
    kana_first_name: string;
    /**
     * 郵便番号（前半3桁）
     * @type {string}
     * @memberof ReqCurrentUser
     */
    zipcode1: string;
    /**
     * 郵便番号（後半4桁）
     * @type {string}
     * @memberof ReqCurrentUser
     */
    zipcode2: string;
    /**
     * 住所（都道府県）
     * @type {string}
     * @memberof ReqCurrentUser
     */
    pref: string;
    /**
     * 住所（市区町村・大字）
     * @type {string}
     * @memberof ReqCurrentUser
     */
    city: string;
    /**
     * 住所（丁目）
     * @type {string}
     * @memberof ReqCurrentUser
     */
    town: string;
    /**
     * 住所（番地・建物名・部屋番号）
     * @type {string}
     * @memberof ReqCurrentUser
     */
    building: string;
    /**
     * 電話番号1_1
     * @type {string}
     * @memberof ReqCurrentUser
     */
    phone1_1: string;
    /**
     * 電話番号1_2
     * @type {string}
     * @memberof ReqCurrentUser
     */
    phone1_2: string;
    /**
     * 電話番号1_3
     * @type {string}
     * @memberof ReqCurrentUser
     */
    phone1_3: string;
    /**
     * 電話番号2_1
     * @type {string}
     * @memberof ReqCurrentUser
     */
    phone2_1?: string;
    /**
     * 電話番号2_2
     * @type {string}
     * @memberof ReqCurrentUser
     */
    phone2_2?: string;
    /**
     * 電話番号2_3
     * @type {string}
     * @memberof ReqCurrentUser
     */
    phone2_3?: string;
    /**
     * 生年月日（年）
     * @type {string}
     * @memberof ReqCurrentUser
     */
    birth_year: string;
    /**
     * 生年月日（月）
     * @type {string}
     * @memberof ReqCurrentUser
     */
    birth_month: string;
    /**
     * 生年月日（日）
     * @type {string}
     * @memberof ReqCurrentUser
     */
    birth_day: string;
    /**
     * 性別区分
     * @type {string}
     * @memberof ReqCurrentUser
     */
    gender_typ: string;
    /**
     * 職業区分
     * @type {string}
     * @memberof ReqCurrentUser
     */
    shokugyou_typ: string;
    /**
     * ONEからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ReqCurrentUser
     */
    promo_mail_from_one_receive_flg: boolean;
    /**
     * OPカードからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ReqCurrentUser
     */
    promo_mail_from_op_card_receive_flg: boolean;
    /**
     * 会員番号
     * @type {string}
     * @memberof ReqCurrentUser
     */
    membership_no?: string;
}
/**
 * トークンのDBへのストア
 * @export
 * @interface ReqEnergyTokenStore
 */
export interface ReqEnergyTokenStore {
    /**
     * 電ガス紐づけトークン
     * @type {string}
     * @memberof ReqEnergyTokenStore
     */
    token?: string;
}
/**
 * 問い合わせメール送信
 * @export
 * @interface ReqInquireSendEmail
 */
export interface ReqInquireSendEmail {
    /**
     * 問い合わせ内容
     * @type {string}
     * @memberof ReqInquireSendEmail
     */
    contents?: string;
}
/**
 * イベント消込リクエスト
 * @export
 * @interface ReqPatchUseEventStatus
 */
export interface ReqPatchUseEventStatus {
    /**
     * イベント申込ID(event_entries.id)
     * @type {number}
     * @memberof ReqPatchUseEventStatus
     */
    event_entry_id: number;
}
/**
 * ポイント明細
 * @export
 * @interface ReqPointDetail
 */
export interface ReqPointDetail {
    /**
     * UID
     * @type {string}
     * @memberof ReqPointDetail
     */
    uid?: string;
    /**
     * 提携サービス利用実績ID
     * @type {string}
     * @memberof ReqPointDetail
     */
    alliance_service_usage_record_id?: string;
    /**
     * ポイント発行日時
     * @type {string}
     * @memberof ReqPointDetail
     */
    point_issuing_dt?: string;
    /**
     * ポイント数
     * @type {number}
     * @memberof ReqPointDetail
     */
    point_cnt?: number;
    /**
     * ポイント摘要
     * @type {string}
     * @memberof ReqPointDetail
     */
    point_abstract?: string;
}
/**
 * キャンペーン登録リクエスト
 * @export
 * @interface ReqPostCampaignEntry
 */
export interface ReqPostCampaignEntry {
    /**
     * キャンペーンタイトル
     * @type {string}
     * @memberof ReqPostCampaignEntry
     */
    campaign_title: string;
    /**
     * キャンペーン内選択肢
     * @type {Array<string>}
     * @memberof ReqPostCampaignEntry
     */
    campaign_options: Array<string>;
    /**
     * 追加ユーザ入力
     * @type {string}
     * @memberof ReqPostCampaignEntry
     */
    additional_user_input: string;
}
/**
 * イベント申込リクエスト
 * @export
 * @interface ReqPostEventEntry
 */
export interface ReqPostEventEntry {
    /**
     * イベントID
     * @type {string}
     * @memberof ReqPostEventEntry
     */
    event_id: string;
    /**
     * イベント内選択肢
     * @type {Array<EventOption>}
     * @memberof ReqPostEventEntry
     */
    event_options: Array<EventOption>;
    /**
     * 備考
     * @type {string}
     * @memberof ReqPostEventEntry
     */
    note: string;
    /**
     * 決済トークン サブスク登録中のユーザが登録済みカードを用いて決済する場合のみnull(key自体なし)を許容する
     * @type {string}
     * @memberof ReqPostEventEntry
     */
    pay_token?: string;
}
/**
 * 限定消込クーポン消込リクエスト
 * @export
 * @interface ReqPostLimitedExpendableCouponUse
 */
export interface ReqPostLimitedExpendableCouponUse {
    /**
     * クーポンID
     * @type {string}
     * @memberof ReqPostLimitedExpendableCouponUse
     */
    coupon_id: string;
    /**
     * 消込枚数
     * @type {number}
     * @memberof ReqPostLimitedExpendableCouponUse
     */
    use_num: number;
    /**
     * 残枚数
     * @type {number}
     * @memberof ReqPostLimitedExpendableCouponUse
     */
    remain_use_num: number;
    /**
     * 消込番号
     * @type {string}
     * @memberof ReqPostLimitedExpendableCouponUse
     */
    expend_cd?: string;
}
/**
 * サブスク会員登録リクエスト
 * @export
 * @interface ReqPostSubscription
 */
export interface ReqPostSubscription {
    /**
     * 決済トークン ソニーペイメント登録済みのユーザが登録済みカードを用いて決済する場合のみnull(key自体なし)を許容する
     * @type {string}
     * @memberof ReqPostSubscription
     */
    pay_token?: string;
    /**
     * サブスクパッケージID
     * @type {string}
     * @memberof ReqPostSubscription
     */
    subscription_package_id: string;
}
/**
 * 利用実績（管理画面用）
 * @export
 * @interface ResAdminAllianceServiceUsageRecords
 */
export interface ResAdminAllianceServiceUsageRecords {
    /**
     * 連携しているサービス
     * @type {Array<ResAllianceServiceWithLinkage>}
     * @memberof ResAdminAllianceServiceUsageRecords
     */
    alliance_service_with_linkage?: Array<ResAllianceServiceWithLinkage>;
    /**
     * 利用実績取得
     * @type {Array<ResServiceUsage>}
     * @memberof ResAdminAllianceServiceUsageRecords
     */
    service_usage?: Array<ResServiceUsage>;
}
/**
 * 
 * @export
 * @interface ResAdminElectricityContract
 */
export interface ResAdminElectricityContract {
    /**
     * ID
     * @type {number}
     * @memberof ResAdminElectricityContract
     */
    id: number;
    /**
     * 登録プログムコード
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    create_pgm_cd: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    create_user_id: string;
    /**
     * 登録日時
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    create_dt: string;
    /**
     * 更新プログラムコード
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    update_pgm_cd: string;
    /**
     * 更新ユーザーID
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    update_user_id: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    update_dt: string;
    /**
     * パッチコード
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    patch_cd: string;
    /**
     * パッチユーザーID
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    patch_user_id: string;
    /**
     * パッチ日時
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    patch_dt: string;
    /**
     * ■CISお客様番号
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    cis_customer_no?: string;
    /**
     * ■契約番号
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    contract_no?: string;
    /**
     * 契約ステータス区分
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    contract_status_type?: string;
    /**
     * 供給地点特定番号
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    supply_point_no?: string;
    /**
     * 契約メニュー名
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    contract_menu_name?: string;
    /**
     * 契約申込日
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    contract_entry_ymd?: string | null;
    /**
     * 供給開始日
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    supply_start_ymd?: string | null;
    /**
     * 契約開始日
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    contract_start_ymd?: string | null;
    /**
     * 契約終了日
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    contract_end_ymd?: string | null;
    /**
     * 契約者氏名
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    contractor_name?: string;
    /**
     * 契約者氏名（カナ）
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    contractor_kana_name?: string;
    /**
     * 契約者電話番号
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    contractor_phone?: string;
    /**
     * 契約者郵便番号
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    contractor_zipcode?: string;
    /**
     * 契約者住所
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    contractor_addr?: string;
    /**
     * 契約者メールアドレス
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    contractor_mail_address?: string;
    /**
     * 需要者氏名
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    consumer_name?: string;
    /**
     * 需要者氏名（カナ）
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    consumer_kana_name?: string;
    /**
     * 需要者電話番号
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    consumer_phone?: string;
    /**
     * 需要者郵便番号
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    consumer_zipcode?: string;
    /**
     * 需要者住所
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    consumer_addr?: string;
    /**
     * 設備情報取得状況
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    facility_info_obtain_situation?: string;
    /**
     * スイッチング廃止取次登録状況
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    sw_disuse_direction_create_situation?: string;
    /**
     * スイッチング開始申請状況
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    sw_start_request_situation?: string;
    /**
     * 再点申請状況
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    counter_check_request_situation?: string;
    /**
     * アンペア変更申請状況
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    ampere_chg_request_situation?: string;
    /**
     * 契約アンペア変更予定日
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    contract_ampere_chg_expected_ymd?: string | null;
    /**
     * 需要者情報変更申請状況
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    consumer_info_chg_request_situation?: string;
    /**
     * 廃止・撤去申請状況
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    disuse_request_situation?: string;
    /**
     * 登録トランザクションID
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    create_transaction_id?: string;
    /**
     * 更新トランザクションID
     * @type {string}
     * @memberof ResAdminElectricityContract
     */
    update_transaction_id?: string;
}
/**
 * ユーザ情報単票更新ページの紐づき電ガス契約一覧取得APIレスポンス
 * @export
 * @interface ResAdminElectricityGasContract
 */
export interface ResAdminElectricityGasContract {
    /**
     * テーブルのサロゲートキー
     * @type {number}
     * @memberof ResAdminElectricityGasContract
     */
    id?: number;
    /**
     * 契約ステータス （01: 契約中、02: 契約終了、 03:契約期間前）
     * @type {string}
     * @memberof ResAdminElectricityGasContract
     */
    contract_status?: string;
    /**
     * 契約番号
     * @type {string}
     * @memberof ResAdminElectricityGasContract
     */
    contract_no?: string;
    /**
     * 契約開始日
     * @type {string}
     * @memberof ResAdminElectricityGasContract
     */
    contract_start_ymd?: string | null;
    /**
     * 契約終了日
     * @type {string}
     * @memberof ResAdminElectricityGasContract
     */
    contract_end_ymd?: string | null;
    /**
     * 電ガス区分 （01: 電気、02: ガス）
     * @type {string}
     * @memberof ResAdminElectricityGasContract
     */
    energy_typ?: string;
}
/**
 * 契約情報の検索条件
 * @export
 * @interface ResAdminEnergyContract
 */
export interface ResAdminEnergyContract {
    /**
     * ガス顧客契約・でんき顧客契約のID
     * @type {number}
     * @memberof ResAdminEnergyContract
     */
    id?: number;
    /**
     * 契約ステータス区分
     * @type {string}
     * @memberof ResAdminEnergyContract
     */
    contract_status_type?: string;
    /**
     * 契約番号
     * @type {string}
     * @memberof ResAdminEnergyContract
     */
    contract_no?: string;
    /**
     * 契約者氏名
     * @type {string}
     * @memberof ResAdminEnergyContract
     */
    contractor_name?: string;
    /**
     * 契約者氏名（カナ）
     * @type {string}
     * @memberof ResAdminEnergyContract
     */
    contractor_kana_name?: string;
    /**
     * 契約者電話番号
     * @type {string}
     * @memberof ResAdminEnergyContract
     */
    contractor_phone?: string;
    /**
     * 契約者メールアドレス
     * @type {string}
     * @memberof ResAdminEnergyContract
     */
    contractor_mail_address?: string;
    /**
     * 契約者住所
     * @type {string}
     * @memberof ResAdminEnergyContract
     */
    contractor_addr?: string;
    /**
     * でんきガス区分
     * @type {string}
     * @memberof ResAdminEnergyContract
     */
    electricity_gas_typ?: string;
    /**
     * 紐付けステータス区分
     * @type {string}
     * @memberof ResAdminEnergyContract
     */
    link_status_typ?: string;
}
/**
 * 小田急でんき・ガス単票参照ページで使用する紐づけ情報
 * @export
 * @interface ResAdminEnergyLinkedInfo
 */
export interface ResAdminEnergyLinkedInfo {
    /**
     * 
     * @type {string}
     * @memberof ResAdminEnergyLinkedInfo
     */
    mail_snd_status_typ?: string;
    /**
     * 
     * @type {string}
     * @memberof ResAdminEnergyLinkedInfo
     */
    link_status_typ?: string;
    /**
     * 
     * @type {string}
     * @memberof ResAdminEnergyLinkedInfo
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof ResAdminEnergyLinkedInfo
     */
    link_url?: string;
}
/**
 * 電ガス契約切替URL発行取得
 * @export
 * @interface ResAdminEnergySwitchContractLink
 */
export interface ResAdminEnergySwitchContractLink {
    /**
     * 電ガス契約切替URL
     * @type {string}
     * @memberof ResAdminEnergySwitchContractLink
     */
    link_url?: string;
}
/**
 * 
 * @export
 * @interface ResAdminGasContract
 */
export interface ResAdminGasContract {
    /**
     * ID
     * @type {number}
     * @memberof ResAdminGasContract
     */
    id: number;
    /**
     * 登録プログムコード
     * @type {string}
     * @memberof ResAdminGasContract
     */
    create_pgm_cd: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof ResAdminGasContract
     */
    create_user_id: string;
    /**
     * 登録日時
     * @type {string}
     * @memberof ResAdminGasContract
     */
    create_dt: string;
    /**
     * 更新プログラムコード
     * @type {string}
     * @memberof ResAdminGasContract
     */
    update_pgm_cd: string;
    /**
     * 更新ユーザーID
     * @type {string}
     * @memberof ResAdminGasContract
     */
    update_user_id: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof ResAdminGasContract
     */
    update_dt: string;
    /**
     * パッチコード
     * @type {string}
     * @memberof ResAdminGasContract
     */
    patch_cd: string;
    /**
     * パッチユーザーID
     * @type {string}
     * @memberof ResAdminGasContract
     */
    patch_user_id: string;
    /**
     * パッチ日時
     * @type {string}
     * @memberof ResAdminGasContract
     */
    patch_dt: string;
    /**
     * CISお客様番号
     * @type {string}
     * @memberof ResAdminGasContract
     */
    cis_customer_no?: string;
    /**
     * 契約ステータス区分
     * @type {string}
     * @memberof ResAdminGasContract
     */
    contract_status_type?: string;
    /**
     * 供給地点特定番号
     * @type {string}
     * @memberof ResAdminGasContract
     */
    supply_point_no?: string;
    /**
     * 契約メニュー名
     * @type {string}
     * @memberof ResAdminGasContract
     */
    contract_menu_name?: string;
    /**
     * 契約開始日
     * @type {string}
     * @memberof ResAdminGasContract
     */
    contract_start_ymd?: string | null;
    /**
     * 契約終了日
     * @type {string}
     * @memberof ResAdminGasContract
     */
    contract_end_ymd?: string | null;
    /**
     * 需要者氏名
     * @type {string}
     * @memberof ResAdminGasContract
     */
    consumer_name?: string;
    /**
     * 需要者氏名（カナ）
     * @type {string}
     * @memberof ResAdminGasContract
     */
    consumer_kana_name?: string;
    /**
     * 需要者電話番号
     * @type {string}
     * @memberof ResAdminGasContract
     */
    consumer_phone?: string;
    /**
     * 需要者FAX番号
     * @type {string}
     * @memberof ResAdminGasContract
     */
    consumer_fax_no?: string;
    /**
     * 需要者郵便番号
     * @type {string}
     * @memberof ResAdminGasContract
     */
    consumer_zipcode?: string;
    /**
     * 需要者住所
     * @type {string}
     * @memberof ResAdminGasContract
     */
    consumer_address?: string;
    /**
     * 需要者メールアドレス
     * @type {string}
     * @memberof ResAdminGasContract
     */
    consumer_mail_address?: string;
    /**
     * 契約者住所区分
     * @type {string}
     * @memberof ResAdminGasContract
     */
    contractor_addr_typ?: string;
    /**
     * 契約者氏名
     * @type {string}
     * @memberof ResAdminGasContract
     */
    contractor_name?: string;
    /**
     * 契約者氏名（カナ）
     * @type {string}
     * @memberof ResAdminGasContract
     */
    contractor_kana_name?: string;
    /**
     * 契約者電話番号
     * @type {string}
     * @memberof ResAdminGasContract
     */
    contractor_phone?: string;
    /**
     * 契約者FAX番号
     * @type {string}
     * @memberof ResAdminGasContract
     */
    contractor_fax_no?: string;
    /**
     * 契約者郵便番号
     * @type {string}
     * @memberof ResAdminGasContract
     */
    contractor_zipcode?: string;
    /**
     * 契約者住所
     * @type {string}
     * @memberof ResAdminGasContract
     */
    contractor_address?: string;
    /**
     * 契約者メールアドレス
     * @type {string}
     * @memberof ResAdminGasContract
     */
    contractor_mail_address?: string;
    /**
     * 請求先住所区分
     * @type {string}
     * @memberof ResAdminGasContract
     */
    billing_dest_addr_typ?: string;
    /**
     * 請求先氏名
     * @type {string}
     * @memberof ResAdminGasContract
     */
    billing_dest_name?: string;
    /**
     * 請求先氏名（カナ）
     * @type {string}
     * @memberof ResAdminGasContract
     */
    billing_dest_kana_name?: string;
    /**
     * 請求先電話番号
     * @type {string}
     * @memberof ResAdminGasContract
     */
    billing_dest_phone?: string;
    /**
     * 請求先FAX番号
     * @type {string}
     * @memberof ResAdminGasContract
     */
    billing_dest_fax_no?: string;
    /**
     * 請求先郵便番号
     * @type {string}
     * @memberof ResAdminGasContract
     */
    billing_dest_zipcode?: string;
    /**
     * 請求先住所
     * @type {string}
     * @memberof ResAdminGasContract
     */
    billing_dest_address?: string;
    /**
     * 請求先メールアドレス
     * @type {string}
     * @memberof ResAdminGasContract
     */
    billing_dest_mail_address?: string;
    /**
     * エリアID
     * @type {string}
     * @memberof ResAdminGasContract
     */
    area_name?: string;
    /**
     * 支払方法
     * @type {string}
     * @memberof ResAdminGasContract
     */
    payment_method_name?: string;
    /**
     * 提供状況
     * @type {string}
     * @memberof ResAdminGasContract
     */
    offer_situation_name?: string;
    /**
     * 契約状況
     * @type {string}
     * @memberof ResAdminGasContract
     */
    contract_situation_name?: string;
    /**
     * 接続供給開始日
     * @type {string}
     * @memberof ResAdminGasContract
     */
    connection_supply_start_ymd?: string | null;
    /**
     * 登録トランザクションID
     * @type {string}
     * @memberof ResAdminGasContract
     */
    create_transaction_id?: string;
    /**
     * 更新トランザクションID
     * @type {string}
     * @memberof ResAdminGasContract
     */
    update_transaction_id?: string;
}
/**
 * ユーザ情報取得（管理画面用）
 * @export
 * @interface ResAdminGetUserInfo
 */
export interface ResAdminGetUserInfo {
    /**
     * UID
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    uid?: string;
    /**
     * 氏
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    last_name?: string;
    /**
     * 名
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    first_name?: string;
    /**
     * 氏（ひらがな）
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    hira_last_name?: string;
    /**
     * 名（ひらがな）
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    hira_first_name?: string;
    /**
     * 郵便番号（前半3桁）
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    zipcode1?: string;
    /**
     * 郵便番号（後半4桁）
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    zipcode2?: string;
    /**
     * 住所（都道府県）
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    pref?: string;
    /**
     * 住所（市町村）
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    city?: string;
    /**
     * 住所（番号）
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    town?: string;
    /**
     * 住所（マンション名、部屋番号）
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    building?: string;
    /**
     * 電話番号1_1
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    phone1_1?: string;
    /**
     * 電話番号1_2
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    phone1_2?: string;
    /**
     * 電話番号1_3
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    phone1_3?: string;
    /**
     * 電話番号2_1
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    phone2_1?: string;
    /**
     * 電話番号2_2
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    phone2_2?: string;
    /**
     * 電話番号2_3
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    phone2_3?: string;
    /**
     * 生年月日（年）
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    birth_year?: string;
    /**
     * 生年月日（月）
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    birth_month?: string;
    /**
     * 生年月日（日）
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    birth_day?: string;
    /**
     * 性別区分
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    gender_typ?: string;
    /**
     * 職業区分
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    shokugyou_typ?: string;
    /**
     * ONEからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ResAdminGetUserInfo
     */
    promo_mail_from_one_receive_flg?: boolean;
    /**
     * OPカードからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ResAdminGetUserInfo
     */
    promo_mail_from_op_card_receive_flg?: boolean;
    /**
     * メールアドレス
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    mail_address?: string;
    /**
     * 小田急お客さま番号1
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    odakyu_customer_no1?: string;
    /**
     * 小田急お客さま番号2
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    odakyu_customer_no2?: string;
    /**
     * 小田急お客さま番号3
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    odakyu_customer_no3?: string;
    /**
     * 小田急お客さま番号確認ステータス
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    odakyu_customer_no_confirm_status?: string;
    /**
     * 退会日時
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    unsubscribe_dt?: string;
    /**
     * 管理画面対応メモ
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    admin_client_relation_memo?: string;
    /**
     * 管理画面更新ユーザー名
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    admin_update_username?: string;
    /**
     * 管理画面更新日時
     * @type {string}
     * @memberof ResAdminGetUserInfo
     */
    admin_update_dt?: string;
}
/**
 * 管理画面で取得するユーザー情報
 * @export
 * @interface ResAdminUser
 */
export interface ResAdminUser {
    /**
     * ID
     * @type {number}
     * @memberof ResAdminUser
     */
    id?: number;
    /**
     * UID
     * @type {string}
     * @memberof ResAdminUser
     */
    uid?: string;
    /**
     * 氏
     * @type {string}
     * @memberof ResAdminUser
     */
    last_name?: string;
    /**
     * 名
     * @type {string}
     * @memberof ResAdminUser
     */
    first_name?: string;
    /**
     * 氏（ひらがな）
     * @type {string}
     * @memberof ResAdminUser
     */
    hira_last_name?: string;
    /**
     * 名（ひらがな）
     * @type {string}
     * @memberof ResAdminUser
     */
    hira_first_name?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof ResAdminUser
     */
    mail_address?: string;
    /**
     * 電話番号1_1
     * @type {string}
     * @memberof ResAdminUser
     */
    phone1_1?: string;
    /**
     * 電話番号1_2
     * @type {string}
     * @memberof ResAdminUser
     */
    phone1_2?: string;
    /**
     * 電話番号1_3
     * @type {string}
     * @memberof ResAdminUser
     */
    phone1_3?: string;
    /**
     * 電話番号2_1
     * @type {string}
     * @memberof ResAdminUser
     */
    phone2_1?: string;
    /**
     * 電話番号2_2
     * @type {string}
     * @memberof ResAdminUser
     */
    phone2_2?: string;
    /**
     * 電話番号2_3
     * @type {string}
     * @memberof ResAdminUser
     */
    phone2_3?: string;
    /**
     * 生年月日（年）
     * @type {string}
     * @memberof ResAdminUser
     */
    birth_year?: string;
    /**
     * 生年月日（月）
     * @type {string}
     * @memberof ResAdminUser
     */
    birth_month?: string;
    /**
     * 生年月日（日）
     * @type {string}
     * @memberof ResAdminUser
     */
    birth_day?: string;
    /**
     * 小田急お客さま番号
     * @type {Array<string>}
     * @memberof ResAdminUser
     */
    odakyu_customer_no?: Array<string>;
    /**
     * 電気ガス契約状況
     * @type {string}
     * @memberof ResAdminUser
     */
    energy_contract_status?: string;
}
/**
 * 
 * @export
 * @interface ResAllianceService
 */
export interface ResAllianceService {
    /**
     * 提携サービスクライアントID
     * @type {string}
     * @memberof ResAllianceService
     */
    alliance_service_client_id?: string;
    /**
     * 提携サービス名
     * @type {string}
     * @memberof ResAllianceService
     */
    alliance_service_name?: string;
    /**
     * 提携サービス説明
     * @type {string}
     * @memberof ResAllianceService
     */
    alliance_service_explanation?: string;
    /**
     * サービス紹介画面URL
     * @type {string}
     * @memberof ResAllianceService
     */
    service_intro_window_url?: string;
    /**
     * ログイン画面URL
     * @type {string}
     * @memberof ResAllianceService
     */
    login_window_url?: string;
    /**
     * トップ画面URL
     * @type {string}
     * @memberof ResAllianceService
     */
    top_window_url?: string;
    /**
     * 連携画面URL
     * @type {string}
     * @memberof ResAllianceService
     */
    linkage_window_url?: string;
    /**
     * コールバックURL
     * @type {string}
     * @memberof ResAllianceService
     */
    callback_url?: string;
    /**
     * サービスサムネイルパス
     * @type {string}
     * @memberof ResAllianceService
     */
    service_thumbnail_pass?: string;
    /**
     * サービスレコメンドコード
     * @type {number}
     * @memberof ResAllianceService
     */
    service_recommend_cd?: number;
    /**
     * サービスロゴパス
     * @type {string}
     * @memberof ResAllianceService
     */
    service_logo_pass?: string;
    /**
     * カテゴリ区分
     * @type {string}
     * @memberof ResAllianceService
     */
    category_typ?: string;
    /**
     * カテゴリクラス区分
     * @type {string}
     * @memberof ResAllianceService
     */
    category_class_typ?: string;
    /**
     * ID
     * @type {number}
     * @memberof ResAllianceService
     */
    id: number;
    /**
     * 登録プログムコード
     * @type {string}
     * @memberof ResAllianceService
     */
    create_pgm_cd: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof ResAllianceService
     */
    create_user_id: string;
    /**
     * 登録日時
     * @type {string}
     * @memberof ResAllianceService
     */
    create_dt: string;
    /**
     * 更新プログラムコード
     * @type {string}
     * @memberof ResAllianceService
     */
    update_pgm_cd: string;
    /**
     * 更新ユーザーID
     * @type {string}
     * @memberof ResAllianceService
     */
    update_user_id: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof ResAllianceService
     */
    update_dt: string;
    /**
     * パッチコード
     * @type {string}
     * @memberof ResAllianceService
     */
    patch_cd: string;
    /**
     * パッチユーザーID
     * @type {string}
     * @memberof ResAllianceService
     */
    patch_user_id: string;
    /**
     * パッチ日時
     * @type {string}
     * @memberof ResAllianceService
     */
    patch_dt: string;
}
/**
 * 
 * @export
 * @interface ResAllianceServiceLinkage
 */
export interface ResAllianceServiceLinkage {
    /**
     * 現在ユーザアラインアンス利用状況
     * @type {boolean}
     * @memberof ResAllianceServiceLinkage
     */
    is_linked: boolean;
}
/**
 * 
 * @export
 * @interface ResAllianceServiceUsageRecord
 */
export interface ResAllianceServiceUsageRecord {
    /**
     * UID
     * @type {string}
     * @memberof ResAllianceServiceUsageRecord
     */
    uid?: string;
    /**
     * 提携サービスクライアントID
     * @type {string}
     * @memberof ResAllianceServiceUsageRecord
     */
    alliance_service_client_id?: string;
    /**
     * 利用内容
     * @type {string}
     * @memberof ResAllianceServiceUsageRecord
     */
    usage_content?: string;
    /**
     * 利用日時
     * @type {string}
     * @memberof ResAllianceServiceUsageRecord
     */
    usage_dt?: string;
    /**
     * 取引合計金額
     * @type {number}
     * @memberof ResAllianceServiceUsageRecord
     */
    trade_total_amount?: number;
    /**
     * 取引コード
     * @type {string}
     * @memberof ResAllianceServiceUsageRecord
     */
    trade_cd?: string;
    /**
     * 取消取引コード
     * @type {string}
     * @memberof ResAllianceServiceUsageRecord
     */
    trade_cancel_cd?: string;
    /**
     * ID
     * @type {number}
     * @memberof ResAllianceServiceUsageRecord
     */
    id: number;
    /**
     * 登録プログムコード
     * @type {string}
     * @memberof ResAllianceServiceUsageRecord
     */
    create_pgm_cd: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof ResAllianceServiceUsageRecord
     */
    create_user_id: string;
    /**
     * 登録日時
     * @type {string}
     * @memberof ResAllianceServiceUsageRecord
     */
    create_dt: string;
    /**
     * 更新プログラムコード
     * @type {string}
     * @memberof ResAllianceServiceUsageRecord
     */
    update_pgm_cd: string;
    /**
     * 更新ユーザーID
     * @type {string}
     * @memberof ResAllianceServiceUsageRecord
     */
    update_user_id: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof ResAllianceServiceUsageRecord
     */
    update_dt: string;
    /**
     * パッチコード
     * @type {string}
     * @memberof ResAllianceServiceUsageRecord
     */
    patch_cd: string;
    /**
     * パッチユーザーID
     * @type {string}
     * @memberof ResAllianceServiceUsageRecord
     */
    patch_user_id: string;
    /**
     * パッチ日時
     * @type {string}
     * @memberof ResAllianceServiceUsageRecord
     */
    patch_dt: string;
}
/**
 * 
 * @export
 * @interface ResAllianceServiceWithLinkage
 */
export interface ResAllianceServiceWithLinkage {
    /**
     * 現在ユーザアラインアンス利用状況
     * @type {boolean}
     * @memberof ResAllianceServiceWithLinkage
     */
    is_linked: boolean;
    /**
     * 提携サービスクライアントID
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    alliance_service_client_id?: string;
    /**
     * 提携サービス名
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    alliance_service_name?: string;
    /**
     * 提携サービス説明
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    alliance_service_explanation?: string;
    /**
     * サービス紹介画面URL
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    service_intro_window_url?: string;
    /**
     * ログイン画面URL
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    login_window_url?: string;
    /**
     * トップ画面URL
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    top_window_url?: string;
    /**
     * 連携画面URL
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    linkage_window_url?: string;
    /**
     * コールバックURL
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    callback_url?: string;
    /**
     * サービスサムネイルパス
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    service_thumbnail_pass?: string;
    /**
     * サービスレコメンドコード
     * @type {number}
     * @memberof ResAllianceServiceWithLinkage
     */
    service_recommend_cd?: number;
    /**
     * サービスロゴパス
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    service_logo_pass?: string;
    /**
     * カテゴリ区分
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    category_typ?: string;
    /**
     * カテゴリクラス区分
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    category_class_typ?: string;
    /**
     * ID
     * @type {number}
     * @memberof ResAllianceServiceWithLinkage
     */
    id: number;
    /**
     * 登録プログムコード
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    create_pgm_cd: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    create_user_id: string;
    /**
     * 登録日時
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    create_dt: string;
    /**
     * 更新プログラムコード
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    update_pgm_cd: string;
    /**
     * 更新ユーザーID
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    update_user_id: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    update_dt: string;
    /**
     * パッチコード
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    patch_cd: string;
    /**
     * パッチユーザーID
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    patch_user_id: string;
    /**
     * パッチ日時
     * @type {string}
     * @memberof ResAllianceServiceWithLinkage
     */
    patch_dt: string;
}
/**
 * Auth0 Management API response
 * @export
 * @interface ResAuth0Admin
 */
export interface ResAuth0Admin {
    /**
     * 
     * @type {number}
     * @memberof ResAuth0Admin
     */
    status?: number;
    /**
     * 
     * @type {object}
     * @memberof ResAuth0Admin
     */
    result?: object;
}
/**
 * キャンペーン応募確認APIレスポンス
 * @export
 * @interface ResCampaignConfirm
 */
export interface ResCampaignConfirm {
    /**
     * 
     * @type {boolean}
     * @memberof ResCampaignConfirm
     */
    is_entried?: boolean;
}
/**
 * 
 * @export
 * @interface ResCurrentUser
 */
export interface ResCurrentUser {
    /**
     * 氏
     * @type {string}
     * @memberof ResCurrentUser
     */
    last_name: string;
    /**
     * 名
     * @type {string}
     * @memberof ResCurrentUser
     */
    first_name: string;
    /**
     * 氏（ひらがな）
     * @type {string}
     * @memberof ResCurrentUser
     */
    hira_last_name: string;
    /**
     * 名（ひらがな）
     * @type {string}
     * @memberof ResCurrentUser
     */
    hira_first_name: string;
    /**
     * 氏（カナ）
     * @type {string}
     * @memberof ResCurrentUser
     */
    kana_last_name: string;
    /**
     * 名（カナ）
     * @type {string}
     * @memberof ResCurrentUser
     */
    kana_first_name: string;
    /**
     * 郵便番号（前半3桁）
     * @type {string}
     * @memberof ResCurrentUser
     */
    zipcode1: string;
    /**
     * 郵便番号（後半4桁）
     * @type {string}
     * @memberof ResCurrentUser
     */
    zipcode2: string;
    /**
     * 住所（都道府県）
     * @type {string}
     * @memberof ResCurrentUser
     */
    pref: string;
    /**
     * 住所（市区町村・大字）
     * @type {string}
     * @memberof ResCurrentUser
     */
    city: string;
    /**
     * 住所（丁目）
     * @type {string}
     * @memberof ResCurrentUser
     */
    town: string;
    /**
     * 住所（番地・建物名・部屋番号）
     * @type {string}
     * @memberof ResCurrentUser
     */
    building: string;
    /**
     * 電話番号1_1
     * @type {string}
     * @memberof ResCurrentUser
     */
    phone1_1: string;
    /**
     * 電話番号1_2
     * @type {string}
     * @memberof ResCurrentUser
     */
    phone1_2: string;
    /**
     * 電話番号1_3
     * @type {string}
     * @memberof ResCurrentUser
     */
    phone1_3: string;
    /**
     * 電話番号2_1
     * @type {string}
     * @memberof ResCurrentUser
     */
    phone2_1?: string;
    /**
     * 電話番号2_2
     * @type {string}
     * @memberof ResCurrentUser
     */
    phone2_2?: string;
    /**
     * 電話番号2_3
     * @type {string}
     * @memberof ResCurrentUser
     */
    phone2_3?: string;
    /**
     * 生年月日（年）
     * @type {string}
     * @memberof ResCurrentUser
     */
    birth_year: string;
    /**
     * 生年月日（月）
     * @type {string}
     * @memberof ResCurrentUser
     */
    birth_month: string;
    /**
     * 生年月日（日）
     * @type {string}
     * @memberof ResCurrentUser
     */
    birth_day: string;
    /**
     * 性別区分
     * @type {string}
     * @memberof ResCurrentUser
     */
    gender_typ: string;
    /**
     * 職業区分
     * @type {string}
     * @memberof ResCurrentUser
     */
    shokugyou_typ: string;
    /**
     * ONEからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ResCurrentUser
     */
    promo_mail_from_one_receive_flg: boolean;
    /**
     * OPカードからのお知らせメール受信フラグ
     * @type {boolean}
     * @memberof ResCurrentUser
     */
    promo_mail_from_op_card_receive_flg: boolean;
    /**
     * 会員番号
     * @type {string}
     * @memberof ResCurrentUser
     */
    membership_no?: string;
    /**
     * UID
     * @type {string}
     * @memberof ResCurrentUser
     */
    uid?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof ResCurrentUser
     */
    mail_address?: string;
}
/**
 * カレントユーザー
 * @export
 * @interface ResCurrentUserAllOf
 */
export interface ResCurrentUserAllOf {
    /**
     * UID
     * @type {string}
     * @memberof ResCurrentUserAllOf
     */
    uid?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof ResCurrentUserAllOf
     */
    mail_address?: string;
}
/**
 * Debug Info response
 * @export
 * @interface ResDebugInfo
 */
export interface ResDebugInfo {
    /**
     * 
     * @type {string}
     * @memberof ResDebugInfo
     */
    system_time?: string;
    /**
     * 
     * @type {string}
     * @memberof ResDebugInfo
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof ResDebugInfo
     */
    commit_rev?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResDebugInfo
     */
    env?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResDebugInfo
     */
    mgr_token?: string;
    /**
     * 
     * @type {object}
     * @memberof ResDebugInfo
     */
    pubkey_cache?: object;
    /**
     * 
     * @type {object}
     * @memberof ResDebugInfo
     */
    mgr_token_cache?: object;
}
/**
 * 電気契約
 * @export
 * @interface ResElectricityContract
 */
export interface ResElectricityContract {
    /**
     * ID
     * @type {number}
     * @memberof ResElectricityContract
     */
    id?: number;
    /**
     * CISお客様番号
     * @type {string}
     * @memberof ResElectricityContract
     */
    cis_customer_no?: string;
    /**
     * 契約番号
     * @type {string}
     * @memberof ResElectricityContract
     */
    contract_no?: string;
    /**
     * 契約ステータス区分
     * @type {string}
     * @memberof ResElectricityContract
     */
    contract_status_type?: string;
    /**
     * 供給地点特定番号
     * @type {string}
     * @memberof ResElectricityContract
     */
    supply_point_no?: string;
    /**
     * 契約メニュー名
     * @type {string}
     * @memberof ResElectricityContract
     */
    contract_menu_name?: string;
    /**
     * 契約申込日
     * @type {string}
     * @memberof ResElectricityContract
     */
    contract_entry_ymd?: string | null;
    /**
     * 供給開始日
     * @type {string}
     * @memberof ResElectricityContract
     */
    supply_start_ymd?: string | null;
    /**
     * 契約開始日
     * @type {string}
     * @memberof ResElectricityContract
     */
    contract_start_ymd?: string | null;
    /**
     * 契約終了日
     * @type {string}
     * @memberof ResElectricityContract
     */
    contract_end_ymd?: string | null;
    /**
     * 契約者氏名
     * @type {string}
     * @memberof ResElectricityContract
     */
    contractor_name?: string;
    /**
     * 契約者メールアドレス
     * @type {string}
     * @memberof ResElectricityContract
     */
    contractor_mail_address?: string;
    /**
     * 需要者氏名
     * @type {string}
     * @memberof ResElectricityContract
     */
    consumer_name?: string;
    /**
     * 需要者氏名（カナ）
     * @type {string}
     * @memberof ResElectricityContract
     */
    consumer_kana_name?: string;
    /**
     * 需要者電話番号
     * @type {string}
     * @memberof ResElectricityContract
     */
    consumer_phone?: string;
    /**
     * 需要者郵便番号
     * @type {string}
     * @memberof ResElectricityContract
     */
    consumer_zipcode?: string;
    /**
     * 需要者住所
     * @type {string}
     * @memberof ResElectricityContract
     */
    consumer_address?: string;
}
/**
 * 電気契約及びそれに紐づく全ての請求ヘッダ
 * @export
 * @interface ResElectricityContractInvoice
 */
export interface ResElectricityContractInvoice {
    /**
     * 
     * @type {ResElectricityContract}
     * @memberof ResElectricityContractInvoice
     */
    contract?: ResElectricityContract;
    /**
     * 電気請求(最大24か月分)
     * @type {Array<ResElectricityInvoice>}
     * @memberof ResElectricityContractInvoice
     */
    invoices?: Array<ResElectricityInvoice>;
}
/**
 * 電気請求
 * @export
 * @interface ResElectricityInvoice
 */
export interface ResElectricityInvoice {
    /**
     * ID
     * @type {number}
     * @memberof ResElectricityInvoice
     */
    id?: number;
    /**
     * 顧客契約ID
     * @type {number}
     * @memberof ResElectricityInvoice
     */
    customer_contract_id?: number;
    /**
     * 伝票番号
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    slip_no?: string;
    /**
     * 伝票日付
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    slip_ymd?: string;
    /**
     * 発行者名
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    issuer_name?: string;
    /**
     * 発行者電話番号
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    issuer_phone?: string;
    /**
     * 発行者住所
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    issuer_addr?: string;
    /**
     * 対象年月
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    target_ym?: string;
    /**
     * 当月使用量
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    this_m_used_amount?: string;
    /**
     * 当月請求額（税込）
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    this_m_tax_included_invoice_amount?: string;
    /**
     * 消費税額
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    con_tax_amount?: string;
    /**
     * 当月検針日
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    this_m_meter_read_ymd?: string;
    /**
     * 当月利用開始日
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    this_m_usage_start_ymd?: string;
    /**
     * 当月利用終了日
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    this_m_usage_end_ymd?: string;
    /**
     * 前月指示数
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    last_m_instruction_num?: string | null;
    /**
     * 当月指示数
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    this_m_instruction_num?: string;
    /**
     * 請求問い合わせ先名
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    invoice_reference_name?: string;
    /**
     * 請求問い合わせ先電話番号
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    invoice_reference_phone?: string;
    /**
     * 電気問い合わせ先名
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    elctrcty_reference_name?: string;
    /**
     * 電気問い合わせ先電話番号
     * @type {string}
     * @memberof ResElectricityInvoice
     */
    elctrcty_reference_phone?: string;
}
/**
 * 電気請求明細
 * @export
 * @interface ResElectricityInvoiceDetail
 */
export interface ResElectricityInvoiceDetail {
    /**
     * ID
     * @type {number}
     * @memberof ResElectricityInvoiceDetail
     */
    id?: number;
    /**
     * 請求ID
     * @type {number}
     * @memberof ResElectricityInvoiceDetail
     */
    invoice_id?: number;
    /**
     * 請求明細番号
     * @type {number}
     * @memberof ResElectricityInvoiceDetail
     */
    invoice_detail_no?: number;
    /**
     * 商品名
     * @type {string}
     * @memberof ResElectricityInvoiceDetail
     */
    item_name?: string;
    /**
     * 数量
     * @type {string}
     * @memberof ResElectricityInvoiceDetail
     */
    qty?: string;
    /**
     * 単価（税込）
     * @type {string}
     * @memberof ResElectricityInvoiceDetail
     */
    tax_included_unit_price?: string;
    /**
     * 金額（税込）
     * @type {string}
     * @memberof ResElectricityInvoiceDetail
     */
    tax_included_amount?: string;
    /**
     * 消費税額
     * @type {string}
     * @memberof ResElectricityInvoiceDetail
     */
    con_tax_amount?: string;
}
/**
 * ガス契約
 * @export
 * @interface ResGasContract
 */
export interface ResGasContract {
    /**
     * ID
     * @type {number}
     * @memberof ResGasContract
     */
    id?: number;
    /**
     * CISお客様番号
     * @type {string}
     * @memberof ResGasContract
     */
    cis_customer_no?: string;
    /**
     * 契約ステータス区分
     * @type {string}
     * @memberof ResGasContract
     */
    contract_status_type?: string;
    /**
     * 供給地点特定番号
     * @type {string}
     * @memberof ResGasContract
     */
    supply_point_no?: string;
    /**
     * 契約メニュー名
     * @type {string}
     * @memberof ResGasContract
     */
    contract_menu_name?: string;
    /**
     * 契約開始日
     * @type {string}
     * @memberof ResGasContract
     */
    contract_start_ymd?: string;
    /**
     * 契約終了日
     * @type {string}
     * @memberof ResGasContract
     */
    contract_end_ymd?: string;
    /**
     * 需要者氏名
     * @type {string}
     * @memberof ResGasContract
     */
    consumer_name?: string;
    /**
     * 需要者氏名（カナ）
     * @type {string}
     * @memberof ResGasContract
     */
    consumer_kana_name?: string;
    /**
     * 需要者電話番号
     * @type {string}
     * @memberof ResGasContract
     */
    consumer_phone?: string;
    /**
     * 需要者FAX番号
     * @type {string}
     * @memberof ResGasContract
     */
    consumer_fax_no?: string;
    /**
     * 需要者郵便番号
     * @type {string}
     * @memberof ResGasContract
     */
    consumer_zipcode?: string;
    /**
     * 需要者住所
     * @type {string}
     * @memberof ResGasContract
     */
    consumer_address?: string;
    /**
     * 契約者住所区分
     * @type {string}
     * @memberof ResGasContract
     */
    contractor_addr_typ?: string;
    /**
     * 契約者氏名
     * @type {string}
     * @memberof ResGasContract
     */
    contractor_name?: string;
    /**
     * 契約者氏名（カナ）
     * @type {string}
     * @memberof ResGasContract
     */
    contractor_kana_name?: string;
    /**
     * 契約者電話番号
     * @type {string}
     * @memberof ResGasContract
     */
    contractor_phone?: string;
    /**
     * 契約者FAX番号
     * @type {string}
     * @memberof ResGasContract
     */
    contractor_fax_no?: string;
    /**
     * 契約者郵便番号
     * @type {string}
     * @memberof ResGasContract
     */
    contractor_zipcode?: string;
    /**
     * 契約者住所
     * @type {string}
     * @memberof ResGasContract
     */
    contractor_address?: string;
    /**
     * 契約者メールアドレス
     * @type {string}
     * @memberof ResGasContract
     */
    contractor_mail_address?: string;
    /**
     * 請求先住所区分
     * @type {string}
     * @memberof ResGasContract
     */
    billing_dest_addr_typ?: string;
    /**
     * 請求先氏名
     * @type {string}
     * @memberof ResGasContract
     */
    billing_dest_name?: string;
    /**
     * 請求先氏名（カナ）
     * @type {string}
     * @memberof ResGasContract
     */
    billing_dest_kana_name?: string;
    /**
     * 請求先電話番号
     * @type {string}
     * @memberof ResGasContract
     */
    billing_dest_phone?: string;
    /**
     * 請求先FAX番号
     * @type {string}
     * @memberof ResGasContract
     */
    billing_dest_fax_no?: string;
    /**
     * 請求先郵便番号
     * @type {string}
     * @memberof ResGasContract
     */
    billing_dest_zipcode?: string;
    /**
     * 請求先住所
     * @type {string}
     * @memberof ResGasContract
     */
    billing_dest_address?: string;
    /**
     * エリア名
     * @type {string}
     * @memberof ResGasContract
     */
    area_name?: string;
    /**
     * 支払方法名
     * @type {string}
     * @memberof ResGasContract
     */
    payment_method_name?: string;
    /**
     * 接続供給開始日
     * @type {string}
     * @memberof ResGasContract
     */
    connection_supply_start_ymd?: string;
}
/**
 * ガス契約及びそれに紐づく全ての請求ヘッダ
 * @export
 * @interface ResGasContractInvoice
 */
export interface ResGasContractInvoice {
    /**
     * 
     * @type {ResGasContract}
     * @memberof ResGasContractInvoice
     */
    contract?: ResGasContract;
    /**
     * ガス請求(最大24か月分)
     * @type {Array<ResGasInvoice>}
     * @memberof ResGasContractInvoice
     */
    invoices?: Array<ResGasInvoice>;
}
/**
 * ガス請求
 * @export
 * @interface ResGasInvoice
 */
export interface ResGasInvoice {
    /**
     * ID
     * @type {number}
     * @memberof ResGasInvoice
     */
    id?: number;
    /**
     * 顧客契約ID
     * @type {number}
     * @memberof ResGasInvoice
     */
    customer_contract_id?: number;
    /**
     * 伝票番号
     * @type {string}
     * @memberof ResGasInvoice
     */
    slip_no?: string;
    /**
     * 供給地点特定番号
     * @type {string}
     * @memberof ResGasInvoice
     */
    supply_point_no?: string;
    /**
     * 伝票日付
     * @type {string}
     * @memberof ResGasInvoice
     */
    slip_ymd?: string;
    /**
     * 発行者名
     * @type {string}
     * @memberof ResGasInvoice
     */
    issuer_name?: string;
    /**
     * 発行者電話番号
     * @type {string}
     * @memberof ResGasInvoice
     */
    issuer_phone?: string;
    /**
     * 発行者住所
     * @type {string}
     * @memberof ResGasInvoice
     */
    issuer_addr?: string;
    /**
     * 対象年月
     * @type {string}
     * @memberof ResGasInvoice
     */
    target_ym?: string;
    /**
     * 当月使用量
     * @type {string}
     * @memberof ResGasInvoice
     */
    this_m_used_amount?: string;
    /**
     * 当月請求額（税込）
     * @type {string}
     * @memberof ResGasInvoice
     */
    this_m_tax_included_invoice_amount?: string;
    /**
     * 消費税額
     * @type {string}
     * @memberof ResGasInvoice
     */
    con_tax_amount?: string;
    /**
     * 当月検針日
     * @type {string}
     * @memberof ResGasInvoice
     */
    this_m_meter_read_ymd?: string;
    /**
     * 当月利用開始日
     * @type {string}
     * @memberof ResGasInvoice
     */
    this_m_usage_start_ymd?: string;
    /**
     * 当月利用終了日
     * @type {string}
     * @memberof ResGasInvoice
     */
    this_m_usage_end_ymd?: string;
    /**
     * 当月使用期間
     * @type {string}
     * @memberof ResGasInvoice
     */
    this_m_use_term?: string;
    /**
     * 前月指示数
     * @type {string}
     * @memberof ResGasInvoice
     */
    last_m_instruction_num?: string | null;
    /**
     * 当月指示数
     * @type {string}
     * @memberof ResGasInvoice
     */
    this_m_instruction_num?: string;
    /**
     * 次回検針予定日
     * @type {string}
     * @memberof ResGasInvoice
     */
    next_meter_read_expected_ymd?: string;
    /**
     * 前年同月使用期間
     * @type {string}
     * @memberof ResGasInvoice
     */
    year_ago_m_use_term?: string | null;
    /**
     * 前年同月使用量
     * @type {string}
     * @memberof ResGasInvoice
     */
    year_ago_m_used_amount?: string | null;
    /**
     * 前月使用期間
     * @type {string}
     * @memberof ResGasInvoice
     */
    last_m_use_term?: string | null;
    /**
     * 前月使用量
     * @type {string}
     * @memberof ResGasInvoice
     */
    last_m_used_amount?: string | null;
    /**
     * 当月適用単位料金_適用料金表
     * @type {string}
     * @memberof ResGasInvoice
     */
    this_m_apply_unit_fee_table?: string;
    /**
     * 当月適用単位料金_単位料金
     * @type {string}
     * @memberof ResGasInvoice
     */
    this_m_apply_unit_fee?: string;
}
/**
 * ガス請求明細
 * @export
 * @interface ResGasInvoiceDetail
 */
export interface ResGasInvoiceDetail {
    /**
     * ID
     * @type {number}
     * @memberof ResGasInvoiceDetail
     */
    id?: number;
    /**
     * 請求ID
     * @type {number}
     * @memberof ResGasInvoiceDetail
     */
    invoice_id?: number;
    /**
     * 請求明細番号
     * @type {number}
     * @memberof ResGasInvoiceDetail
     */
    invoice_detail_no?: number;
    /**
     * 明細コード
     * @type {string}
     * @memberof ResGasInvoiceDetail
     */
    detail_cd?: string;
    /**
     * 商品名
     * @type {string}
     * @memberof ResGasInvoiceDetail
     */
    item_name?: string;
    /**
     * 数量
     * @type {string}
     * @memberof ResGasInvoiceDetail
     */
    qty?: string;
    /**
     * 単価（税込）
     * @type {string}
     * @memberof ResGasInvoiceDetail
     */
    tax_included_unit_price?: string;
    /**
     * 金額（税込）
     * @type {string}
     * @memberof ResGasInvoiceDetail
     */
    tax_included_amount?: string;
    /**
     * 消費税額
     * @type {string}
     * @memberof ResGasInvoiceDetail
     */
    con_tax_amount?: string;
}
/**
 * イベント在庫取得レスポンス
 * @export
 * @interface ResGetEvent
 */
export interface ResGetEvent {
    /**
     * イベント内選択肢ID
     * @type {string}
     * @memberof ResGetEvent
     */
    event_option_id: string;
    /**
     * 残席数 イベントへの参加申込み可能残数。 残席数が10より多い場合nullを返却し、10以下の場合は値を返却する。 残席数が0より少ない(座席上限を超えた申込を受付け済)の場合、残席数は0として返却する。
     * @type {number}
     * @memberof ResGetEvent
     */
    remain_seats: number | null;
}
/**
 * イベント申込履歴取得レスポンス
 * @export
 * @interface ResGetEventEntry
 */
export interface ResGetEventEntry extends ResGetEventEntryAllOf {
}


/**
 * 
 * @export
 * @interface ResGetEventEntryAllOf
 */
export interface ResGetEventEntryAllOf {
    /**
     * イベントID
     * @type {string}
     * @memberof ResGetEventEntryAllOf
     */
    event_id: string;
    /**
     * イベント内選択肢ID
     * @type {string}
     * @memberof ResGetEventEntryAllOf
     */
    event_option_id: string;
    /**
     * イベント申込履歴ID
     * @type {number}
     * @memberof ResGetEventEntryAllOf
     */
    event_entry_id: number;
    /**
     * イベント申込日時
     * @type {string}
     * @memberof ResGetEventEntryAllOf
     */
    event_entry_dt: string;
    /**
     * 大人申込人数
     * @type {number}
     * @memberof ResGetEventEntryAllOf
     */
    adult_num: number;
    /**
     * 子供申込人数 0の時は子供の参加人数が0人 null(key自体がない)の場合はそもそもadult/childの申込区分け自体がないイベント
     * @type {number}
     * @memberof ResGetEventEntryAllOf
     */
    child_num?: number;
    /**
     * 合計金額
     * @type {number}
     * @memberof ResGetEventEntryAllOf
     */
    pay_amount: number;
    /**
     * 備考
     * @type {string}
     * @memberof ResGetEventEntryAllOf
     */
    note: string;
    /**
     * イベント消込ステータス (00:消込対象でない、01:未消込、02:消込済)
     * @type {string}
     * @memberof ResGetEventEntryAllOf
     */
    used_status: ResGetEventEntryAllOfUsedStatusEnum;
    /**
     * イベント消込日時
     * @type {string}
     * @memberof ResGetEventEntryAllOf
     */
    used_dt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ResGetEventEntryAllOfUsedStatusEnum {
    _00 = '00',
    _01 = '01',
    _02 = '02'
}

/**
 * 限定消込クーポン履歴取得レスポンス
 * @export
 * @interface ResGetLimitedExpendableCouponHistories
 */
export interface ResGetLimitedExpendableCouponHistories {
    /**
     * 1ページに表示する履歴の件数
     * @type {number}
     * @memberof ResGetLimitedExpendableCouponHistories
     */
    limit: number;
    /**
     * 履歴の取得開始位置（件数）
     * @type {number}
     * @memberof ResGetLimitedExpendableCouponHistories
     */
    offset: number;
    /**
     * 履歴の総数
     * @type {number}
     * @memberof ResGetLimitedExpendableCouponHistories
     */
    total_count: number;
    /**
     * 
     * @type {Array<ResGetLimitedExpendableCouponHistoriesContents>}
     * @memberof ResGetLimitedExpendableCouponHistories
     */
    contents: Array<ResGetLimitedExpendableCouponHistoriesContents>;
}
/**
 * 限定消込クーポン消込履歴
 * @export
 * @interface ResGetLimitedExpendableCouponHistoriesContents
 */
export interface ResGetLimitedExpendableCouponHistoriesContents {
    /**
     * 限定消込クーポンID
     * @type {string}
     * @memberof ResGetLimitedExpendableCouponHistoriesContents
     */
    coupon_id: string;
    /**
     * 消込日時
     * @type {string}
     * @memberof ResGetLimitedExpendableCouponHistoriesContents
     */
    use_dt: string;
    /**
     * 消込枚数
     * @type {number}
     * @memberof ResGetLimitedExpendableCouponHistoriesContents
     */
    use_num: number;
}
/**
 * クーポン情報
 * @export
 * @interface ResGetLimitedExpendableCoupons
 */
export interface ResGetLimitedExpendableCoupons {
    /**
     * クーポンID
     * @type {string}
     * @memberof ResGetLimitedExpendableCoupons
     */
    coupon_id: string;
    /**
     * クーポン名
     * @type {string}
     * @memberof ResGetLimitedExpendableCoupons
     */
    coupon_name: string;
    /**
     * サムネイル
     * @type {string}
     * @memberof ResGetLimitedExpendableCoupons
     */
    thumbnail: string;
    /**
     * メイン画像
     * @type {string}
     * @memberof ResGetLimitedExpendableCoupons
     */
    main_image: string;
    /**
     * ロゴ
     * @type {string}
     * @memberof ResGetLimitedExpendableCoupons
     */
    logo: string;
    /**
     * 見出し
     * @type {string}
     * @memberof ResGetLimitedExpendableCoupons
     */
    summary: string;
    /**
     * 説明文
     * @type {string}
     * @memberof ResGetLimitedExpendableCoupons
     */
    explanation: string;
    /**
     * 消込注意文言
     * @type {string}
     * @memberof ResGetLimitedExpendableCoupons
     */
    caution?: string;
    /**
     * 配布対象サブスクパッケージ
     * @type {Array<ResGetLimitedOnlineCouponsSubscriptionPackages>}
     * @memberof ResGetLimitedExpendableCoupons
     */
    subscription_packages: Array<ResGetLimitedOnlineCouponsSubscriptionPackages>;
    /**
     * 配布開始日時
     * @type {string}
     * @memberof ResGetLimitedExpendableCoupons
     */
    distribute_start_dt: string;
    /**
     * 配布終了日時
     * @type {string}
     * @memberof ResGetLimitedExpendableCoupons
     */
    distribute_end_dt: string;
    /**
     * 配布日に依存しない有効期限
     * @type {string}
     * @memberof ResGetLimitedExpendableCoupons
     */
    absolute_expire_dt: string;
    /**
     * 配布日からの有効期限
     * @type {number}
     * @memberof ResGetLimitedExpendableCoupons
     */
    validity_months: number;
    /**
     * 同一人物複数回発行禁止フラグ
     * @type {boolean}
     * @memberof ResGetLimitedExpendableCoupons
     */
    once_flg: boolean;
    /**
     * 配布間隔
     * @type {number}
     * @memberof ResGetLimitedExpendableCoupons
     */
    distribute_interval: number;
    /**
     * 発行枚数
     * @type {number}
     * @memberof ResGetLimitedExpendableCoupons
     */
    issue_num: number;
    /**
     * 1日の利用可能枚数
     * @type {number}
     * @memberof ResGetLimitedExpendableCoupons
     */
    use_limit_num_per_day?: number;
    /**
     * 確認コード
     * @type {string}
     * @memberof ResGetLimitedExpendableCoupons
     */
    auth_code?: string;
    /**
     * 消込店舗
     * @type {Array<ResGetLimitedExpendableCouponsStores>}
     * @memberof ResGetLimitedExpendableCoupons
     */
    stores?: Array<ResGetLimitedExpendableCouponsStores>;
    /**
     * 本日の消込回数
     * @type {number}
     * @memberof ResGetLimitedExpendableCoupons
     */
    used_num_today: number;
    /**
     * 使用可能な配布済クーポン情報
     * @type {Array<ResGetLimitedExpendableCouponsDistributions>}
     * @memberof ResGetLimitedExpendableCoupons
     */
    distributions: Array<ResGetLimitedExpendableCouponsDistributions>;
    /**
     * 次回配布情報
     * @type {Array<ResGetLimitedExpendableCouponsNextDistributions>}
     * @memberof ResGetLimitedExpendableCoupons
     */
    next_distributions: Array<ResGetLimitedExpendableCouponsNextDistributions>;
    /**
     * 残数非表示フラグ
     * @type {boolean}
     * @memberof ResGetLimitedExpendableCoupons
     */
    hide_remaining_flg: boolean;
}
/**
 * 使用可能な配布済クーポン情報
 * @export
 * @interface ResGetLimitedExpendableCouponsDistributions
 */
export interface ResGetLimitedExpendableCouponsDistributions {
    /**
     * サブスクパッケージID
     * @type {string}
     * @memberof ResGetLimitedExpendableCouponsDistributions
     */
    subscription_package_id: string;
    /**
     * サブスクパッケージ名
     * @type {string}
     * @memberof ResGetLimitedExpendableCouponsDistributions
     */
    subscription_package_name: string;
    /**
     * 配布日時
     * @type {string}
     * @memberof ResGetLimitedExpendableCouponsDistributions
     */
    activate_dt: string;
    /**
     * 有効期限日時
     * @type {string}
     * @memberof ResGetLimitedExpendableCouponsDistributions
     */
    expire_dt: string;
    /**
     * 残消込回数
     * @type {number}
     * @memberof ResGetLimitedExpendableCouponsDistributions
     */
    remain_use_num: number;
}
/**
 * 次回配布情報
 * @export
 * @interface ResGetLimitedExpendableCouponsNextDistributions
 */
export interface ResGetLimitedExpendableCouponsNextDistributions {
    /**
     * サブスクパッケージID
     * @type {string}
     * @memberof ResGetLimitedExpendableCouponsNextDistributions
     */
    subscription_package_id: string;
    /**
     * サブスクパッケージ名
     * @type {string}
     * @memberof ResGetLimitedExpendableCouponsNextDistributions
     */
    subscription_package_name: string;
    /**
     * 次回配布日時
     * @type {string}
     * @memberof ResGetLimitedExpendableCouponsNextDistributions
     */
    next_distribute_dt: string;
}
/**
 * 
 * @export
 * @interface ResGetLimitedExpendableCouponsStores
 */
export interface ResGetLimitedExpendableCouponsStores {
    /**
     * 店舗ID
     * @type {string}
     * @memberof ResGetLimitedExpendableCouponsStores
     */
    store_id: string;
    /**
     * 店舗名
     * @type {string}
     * @memberof ResGetLimitedExpendableCouponsStores
     */
    store_name: string;
}
/**
 * 限定オンラインクーポン取得レスポンス
 * @export
 * @interface ResGetLimitedOnlineCoupons
 */
export interface ResGetLimitedOnlineCoupons {
    /**
     * 限定オンラインクーポンの取得件数
     * @type {number}
     * @memberof ResGetLimitedOnlineCoupons
     */
    total_count: number;
    /**
     * 限定オンラインクーポンの取得開始位置（件数）
     * @type {number}
     * @memberof ResGetLimitedOnlineCoupons
     */
    offset: number;
    /**
     * 1ページに表示する限定オンラインクーポンの件数
     * @type {number}
     * @memberof ResGetLimitedOnlineCoupons
     */
    limit: number;
    /**
     * 限定オンラインクーポン情報リスト
     * @type {Array<ResGetLimitedOnlineCouponsContents>}
     * @memberof ResGetLimitedOnlineCoupons
     */
    contents: Array<ResGetLimitedOnlineCouponsContents>;
}
/**
 * 限定オンラインクーポン情報
 * @export
 * @interface ResGetLimitedOnlineCouponsContents
 */
export interface ResGetLimitedOnlineCouponsContents {
    /**
     * クーポンID
     * @type {string}
     * @memberof ResGetLimitedOnlineCouponsContents
     */
    id: string;
    /**
     * クーポン効果
     * @type {string}
     * @memberof ResGetLimitedOnlineCouponsContents
     */
    effect: string;
    /**
     * クーポン名
     * @type {string}
     * @memberof ResGetLimitedOnlineCouponsContents
     */
    name: string;
    /**
     * クーポン内容説明
     * @type {string}
     * @memberof ResGetLimitedOnlineCouponsContents
     */
    explanation: string;
    /**
     * 見出し
     * @type {string}
     * @memberof ResGetLimitedOnlineCouponsContents
     */
    summary: string;
    /**
     * クーポン詳細
     * @type {string}
     * @memberof ResGetLimitedOnlineCouponsContents
     */
    detail?: string;
    /**
     * メイン画像URL
     * @type {string}
     * @memberof ResGetLimitedOnlineCouponsContents
     */
    main_image: string;
    /**
     * サムネイル画像URL
     * @type {string}
     * @memberof ResGetLimitedOnlineCouponsContents
     */
    thumbnail: string;
    /**
     * 画像に関するサービスロゴ画像URL
     * @type {string}
     * @memberof ResGetLimitedOnlineCouponsContents
     */
    logo: string;
    /**
     * クーポンコード
     * @type {string}
     * @memberof ResGetLimitedOnlineCouponsContents
     */
    code?: string;
    /**
     * サービス提供元URL
     * @type {string}
     * @memberof ResGetLimitedOnlineCouponsContents
     */
    url: string;
    /**
     * 表示パターン
     * @type {number}
     * @memberof ResGetLimitedOnlineCouponsContents
     */
    display_pattern: number;
    /**
     * サブスクパッケージ
     * @type {Array<ResGetLimitedOnlineCouponsSubscriptionPackages>}
     * @memberof ResGetLimitedOnlineCouponsContents
     */
    subscription_packages: Array<ResGetLimitedOnlineCouponsSubscriptionPackages>;
}
/**
 * 
 * @export
 * @interface ResGetLimitedOnlineCouponsSubscriptionPackages
 */
export interface ResGetLimitedOnlineCouponsSubscriptionPackages {
    /**
     * サブスクパッケージID
     * @type {string}
     * @memberof ResGetLimitedOnlineCouponsSubscriptionPackages
     */
    subscription_package_id: string;
    /**
     * サブスクパッケージ名
     * @type {string}
     * @memberof ResGetLimitedOnlineCouponsSubscriptionPackages
     */
    subscription_package_name: string;
}
/**
 * 
 * @export
 * @interface ResPointDetail
 */
export interface ResPointDetail {
    /**
     * UID
     * @type {string}
     * @memberof ResPointDetail
     */
    uid?: string;
    /**
     * 提携サービス利用実績ID
     * @type {string}
     * @memberof ResPointDetail
     */
    alliance_service_usage_record_id?: string;
    /**
     * ポイント発行日時
     * @type {string}
     * @memberof ResPointDetail
     */
    point_issuing_dt?: string;
    /**
     * ポイント数
     * @type {number}
     * @memberof ResPointDetail
     */
    point_cnt?: number;
    /**
     * ポイント摘要
     * @type {string}
     * @memberof ResPointDetail
     */
    point_abstract?: string;
    /**
     * ID
     * @type {number}
     * @memberof ResPointDetail
     */
    id: number;
    /**
     * 登録プログムコード
     * @type {string}
     * @memberof ResPointDetail
     */
    create_pgm_cd: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof ResPointDetail
     */
    create_user_id: string;
    /**
     * 登録日時
     * @type {string}
     * @memberof ResPointDetail
     */
    create_dt: string;
    /**
     * 更新プログラムコード
     * @type {string}
     * @memberof ResPointDetail
     */
    update_pgm_cd: string;
    /**
     * 更新ユーザーID
     * @type {string}
     * @memberof ResPointDetail
     */
    update_user_id: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof ResPointDetail
     */
    update_dt: string;
    /**
     * パッチコード
     * @type {string}
     * @memberof ResPointDetail
     */
    patch_cd: string;
    /**
     * パッチユーザーID
     * @type {string}
     * @memberof ResPointDetail
     */
    patch_user_id: string;
    /**
     * パッチ日時
     * @type {string}
     * @memberof ResPointDetail
     */
    patch_dt: string;
}
/**
 * サブスク会員登録レスポンス
 * @export
 * @interface ResPostSubscription
 */
export interface ResPostSubscription {
    /**
     * パッケージ名
     * @type {string}
     * @memberof ResPostSubscription
     */
    package_name: string;
    /**
     * サブスク開始日時
     * @type {string}
     * @memberof ResPostSubscription
     */
    start_dt: string;
    /**
     * サブスク終了日時
     * @type {string}
     * @memberof ResPostSubscription
     */
    end_dt: string;
    /**
     * 支払料金 登録状況により初回登録時料金か更新料金のいずれかとなる 
     * @type {number}
     * @memberof ResPostSubscription
     */
    current_pay_amount: number;
    /**
     * 更新料金
     * @type {number}
     * @memberof ResPostSubscription
     */
    next_pay_amount: number;
    /**
     * サブスクパッケージの更新間隔。単位は月
     * @type {number}
     * @memberof ResPostSubscription
     */
    pay_interval: number;
    /**
     * 最終契約フラグ(true:パッケージ終了に伴う最終契約 false:今後も継続更新が予定されている契約)
     * @type {boolean}
     * @memberof ResPostSubscription
     */
    last_contract_flg: boolean;
}
/**
 * Service Usage response
 * @export
 * @interface ResServiceUsage
 */
export interface ResServiceUsage {
    /**
     * UID
     * @type {string}
     * @memberof ResServiceUsage
     */
    uid?: string;
    /**
     * 提携サービスID
     * @type {number}
     * @memberof ResServiceUsage
     */
    alliance_service_id?: number;
    /**
     * 提携サービスクライアントID
     * @type {string}
     * @memberof ResServiceUsage
     */
    alliance_service_client_id?: string;
    /**
     * 提携サービス名
     * @type {string}
     * @memberof ResServiceUsage
     */
    alliance_service_name?: string;
    /**
     * ポイント明細ID
     * @type {number}
     * @memberof ResServiceUsage
     */
    point_detail_id?: number;
    /**
     * ポイント発行日時
     * @type {string}
     * @memberof ResServiceUsage
     */
    point_issuing_dt?: string;
    /**
     * ポイント数
     * @type {string}
     * @memberof ResServiceUsage
     */
    point_cnt?: string;
    /**
     * ポイント摘要
     * @type {string}
     * @memberof ResServiceUsage
     */
    point_abstract?: string;
    /**
     * 提携サービス利用実績ID
     * @type {number}
     * @memberof ResServiceUsage
     */
    alliance_service_usage_record_id?: number;
    /**
     * 利用内容
     * @type {string}
     * @memberof ResServiceUsage
     */
    usage_content?: string;
    /**
     * 利用日時
     * @type {string}
     * @memberof ResServiceUsage
     */
    usage_dt?: string;
    /**
     * 取引合計金額
     * @type {number}
     * @memberof ResServiceUsage
     */
    trade_total_amount?: number;
    /**
     * 取引コード
     * @type {string}
     * @memberof ResServiceUsage
     */
    trade_cd?: string;
    /**
     * 取消取引コード
     * @type {string}
     * @memberof ResServiceUsage
     */
    trade_cancel_cd?: string;
}
/**
 * サブスク登録情報取得レスポンス
 * @export
 * @interface ResSubscriptionInfo
 */
export interface ResSubscriptionInfo {
    /**
     * 
     * @type {ResSubscriptionInfoCardInfo}
     * @memberof ResSubscriptionInfo
     */
    card_info: ResSubscriptionInfoCardInfo;
    /**
     * パッケージごとのサブスク契約情報
     * @type {Array<SubscriptionContract>}
     * @memberof ResSubscriptionInfo
     */
    subscription_contracts: Array<SubscriptionContract>;
}
/**
 * 登録中のクレジットカード情報
 * @export
 * @interface ResSubscriptionInfoCardInfo
 */
export interface ResSubscriptionInfoCardInfo {
    /**
     * マスキングされたカード番号（最小11桁、最大16桁）
     * @type {string}
     * @memberof ResSubscriptionInfoCardInfo
     */
    masked_card_number: string;
    /**
     * カード有効期限（YYMM）
     * @type {string}
     * @memberof ResSubscriptionInfoCardInfo
     */
    expiry_ym: string;
}
/**
 * パッケージごとのサブスク契約情報
 * @export
 * @interface SubscriptionContract
 */
export interface SubscriptionContract {
    /**
     * サブスクパッケージID
     * @type {string}
     * @memberof SubscriptionContract
     */
    subscription_package_id: string;
    /**
     * サブスク契約開始日時（ユーザーが画面から申し込んだ最新の日時を返却する）
     * @type {string}
     * @memberof SubscriptionContract
     */
    start_dt: string;
    /**
     * サブスク契約終了日時
     * @type {string}
     * @memberof SubscriptionContract
     */
    end_dt: string;
    /**
     * 自動更新フラグ（true:更新予定 false:退会予定）
     * @type {boolean}
     * @memberof SubscriptionContract
     */
    continue_flg: boolean;
    /**
     * 決済エラーコード
     * @type {string}
     * @memberof SubscriptionContract
     */
    pay_error: string;
    /**
     * 最終契約フラグ(true:パッケージ終了に伴う最終契約 false:今後も継続更新が予定されている契約)
     * @type {boolean}
     * @memberof SubscriptionContract
     */
    last_contract_flg: boolean;
}
/**
 * 
 * @export
 * @interface SystemCommon
 */
export interface SystemCommon {
    /**
     * ID
     * @type {number}
     * @memberof SystemCommon
     */
    id: number;
    /**
     * 登録プログムコード
     * @type {string}
     * @memberof SystemCommon
     */
    create_pgm_cd: string;
    /**
     * 登録ユーザーID
     * @type {string}
     * @memberof SystemCommon
     */
    create_user_id: string;
    /**
     * 登録日時
     * @type {string}
     * @memberof SystemCommon
     */
    create_dt: string;
    /**
     * 更新プログラムコード
     * @type {string}
     * @memberof SystemCommon
     */
    update_pgm_cd: string;
    /**
     * 更新ユーザーID
     * @type {string}
     * @memberof SystemCommon
     */
    update_user_id: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof SystemCommon
     */
    update_dt: string;
    /**
     * パッチコード
     * @type {string}
     * @memberof SystemCommon
     */
    patch_cd: string;
    /**
     * パッチユーザーID
     * @type {string}
     * @memberof SystemCommon
     */
    patch_user_id: string;
    /**
     * パッチ日時
     * @type {string}
     * @memberof SystemCommon
     */
    patch_dt: string;
}

/**
 * AdminAllianceServicesApi - axios parameter creator
 * @export
 */
export const AdminAllianceServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsageRecordsInfo: async (uid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getUsageRecordsInfo.');
            }
            const localVarPath = `/admin/users/{uid}/alliance_service_usage_records`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-support-center"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAllianceServicesApi - functional programming interface
 * @export
 */
export const AdminAllianceServicesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsageRecordsInfo(uid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAdminAllianceServiceUsageRecords>> {
            const localVarAxiosArgs = await AdminAllianceServicesApiAxiosParamCreator(configuration).getUsageRecordsInfo(uid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminAllianceServicesApi - factory interface
 * @export
 */
export const AdminAllianceServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsageRecordsInfo(uid: string, options?: any): AxiosPromise<ResAdminAllianceServiceUsageRecords> {
            return AdminAllianceServicesApiFp(configuration).getUsageRecordsInfo(uid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminAllianceServicesApi - object-oriented interface
 * @export
 * @class AdminAllianceServicesApi
 * @extends {BaseAPI}
 */
export class AdminAllianceServicesApi extends BaseAPI {
    /**
     * 
     * @param {string} uid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAllianceServicesApi
     */
    public getUsageRecordsInfo(uid: string, options?: any) {
        return AdminAllianceServicesApiFp(this.configuration).getUsageRecordsInfo(uid, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * AdminElectricityApi - axios parameter creator
 * @export
 */
export const AdminElectricityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 小田急でんき契約単票参照
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getContract.');
            }
            const localVarPath = `/admin/electricity_contract/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-support-center", "ens-support-center"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminElectricityApi - functional programming interface
 * @export
 */
export const AdminElectricityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 小田急でんき契約単票参照
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContract(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAdminElectricityContract>> {
            const localVarAxiosArgs = await AdminElectricityApiAxiosParamCreator(configuration).getContract(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminElectricityApi - factory interface
 * @export
 */
export const AdminElectricityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 小田急でんき契約単票参照
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract(id: number, options?: any): AxiosPromise<ResAdminElectricityContract> {
            return AdminElectricityApiFp(configuration).getContract(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminElectricityApi - object-oriented interface
 * @export
 * @class AdminElectricityApi
 * @extends {BaseAPI}
 */
export class AdminElectricityApi extends BaseAPI {
    /**
     * 小田急でんき契約単票参照
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminElectricityApi
     */
    public getContract(id: number, options?: any) {
        return AdminElectricityApiFp(this.configuration).getContract(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * AdminEnergyApi - axios parameter creator
 * @export
 */
export const AdminEnergyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 検索条件に合致した小田急でんき・ガスの契約情報を取得
         * @param {string} [contractNo] でんき顧客契約の契約番号またはガス顧客のCISお客さま番号
         * @param {string} [supplyPointNo] 顧客契約の供給地点特定番号
         * @param {string} [contractStatusType] 契約ステータス区分
         * @param {string} [contractorName] 契約者氏名
         * @param {string} [contractorKanaName] 契約者氏名（カナ）
         * @param {string} [contractorPhone] 契約者電話番号
         * @param {string} [contractorMailAddress] 契約者メールアドレス
         * @param {string} [contractorAddress] 契約者住所
         * @param {string} [electricityGasTyp] でんきガス区分
         * @param {string} [linkStatusTyp] 紐付けステータス区分
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminEnergyContracts: async (contractNo?: string, supplyPointNo?: string, contractStatusType?: string, contractorName?: string, contractorKanaName?: string, contractorPhone?: string, contractorMailAddress?: string, contractorAddress?: string, electricityGasTyp?: string, linkStatusTyp?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/energy_contracts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-support-center", "ens-support-center"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (contractNo !== undefined) {
                localVarQueryParameter['contract_no'] = contractNo;
            }

            if (supplyPointNo !== undefined) {
                localVarQueryParameter['supply_point_no'] = supplyPointNo;
            }

            if (contractStatusType !== undefined) {
                localVarQueryParameter['contract_status_type'] = contractStatusType;
            }

            if (contractorName !== undefined) {
                localVarQueryParameter['contractor_name'] = contractorName;
            }

            if (contractorKanaName !== undefined) {
                localVarQueryParameter['contractor_kana_name'] = contractorKanaName;
            }

            if (contractorPhone !== undefined) {
                localVarQueryParameter['contractor_phone'] = contractorPhone;
            }

            if (contractorMailAddress !== undefined) {
                localVarQueryParameter['contractor_mail_address'] = contractorMailAddress;
            }

            if (contractorAddress !== undefined) {
                localVarQueryParameter['contractor_address'] = contractorAddress;
            }

            if (electricityGasTyp !== undefined) {
                localVarQueryParameter['electricity_gas_typ'] = electricityGasTyp;
            }

            if (linkStatusTyp !== undefined) {
                localVarQueryParameter['link_status_typ'] = linkStatusTyp;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザ情報単票更新ページの紐づき電ガス契約一覧取得API
         * @param {string} uid Auth0 uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUsersElectricityGasContracts: async (uid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getAdminUsersElectricityGasContracts.');
            }
            const localVarPath = `/admin/users/{uid}/electricity_gas_contracts`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-support-center", "auth0-system-access"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 小田急でんき・ガス単票ページで共通利用する紐づけ情報取得用のAPI
         * @param {'01' | '02'} energyType 電ガス区分 -&gt; 01：小田急でんき, 02：小田急ガス
         * @param {number} customerContractId 小田急でんき・ガス顧客契約テーブルのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyLinkedInfo: async (energyType: '01' | '02', customerContractId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'energyType' is not null or undefined
            if (energyType === null || energyType === undefined) {
                throw new RequiredError('energyType','Required parameter energyType was null or undefined when calling getEnergyLinkedInfo.');
            }
            // verify required parameter 'customerContractId' is not null or undefined
            if (customerContractId === null || customerContractId === undefined) {
                throw new RequiredError('customerContractId','Required parameter customerContractId was null or undefined when calling getEnergyLinkedInfo.');
            }
            const localVarPath = `/admin/energy_linked_info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-support-center", "ens-support-center"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (energyType !== undefined) {
                localVarQueryParameter['energy_type'] = energyType;
            }

            if (customerContractId !== undefined) {
                localVarQueryParameter['customer_contract_id'] = customerContractId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwitchContractLink: async (uid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getSwitchContractLink.');
            }
            const localVarPath = `/admin/users/{uid}/switch_contract_link`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-support-center", "ens-support-center"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminEnergyApi - functional programming interface
 * @export
 */
export const AdminEnergyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 検索条件に合致した小田急でんき・ガスの契約情報を取得
         * @param {string} [contractNo] でんき顧客契約の契約番号またはガス顧客のCISお客さま番号
         * @param {string} [supplyPointNo] 顧客契約の供給地点特定番号
         * @param {string} [contractStatusType] 契約ステータス区分
         * @param {string} [contractorName] 契約者氏名
         * @param {string} [contractorKanaName] 契約者氏名（カナ）
         * @param {string} [contractorPhone] 契約者電話番号
         * @param {string} [contractorMailAddress] 契約者メールアドレス
         * @param {string} [contractorAddress] 契約者住所
         * @param {string} [electricityGasTyp] でんきガス区分
         * @param {string} [linkStatusTyp] 紐付けステータス区分
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminEnergyContracts(contractNo?: string, supplyPointNo?: string, contractStatusType?: string, contractorName?: string, contractorKanaName?: string, contractorPhone?: string, contractorMailAddress?: string, contractorAddress?: string, electricityGasTyp?: string, linkStatusTyp?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResAdminEnergyContract>>> {
            const localVarAxiosArgs = await AdminEnergyApiAxiosParamCreator(configuration).getAdminEnergyContracts(contractNo, supplyPointNo, contractStatusType, contractorName, contractorKanaName, contractorPhone, contractorMailAddress, contractorAddress, electricityGasTyp, linkStatusTyp, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ユーザ情報単票更新ページの紐づき電ガス契約一覧取得API
         * @param {string} uid Auth0 uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminUsersElectricityGasContracts(uid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResAdminElectricityGasContract>>> {
            const localVarAxiosArgs = await AdminEnergyApiAxiosParamCreator(configuration).getAdminUsersElectricityGasContracts(uid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 小田急でんき・ガス単票ページで共通利用する紐づけ情報取得用のAPI
         * @param {'01' | '02'} energyType 電ガス区分 -&gt; 01：小田急でんき, 02：小田急ガス
         * @param {number} customerContractId 小田急でんき・ガス顧客契約テーブルのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnergyLinkedInfo(energyType: '01' | '02', customerContractId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAdminEnergyLinkedInfo>> {
            const localVarAxiosArgs = await AdminEnergyApiAxiosParamCreator(configuration).getEnergyLinkedInfo(energyType, customerContractId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSwitchContractLink(uid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAdminEnergySwitchContractLink>> {
            const localVarAxiosArgs = await AdminEnergyApiAxiosParamCreator(configuration).getSwitchContractLink(uid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminEnergyApi - factory interface
 * @export
 */
export const AdminEnergyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 検索条件に合致した小田急でんき・ガスの契約情報を取得
         * @param {string} [contractNo] でんき顧客契約の契約番号またはガス顧客のCISお客さま番号
         * @param {string} [supplyPointNo] 顧客契約の供給地点特定番号
         * @param {string} [contractStatusType] 契約ステータス区分
         * @param {string} [contractorName] 契約者氏名
         * @param {string} [contractorKanaName] 契約者氏名（カナ）
         * @param {string} [contractorPhone] 契約者電話番号
         * @param {string} [contractorMailAddress] 契約者メールアドレス
         * @param {string} [contractorAddress] 契約者住所
         * @param {string} [electricityGasTyp] でんきガス区分
         * @param {string} [linkStatusTyp] 紐付けステータス区分
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminEnergyContracts(contractNo?: string, supplyPointNo?: string, contractStatusType?: string, contractorName?: string, contractorKanaName?: string, contractorPhone?: string, contractorMailAddress?: string, contractorAddress?: string, electricityGasTyp?: string, linkStatusTyp?: string, options?: any): AxiosPromise<Array<ResAdminEnergyContract>> {
            return AdminEnergyApiFp(configuration).getAdminEnergyContracts(contractNo, supplyPointNo, contractStatusType, contractorName, contractorKanaName, contractorPhone, contractorMailAddress, contractorAddress, electricityGasTyp, linkStatusTyp, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザ情報単票更新ページの紐づき電ガス契約一覧取得API
         * @param {string} uid Auth0 uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUsersElectricityGasContracts(uid: string, options?: any): AxiosPromise<Array<ResAdminElectricityGasContract>> {
            return AdminEnergyApiFp(configuration).getAdminUsersElectricityGasContracts(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 小田急でんき・ガス単票ページで共通利用する紐づけ情報取得用のAPI
         * @param {'01' | '02'} energyType 電ガス区分 -&gt; 01：小田急でんき, 02：小田急ガス
         * @param {number} customerContractId 小田急でんき・ガス顧客契約テーブルのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyLinkedInfo(energyType: '01' | '02', customerContractId: number, options?: any): AxiosPromise<ResAdminEnergyLinkedInfo> {
            return AdminEnergyApiFp(configuration).getEnergyLinkedInfo(energyType, customerContractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwitchContractLink(uid: string, options?: any): AxiosPromise<ResAdminEnergySwitchContractLink> {
            return AdminEnergyApiFp(configuration).getSwitchContractLink(uid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminEnergyApi - object-oriented interface
 * @export
 * @class AdminEnergyApi
 * @extends {BaseAPI}
 */
export class AdminEnergyApi extends BaseAPI {
    /**
     * 検索条件に合致した小田急でんき・ガスの契約情報を取得
     * @param {string} [contractNo] でんき顧客契約の契約番号またはガス顧客のCISお客さま番号
     * @param {string} [supplyPointNo] 顧客契約の供給地点特定番号
     * @param {string} [contractStatusType] 契約ステータス区分
     * @param {string} [contractorName] 契約者氏名
     * @param {string} [contractorKanaName] 契約者氏名（カナ）
     * @param {string} [contractorPhone] 契約者電話番号
     * @param {string} [contractorMailAddress] 契約者メールアドレス
     * @param {string} [contractorAddress] 契約者住所
     * @param {string} [electricityGasTyp] でんきガス区分
     * @param {string} [linkStatusTyp] 紐付けステータス区分
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEnergyApi
     */
    public getAdminEnergyContracts(contractNo?: string, supplyPointNo?: string, contractStatusType?: string, contractorName?: string, contractorKanaName?: string, contractorPhone?: string, contractorMailAddress?: string, contractorAddress?: string, electricityGasTyp?: string, linkStatusTyp?: string, options?: any) {
        return AdminEnergyApiFp(this.configuration).getAdminEnergyContracts(contractNo, supplyPointNo, contractStatusType, contractorName, contractorKanaName, contractorPhone, contractorMailAddress, contractorAddress, electricityGasTyp, linkStatusTyp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザ情報単票更新ページの紐づき電ガス契約一覧取得API
     * @param {string} uid Auth0 uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEnergyApi
     */
    public getAdminUsersElectricityGasContracts(uid: string, options?: any) {
        return AdminEnergyApiFp(this.configuration).getAdminUsersElectricityGasContracts(uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 小田急でんき・ガス単票ページで共通利用する紐づけ情報取得用のAPI
     * @param {'01' | '02'} energyType 電ガス区分 -&gt; 01：小田急でんき, 02：小田急ガス
     * @param {number} customerContractId 小田急でんき・ガス顧客契約テーブルのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEnergyApi
     */
    public getEnergyLinkedInfo(energyType: '01' | '02', customerContractId: number, options?: any) {
        return AdminEnergyApiFp(this.configuration).getEnergyLinkedInfo(energyType, customerContractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEnergyApi
     */
    public getSwitchContractLink(uid: string, options?: any) {
        return AdminEnergyApiFp(this.configuration).getSwitchContractLink(uid, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * AdminGasApi - axios parameter creator
 * @export
 */
export const AdminGasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 小田急ガス契約単票参照
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGasContract: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getGasContract.');
            }
            const localVarPath = `/admin/gas_contract/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-support-center", "ens-support-center"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminGasApi - functional programming interface
 * @export
 */
export const AdminGasApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 小田急ガス契約単票参照
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGasContract(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAdminGasContract>> {
            const localVarAxiosArgs = await AdminGasApiAxiosParamCreator(configuration).getGasContract(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminGasApi - factory interface
 * @export
 */
export const AdminGasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 小田急ガス契約単票参照
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGasContract(id: number, options?: any): AxiosPromise<ResAdminGasContract> {
            return AdminGasApiFp(configuration).getGasContract(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminGasApi - object-oriented interface
 * @export
 * @class AdminGasApi
 * @extends {BaseAPI}
 */
export class AdminGasApi extends BaseAPI {
    /**
     * 小田急ガス契約単票参照
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGasApi
     */
    public getGasContract(id: number, options?: any) {
        return AdminGasApiFp(this.configuration).getGasContract(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * AdminUserApi - axios parameter creator
 * @export
 */
export const AdminUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfo: async (uid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getInfo.');
            }
            const localVarPath = `/admin/users/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-system-access"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [P0203]管理画面用ユーザ情報取得API 指定したONEユーザの登録情報を1件返却する 個人情報はマスキングする仕様とする
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaskedUserInfo: async (uid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getMaskedUserInfo.');
            }
            const localVarPath = `/admin/users/{uid}/masked`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-support-center", "auth0-system-access"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [P0087]ユーザ検索API 検索条件に合致したONEユーザの一覧を返却する 個人情報はマスキングする仕様とする
         * @param {string} [uid] UID
         * @param {string} [mailAddress] メールアドレス
         * @param {string} [birthYear] 生年月日（年）
         * @param {string} [birthMonth] 生年月日（月）
         * @param {string} [birthDay] 生年月日（日）
         * @param {string} [lastName] 氏
         * @param {string} [firstName] 名
         * @param {string} [hiraLastName] 氏（ひらがな）
         * @param {string} [hiraFirstName] 名（ひらがな）
         * @param {string} [phone] 電話番号
         * @param {string} [odakyuCustomerNo] 小田急お客さま番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAdminUsers: async (uid?: string, mailAddress?: string, birthYear?: string, birthMonth?: string, birthDay?: string, lastName?: string, firstName?: string, hiraLastName?: string, hiraFirstName?: string, phone?: string, odakyuCustomerNo?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-support-center"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (mailAddress !== undefined) {
                localVarQueryParameter['mail_address'] = mailAddress;
            }

            if (birthYear !== undefined) {
                localVarQueryParameter['birth_year'] = birthYear;
            }

            if (birthMonth !== undefined) {
                localVarQueryParameter['birth_month'] = birthMonth;
            }

            if (birthDay !== undefined) {
                localVarQueryParameter['birth_day'] = birthDay;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['last_name'] = lastName;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['first_name'] = firstName;
            }

            if (hiraLastName !== undefined) {
                localVarQueryParameter['hira_last_name'] = hiraLastName;
            }

            if (hiraFirstName !== undefined) {
                localVarQueryParameter['hira_first_name'] = hiraFirstName;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (odakyuCustomerNo !== undefined) {
                localVarQueryParameter['odakyu_customer_no'] = odakyuCustomerNo;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {ReqAdminUserUpdate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInfo: async (uid: string, body?: ReqAdminUserUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling updateInfo.');
            }
            const localVarPath = `/admin/users/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-system-access"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUserApi - functional programming interface
 * @export
 */
export const AdminUserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInfo(uid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAdminGetUserInfo>> {
            const localVarAxiosArgs = await AdminUserApiAxiosParamCreator(configuration).getInfo(uid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * [P0203]管理画面用ユーザ情報取得API 指定したONEユーザの登録情報を1件返却する 個人情報はマスキングする仕様とする
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaskedUserInfo(uid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAdminGetUserInfo>> {
            const localVarAxiosArgs = await AdminUserApiAxiosParamCreator(configuration).getMaskedUserInfo(uid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * [P0087]ユーザ検索API 検索条件に合致したONEユーザの一覧を返却する 個人情報はマスキングする仕様とする
         * @param {string} [uid] UID
         * @param {string} [mailAddress] メールアドレス
         * @param {string} [birthYear] 生年月日（年）
         * @param {string} [birthMonth] 生年月日（月）
         * @param {string} [birthDay] 生年月日（日）
         * @param {string} [lastName] 氏
         * @param {string} [firstName] 名
         * @param {string} [hiraLastName] 氏（ひらがな）
         * @param {string} [hiraFirstName] 名（ひらがな）
         * @param {string} [phone] 電話番号
         * @param {string} [odakyuCustomerNo] 小田急お客さま番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAdminUsers(uid?: string, mailAddress?: string, birthYear?: string, birthMonth?: string, birthDay?: string, lastName?: string, firstName?: string, hiraLastName?: string, hiraFirstName?: string, phone?: string, odakyuCustomerNo?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResAdminUser>>> {
            const localVarAxiosArgs = await AdminUserApiAxiosParamCreator(configuration).searchAdminUsers(uid, mailAddress, birthYear, birthMonth, birthDay, lastName, firstName, hiraLastName, hiraFirstName, phone, odakyuCustomerNo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {ReqAdminUserUpdate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInfo(uid: string, body?: ReqAdminUserUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AdminUserApiAxiosParamCreator(configuration).updateInfo(uid, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AdminUserApi - factory interface
 * @export
 */
export const AdminUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfo(uid: string, options?: any): AxiosPromise<ResAdminGetUserInfo> {
            return AdminUserApiFp(configuration).getInfo(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * [P0203]管理画面用ユーザ情報取得API 指定したONEユーザの登録情報を1件返却する 個人情報はマスキングする仕様とする
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaskedUserInfo(uid: string, options?: any): AxiosPromise<ResAdminGetUserInfo> {
            return AdminUserApiFp(configuration).getMaskedUserInfo(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * [P0087]ユーザ検索API 検索条件に合致したONEユーザの一覧を返却する 個人情報はマスキングする仕様とする
         * @param {string} [uid] UID
         * @param {string} [mailAddress] メールアドレス
         * @param {string} [birthYear] 生年月日（年）
         * @param {string} [birthMonth] 生年月日（月）
         * @param {string} [birthDay] 生年月日（日）
         * @param {string} [lastName] 氏
         * @param {string} [firstName] 名
         * @param {string} [hiraLastName] 氏（ひらがな）
         * @param {string} [hiraFirstName] 名（ひらがな）
         * @param {string} [phone] 電話番号
         * @param {string} [odakyuCustomerNo] 小田急お客さま番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAdminUsers(uid?: string, mailAddress?: string, birthYear?: string, birthMonth?: string, birthDay?: string, lastName?: string, firstName?: string, hiraLastName?: string, hiraFirstName?: string, phone?: string, odakyuCustomerNo?: string, options?: any): AxiosPromise<Array<ResAdminUser>> {
            return AdminUserApiFp(configuration).searchAdminUsers(uid, mailAddress, birthYear, birthMonth, birthDay, lastName, firstName, hiraLastName, hiraFirstName, phone, odakyuCustomerNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {ReqAdminUserUpdate} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInfo(uid: string, body?: ReqAdminUserUpdate, options?: any): AxiosPromise<void> {
            return AdminUserApiFp(configuration).updateInfo(uid, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminUserApi - object-oriented interface
 * @export
 * @class AdminUserApi
 * @extends {BaseAPI}
 */
export class AdminUserApi extends BaseAPI {
    /**
     * 
     * @param {string} uid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    public getInfo(uid: string, options?: any) {
        return AdminUserApiFp(this.configuration).getInfo(uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [P0203]管理画面用ユーザ情報取得API 指定したONEユーザの登録情報を1件返却する 個人情報はマスキングする仕様とする
     * @param {string} uid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    public getMaskedUserInfo(uid: string, options?: any) {
        return AdminUserApiFp(this.configuration).getMaskedUserInfo(uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [P0087]ユーザ検索API 検索条件に合致したONEユーザの一覧を返却する 個人情報はマスキングする仕様とする
     * @param {string} [uid] UID
     * @param {string} [mailAddress] メールアドレス
     * @param {string} [birthYear] 生年月日（年）
     * @param {string} [birthMonth] 生年月日（月）
     * @param {string} [birthDay] 生年月日（日）
     * @param {string} [lastName] 氏
     * @param {string} [firstName] 名
     * @param {string} [hiraLastName] 氏（ひらがな）
     * @param {string} [hiraFirstName] 名（ひらがな）
     * @param {string} [phone] 電話番号
     * @param {string} [odakyuCustomerNo] 小田急お客さま番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    public searchAdminUsers(uid?: string, mailAddress?: string, birthYear?: string, birthMonth?: string, birthDay?: string, lastName?: string, firstName?: string, hiraLastName?: string, hiraFirstName?: string, phone?: string, odakyuCustomerNo?: string, options?: any) {
        return AdminUserApiFp(this.configuration).searchAdminUsers(uid, mailAddress, birthYear, birthMonth, birthDay, lastName, firstName, hiraLastName, hiraFirstName, phone, odakyuCustomerNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} uid 
     * @param {ReqAdminUserUpdate} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    public updateInfo(uid: string, body?: ReqAdminUserUpdate, options?: any) {
        return AdminUserApiFp(this.configuration).updateInfo(uid, body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * AllianceServiceApi - axios parameter creator
 * @export
 */
export const AllianceServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReqAllianceService} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAllianceService: async (body: ReqAllianceService, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAllianceService.');
            }
            const localVarPath = `/alliance_services`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ReqAllianceService} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAllianceService: async (id: number, body: ReqAllianceService, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling editAllianceService.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editAllianceService.');
            }
            const localVarPath = `/alliance_services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllianceService: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAllianceService.');
            }
            const localVarPath = `/alliance_services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAllianceService: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeAllianceService.');
            }
            const localVarPath = `/alliance_services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAllianceServices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/alliance_services`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllianceServiceApi - functional programming interface
 * @export
 */
export const AllianceServiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReqAllianceService} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAllianceService(body: ReqAllianceService, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAllianceService>> {
            const localVarAxiosArgs = await AllianceServiceApiAxiosParamCreator(configuration).createAllianceService(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ReqAllianceService} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editAllianceService(id: number, body: ReqAllianceService, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAllianceService>> {
            const localVarAxiosArgs = await AllianceServiceApiAxiosParamCreator(configuration).editAllianceService(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllianceService(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAllianceService>> {
            const localVarAxiosArgs = await AllianceServiceApiAxiosParamCreator(configuration).getAllianceService(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAllianceService(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AllianceServiceApiAxiosParamCreator(configuration).removeAllianceService(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAllianceServices(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResAllianceServiceWithLinkage>>> {
            const localVarAxiosArgs = await AllianceServiceApiAxiosParamCreator(configuration).searchAllianceServices(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AllianceServiceApi - factory interface
 * @export
 */
export const AllianceServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {ReqAllianceService} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAllianceService(body: ReqAllianceService, options?: any): AxiosPromise<ResAllianceService> {
            return AllianceServiceApiFp(configuration).createAllianceService(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ReqAllianceService} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAllianceService(id: number, body: ReqAllianceService, options?: any): AxiosPromise<ResAllianceService> {
            return AllianceServiceApiFp(configuration).editAllianceService(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllianceService(id: number, options?: any): AxiosPromise<ResAllianceService> {
            return AllianceServiceApiFp(configuration).getAllianceService(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAllianceService(id: number, options?: any): AxiosPromise<void> {
            return AllianceServiceApiFp(configuration).removeAllianceService(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAllianceServices(options?: any): AxiosPromise<Array<ResAllianceServiceWithLinkage>> {
            return AllianceServiceApiFp(configuration).searchAllianceServices(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllianceServiceApi - object-oriented interface
 * @export
 * @class AllianceServiceApi
 * @extends {BaseAPI}
 */
export class AllianceServiceApi extends BaseAPI {
    /**
     * 
     * @param {ReqAllianceService} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllianceServiceApi
     */
    public createAllianceService(body: ReqAllianceService, options?: any) {
        return AllianceServiceApiFp(this.configuration).createAllianceService(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ReqAllianceService} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllianceServiceApi
     */
    public editAllianceService(id: number, body: ReqAllianceService, options?: any) {
        return AllianceServiceApiFp(this.configuration).editAllianceService(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllianceServiceApi
     */
    public getAllianceService(id: number, options?: any) {
        return AllianceServiceApiFp(this.configuration).getAllianceService(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllianceServiceApi
     */
    public removeAllianceService(id: number, options?: any) {
        return AllianceServiceApiFp(this.configuration).removeAllianceService(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllianceServiceApi
     */
    public searchAllianceServices(options?: any) {
        return AllianceServiceApiFp(this.configuration).searchAllianceServices(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * AllianceServiceUsageRecordApi - axios parameter creator
 * @export
 */
export const AllianceServiceUsageRecordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReqAllianceServiceUsageRecord} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAllianceServiceUsageRecord: async (body: ReqAllianceServiceUsageRecord, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAllianceServiceUsageRecord.');
            }
            const localVarPath = `/alliance_service_usage_records`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAllianceServiceUsageRecord: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling editAllianceServiceUsageRecord.');
            }
            const localVarPath = `/alliance_service_usage_records/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllianceServiceUsageRecord: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAllianceServiceUsageRecord.');
            }
            const localVarPath = `/alliance_service_usage_records/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAllianceServiceUsageRecord: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removeAllianceServiceUsageRecord.');
            }
            const localVarPath = `/alliance_service_usage_records/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [uid] 完全マッチ
         * @param {string} [beginUsageDt] ～以上
         * @param {string} [endUsageDt] ～未満
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAllianceServiceUsageRecords: async (uid?: string, beginUsageDt?: string, endUsageDt?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/alliance_service_usage_records`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (beginUsageDt !== undefined) {
                localVarQueryParameter['begin_usage_dt'] = beginUsageDt;
            }

            if (endUsageDt !== undefined) {
                localVarQueryParameter['end_usage_dt'] = endUsageDt;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllianceServiceUsageRecordApi - functional programming interface
 * @export
 */
export const AllianceServiceUsageRecordApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReqAllianceServiceUsageRecord} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAllianceServiceUsageRecord(body: ReqAllianceServiceUsageRecord, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAllianceServiceUsageRecord>> {
            const localVarAxiosArgs = await AllianceServiceUsageRecordApiAxiosParamCreator(configuration).createAllianceServiceUsageRecord(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editAllianceServiceUsageRecord(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAllianceServiceUsageRecord>> {
            const localVarAxiosArgs = await AllianceServiceUsageRecordApiAxiosParamCreator(configuration).editAllianceServiceUsageRecord(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllianceServiceUsageRecord(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAllianceServiceUsageRecord>> {
            const localVarAxiosArgs = await AllianceServiceUsageRecordApiAxiosParamCreator(configuration).getAllianceServiceUsageRecord(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeAllianceServiceUsageRecord(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AllianceServiceUsageRecordApiAxiosParamCreator(configuration).removeAllianceServiceUsageRecord(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [uid] 完全マッチ
         * @param {string} [beginUsageDt] ～以上
         * @param {string} [endUsageDt] ～未満
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAllianceServiceUsageRecords(uid?: string, beginUsageDt?: string, endUsageDt?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResAllianceServiceUsageRecord>>> {
            const localVarAxiosArgs = await AllianceServiceUsageRecordApiAxiosParamCreator(configuration).searchAllianceServiceUsageRecords(uid, beginUsageDt, endUsageDt, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AllianceServiceUsageRecordApi - factory interface
 * @export
 */
export const AllianceServiceUsageRecordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {ReqAllianceServiceUsageRecord} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAllianceServiceUsageRecord(body: ReqAllianceServiceUsageRecord, options?: any): AxiosPromise<ResAllianceServiceUsageRecord> {
            return AllianceServiceUsageRecordApiFp(configuration).createAllianceServiceUsageRecord(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAllianceServiceUsageRecord(id: string, options?: any): AxiosPromise<ResAllianceServiceUsageRecord> {
            return AllianceServiceUsageRecordApiFp(configuration).editAllianceServiceUsageRecord(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllianceServiceUsageRecord(id: string, options?: any): AxiosPromise<ResAllianceServiceUsageRecord> {
            return AllianceServiceUsageRecordApiFp(configuration).getAllianceServiceUsageRecord(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAllianceServiceUsageRecord(id: string, options?: any): AxiosPromise<void> {
            return AllianceServiceUsageRecordApiFp(configuration).removeAllianceServiceUsageRecord(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [uid] 完全マッチ
         * @param {string} [beginUsageDt] ～以上
         * @param {string} [endUsageDt] ～未満
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAllianceServiceUsageRecords(uid?: string, beginUsageDt?: string, endUsageDt?: string, options?: any): AxiosPromise<Array<ResAllianceServiceUsageRecord>> {
            return AllianceServiceUsageRecordApiFp(configuration).searchAllianceServiceUsageRecords(uid, beginUsageDt, endUsageDt, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllianceServiceUsageRecordApi - object-oriented interface
 * @export
 * @class AllianceServiceUsageRecordApi
 * @extends {BaseAPI}
 */
export class AllianceServiceUsageRecordApi extends BaseAPI {
    /**
     * 
     * @param {ReqAllianceServiceUsageRecord} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllianceServiceUsageRecordApi
     */
    public createAllianceServiceUsageRecord(body: ReqAllianceServiceUsageRecord, options?: any) {
        return AllianceServiceUsageRecordApiFp(this.configuration).createAllianceServiceUsageRecord(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllianceServiceUsageRecordApi
     */
    public editAllianceServiceUsageRecord(id: string, options?: any) {
        return AllianceServiceUsageRecordApiFp(this.configuration).editAllianceServiceUsageRecord(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllianceServiceUsageRecordApi
     */
    public getAllianceServiceUsageRecord(id: string, options?: any) {
        return AllianceServiceUsageRecordApiFp(this.configuration).getAllianceServiceUsageRecord(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllianceServiceUsageRecordApi
     */
    public removeAllianceServiceUsageRecord(id: string, options?: any) {
        return AllianceServiceUsageRecordApiFp(this.configuration).removeAllianceServiceUsageRecord(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [uid] 完全マッチ
     * @param {string} [beginUsageDt] ～以上
     * @param {string} [endUsageDt] ～未満
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllianceServiceUsageRecordApi
     */
    public searchAllianceServiceUsageRecords(uid?: string, beginUsageDt?: string, endUsageDt?: string, options?: any) {
        return AllianceServiceUsageRecordApiFp(this.configuration).searchAllianceServiceUsageRecords(uid, beginUsageDt, endUsageDt, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * [P0205]キャンペーン登録取消API リクエストユーザのキャンペーン登録を取り消す
         * @param {string} id キャンペーンID
         * @param {string} campaignTitle キャンペーンタイトル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCampaignCancel: async (id: string, campaignTitle: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCampaignCancel.');
            }
            // verify required parameter 'campaignTitle' is not null or undefined
            if (campaignTitle === null || campaignTitle === undefined) {
                throw new RequiredError('campaignTitle','Required parameter campaignTitle was null or undefined when calling deleteCampaignCancel.');
            }
            const localVarPath = `/campaign/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (campaignTitle !== undefined) {
                localVarQueryParameter['campaign_title'] = campaignTitle;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [P0206]キャンペーン登録確認API リクエストユーザのキャンペーン登録状況を確認する
         * @param {string} id キャンペーンID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignConfirm: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCampaignConfirm.');
            }
            const localVarPath = `/campaign/{id}/confirm`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [P0204]キャンペーン登録API リクエストユーザのキャンペーン登録を行う リクエストユーザのメインカードがキャンペーン登録可能券種でない場合、エラーを返却する
         * @param {string} id キャンペーンID
         * @param {ReqPostCampaignEntry} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCampaignEntry: async (id: string, body?: ReqPostCampaignEntry, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling postCampaignEntry.');
            }
            const localVarPath = `/campaign/{id}/entry`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    return {
        /**
         * [P0205]キャンペーン登録取消API リクエストユーザのキャンペーン登録を取り消す
         * @param {string} id キャンペーンID
         * @param {string} campaignTitle キャンペーンタイトル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCampaignCancel(id: string, campaignTitle: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).deleteCampaignCancel(id, campaignTitle, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * [P0206]キャンペーン登録確認API リクエストユーザのキャンペーン登録状況を確認する
         * @param {string} id キャンペーンID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignConfirm(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResCampaignConfirm>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).getCampaignConfirm(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * [P0204]キャンペーン登録API リクエストユーザのキャンペーン登録を行う リクエストユーザのメインカードがキャンペーン登録可能券種でない場合、エラーを返却する
         * @param {string} id キャンペーンID
         * @param {ReqPostCampaignEntry} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCampaignEntry(id: string, body?: ReqPostCampaignEntry, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).postCampaignEntry(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * [P0205]キャンペーン登録取消API リクエストユーザのキャンペーン登録を取り消す
         * @param {string} id キャンペーンID
         * @param {string} campaignTitle キャンペーンタイトル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCampaignCancel(id: string, campaignTitle: string, options?: any): AxiosPromise<void> {
            return CampaignApiFp(configuration).deleteCampaignCancel(id, campaignTitle, options).then((request) => request(axios, basePath));
        },
        /**
         * [P0206]キャンペーン登録確認API リクエストユーザのキャンペーン登録状況を確認する
         * @param {string} id キャンペーンID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignConfirm(id: string, options?: any): AxiosPromise<ResCampaignConfirm> {
            return CampaignApiFp(configuration).getCampaignConfirm(id, options).then((request) => request(axios, basePath));
        },
        /**
         * [P0204]キャンペーン登録API リクエストユーザのキャンペーン登録を行う リクエストユーザのメインカードがキャンペーン登録可能券種でない場合、エラーを返却する
         * @param {string} id キャンペーンID
         * @param {ReqPostCampaignEntry} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCampaignEntry(id: string, body?: ReqPostCampaignEntry, options?: any): AxiosPromise<void> {
            return CampaignApiFp(configuration).postCampaignEntry(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * [P0205]キャンペーン登録取消API リクエストユーザのキャンペーン登録を取り消す
     * @param {string} id キャンペーンID
     * @param {string} campaignTitle キャンペーンタイトル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public deleteCampaignCancel(id: string, campaignTitle: string, options?: any) {
        return CampaignApiFp(this.configuration).deleteCampaignCancel(id, campaignTitle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [P0206]キャンペーン登録確認API リクエストユーザのキャンペーン登録状況を確認する
     * @param {string} id キャンペーンID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getCampaignConfirm(id: string, options?: any) {
        return CampaignApiFp(this.configuration).getCampaignConfirm(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [P0204]キャンペーン登録API リクエストユーザのキャンペーン登録を行う リクエストユーザのメインカードがキャンペーン登録可能券種でない場合、エラーを返却する
     * @param {string} id キャンペーンID
     * @param {ReqPostCampaignEntry} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public postCampaignEntry(id: string, body?: ReqPostCampaignEntry, options?: any) {
        return CampaignApiFp(this.configuration).postCampaignEntry(id, body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CouponApi - axios parameter creator
 * @export
 */
export const CouponApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * [P0258]限定消込クーポン消込履歴取得 概要:消込済みの限定消込クーポンの履歴を取得する。
         * @param {number} limit 1ページに表示する履歴の件数
         * @param {number} offset 消込済クーポン履歴の取得開始位置（件数）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimitedExpendableCouponHistories: async (limit: number, offset: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            if (limit === null || limit === undefined) {
                throw new RequiredError('limit','Required parameter limit was null or undefined when calling getLimitedExpendableCouponHistories.');
            }
            // verify required parameter 'offset' is not null or undefined
            if (offset === null || offset === undefined) {
                throw new RequiredError('offset','Required parameter offset was null or undefined when calling getLimitedExpendableCouponHistories.');
            }
            const localVarPath = `/limited_expendable_coupons/histories`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [P0256]限定消込クーポン取得API 概要:ユーザのサブスク契約状況および限定消込クーポンの発行状況に応じて、使用可能な限定消込クーポンと配布予定の限定消込クーポンの情報を取得する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimitedExpendableCoupons: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/limited_expendable_coupons`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [P0252] 限定オンラインクーポン取得API 概要:ユーザのパッケージごとのサブスク登録状況に応じて、使用可能な限定オンラインクーポンの情報を取得する
         * @param {number} limit 1ページに表示する限定オンラインクーポンの件数
         * @param {number} offset 限定オンラインクーポンの取得開始位置（件数）
         * @param {string} [couponId] 限定オンラインクーポンID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimitedOnlineCoupons: async (limit: number, offset: number, couponId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            if (limit === null || limit === undefined) {
                throw new RequiredError('limit','Required parameter limit was null or undefined when calling getLimitedOnlineCoupons.');
            }
            // verify required parameter 'offset' is not null or undefined
            if (offset === null || offset === undefined) {
                throw new RequiredError('offset','Required parameter offset was null or undefined when calling getLimitedOnlineCoupons.');
            }
            const localVarPath = `/limited_online_coupons`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (couponId !== undefined) {
                localVarQueryParameter['coupon_id'] = couponId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [P0257]限定消込クーポン消込API 概要:指定した1つの限定消込クーポンに対して指定枚数分の消込処理を実行する。
         * @param {ReqPostLimitedExpendableCouponUse} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLimitedExpendableCouponUse: async (body: ReqPostLimitedExpendableCouponUse, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postLimitedExpendableCouponUse.');
            }
            const localVarPath = `/limited_expendable_coupons/use`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponApi - functional programming interface
 * @export
 */
export const CouponApiFp = function(configuration?: Configuration) {
    return {
        /**
         * [P0258]限定消込クーポン消込履歴取得 概要:消込済みの限定消込クーポンの履歴を取得する。
         * @param {number} limit 1ページに表示する履歴の件数
         * @param {number} offset 消込済クーポン履歴の取得開始位置（件数）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLimitedExpendableCouponHistories(limit: number, offset: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResGetLimitedExpendableCouponHistories>> {
            const localVarAxiosArgs = await CouponApiAxiosParamCreator(configuration).getLimitedExpendableCouponHistories(limit, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * [P0256]限定消込クーポン取得API 概要:ユーザのサブスク契約状況および限定消込クーポンの発行状況に応じて、使用可能な限定消込クーポンと配布予定の限定消込クーポンの情報を取得する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLimitedExpendableCoupons(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResGetLimitedExpendableCoupons>>> {
            const localVarAxiosArgs = await CouponApiAxiosParamCreator(configuration).getLimitedExpendableCoupons(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * [P0252] 限定オンラインクーポン取得API 概要:ユーザのパッケージごとのサブスク登録状況に応じて、使用可能な限定オンラインクーポンの情報を取得する
         * @param {number} limit 1ページに表示する限定オンラインクーポンの件数
         * @param {number} offset 限定オンラインクーポンの取得開始位置（件数）
         * @param {string} [couponId] 限定オンラインクーポンID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLimitedOnlineCoupons(limit: number, offset: number, couponId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResGetLimitedOnlineCoupons>> {
            const localVarAxiosArgs = await CouponApiAxiosParamCreator(configuration).getLimitedOnlineCoupons(limit, offset, couponId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * [P0257]限定消込クーポン消込API 概要:指定した1つの限定消込クーポンに対して指定枚数分の消込処理を実行する。
         * @param {ReqPostLimitedExpendableCouponUse} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLimitedExpendableCouponUse(body: ReqPostLimitedExpendableCouponUse, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CouponApiAxiosParamCreator(configuration).postLimitedExpendableCouponUse(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CouponApi - factory interface
 * @export
 */
export const CouponApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * [P0258]限定消込クーポン消込履歴取得 概要:消込済みの限定消込クーポンの履歴を取得する。
         * @param {number} limit 1ページに表示する履歴の件数
         * @param {number} offset 消込済クーポン履歴の取得開始位置（件数）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimitedExpendableCouponHistories(limit: number, offset: number, options?: any): AxiosPromise<ResGetLimitedExpendableCouponHistories> {
            return CouponApiFp(configuration).getLimitedExpendableCouponHistories(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * [P0256]限定消込クーポン取得API 概要:ユーザのサブスク契約状況および限定消込クーポンの発行状況に応じて、使用可能な限定消込クーポンと配布予定の限定消込クーポンの情報を取得する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimitedExpendableCoupons(options?: any): AxiosPromise<Array<ResGetLimitedExpendableCoupons>> {
            return CouponApiFp(configuration).getLimitedExpendableCoupons(options).then((request) => request(axios, basePath));
        },
        /**
         * [P0252] 限定オンラインクーポン取得API 概要:ユーザのパッケージごとのサブスク登録状況に応じて、使用可能な限定オンラインクーポンの情報を取得する
         * @param {number} limit 1ページに表示する限定オンラインクーポンの件数
         * @param {number} offset 限定オンラインクーポンの取得開始位置（件数）
         * @param {string} [couponId] 限定オンラインクーポンID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimitedOnlineCoupons(limit: number, offset: number, couponId?: string, options?: any): AxiosPromise<ResGetLimitedOnlineCoupons> {
            return CouponApiFp(configuration).getLimitedOnlineCoupons(limit, offset, couponId, options).then((request) => request(axios, basePath));
        },
        /**
         * [P0257]限定消込クーポン消込API 概要:指定した1つの限定消込クーポンに対して指定枚数分の消込処理を実行する。
         * @param {ReqPostLimitedExpendableCouponUse} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLimitedExpendableCouponUse(body: ReqPostLimitedExpendableCouponUse, options?: any): AxiosPromise<void> {
            return CouponApiFp(configuration).postLimitedExpendableCouponUse(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CouponApi - object-oriented interface
 * @export
 * @class CouponApi
 * @extends {BaseAPI}
 */
export class CouponApi extends BaseAPI {
    /**
     * [P0258]限定消込クーポン消込履歴取得 概要:消込済みの限定消込クーポンの履歴を取得する。
     * @param {number} limit 1ページに表示する履歴の件数
     * @param {number} offset 消込済クーポン履歴の取得開始位置（件数）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public getLimitedExpendableCouponHistories(limit: number, offset: number, options?: any) {
        return CouponApiFp(this.configuration).getLimitedExpendableCouponHistories(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [P0256]限定消込クーポン取得API 概要:ユーザのサブスク契約状況および限定消込クーポンの発行状況に応じて、使用可能な限定消込クーポンと配布予定の限定消込クーポンの情報を取得する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public getLimitedExpendableCoupons(options?: any) {
        return CouponApiFp(this.configuration).getLimitedExpendableCoupons(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [P0252] 限定オンラインクーポン取得API 概要:ユーザのパッケージごとのサブスク登録状況に応じて、使用可能な限定オンラインクーポンの情報を取得する
     * @param {number} limit 1ページに表示する限定オンラインクーポンの件数
     * @param {number} offset 限定オンラインクーポンの取得開始位置（件数）
     * @param {string} [couponId] 限定オンラインクーポンID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public getLimitedOnlineCoupons(limit: number, offset: number, couponId?: string, options?: any) {
        return CouponApiFp(this.configuration).getLimitedOnlineCoupons(limit, offset, couponId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [P0257]限定消込クーポン消込API 概要:指定した1つの限定消込クーポンに対して指定枚数分の消込処理を実行する。
     * @param {ReqPostLimitedExpendableCouponUse} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public postLimitedExpendableCouponUse(body: ReqPostLimitedExpendableCouponUse, options?: any) {
        return CouponApiFp(this.configuration).postLimitedExpendableCouponUse(body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * CurrentUserApi - axios parameter creator
 * @export
 */
export const CurrentUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReqCurrentUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCurrentUser: async (body?: ReqCurrentUser, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/current_user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user", "1st-party-point-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/current_user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user", "auth0-system-access", "1st-party-point-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * [P0270]メールアドレス変更受付API UID・メールアドレス・有効期限の3つを含むメールアドレス変更用URLを生成し、ユーザの新しいメールアドレス宛にメールを送信するAPI。
         * @param {InlineObject3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestForUpdateCurrentUserEmail: async (body: InlineObject3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postRequestForUpdateCurrentUserEmail.');
            }
            const localVarPath = `/current_user/request_update_email`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postResendEmail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/current_user/resend_email`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCurrentUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/current_user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchServiceUsages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/current_user/service_usages`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReqCurrentUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUser: async (body?: ReqCurrentUser, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/current_user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user", "1st-party-point-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject2} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUserEmail: async (body?: InlineObject2, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/current_user/email`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUserPassword: async (body?: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/current_user/update_password`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrentUserApi - functional programming interface
 * @export
 */
export const CurrentUserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReqCurrentUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCurrentUser(body?: ReqCurrentUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CurrentUserApiAxiosParamCreator(configuration).createCurrentUser(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResCurrentUser>> {
            const localVarAxiosArgs = await CurrentUserApiAxiosParamCreator(configuration).getCurrentUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * [P0270]メールアドレス変更受付API UID・メールアドレス・有効期限の3つを含むメールアドレス変更用URLを生成し、ユーザの新しいメールアドレス宛にメールを送信するAPI。
         * @param {InlineObject3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRequestForUpdateCurrentUserEmail(body: InlineObject3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CurrentUserApiAxiosParamCreator(configuration).postRequestForUpdateCurrentUserEmail(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postResendEmail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CurrentUserApiAxiosParamCreator(configuration).postResendEmail(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCurrentUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CurrentUserApiAxiosParamCreator(configuration).removeCurrentUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchServiceUsages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResServiceUsage>>> {
            const localVarAxiosArgs = await CurrentUserApiAxiosParamCreator(configuration).searchServiceUsages(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ReqCurrentUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCurrentUser(body?: ReqCurrentUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CurrentUserApiAxiosParamCreator(configuration).updateCurrentUser(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject2} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCurrentUserEmail(body?: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CurrentUserApiAxiosParamCreator(configuration).updateCurrentUserEmail(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCurrentUserPassword(body?: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CurrentUserApiAxiosParamCreator(configuration).updateCurrentUserPassword(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CurrentUserApi - factory interface
 * @export
 */
export const CurrentUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {ReqCurrentUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCurrentUser(body?: ReqCurrentUser, options?: any): AxiosPromise<void> {
            return CurrentUserApiFp(configuration).createCurrentUser(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<ResCurrentUser> {
            return CurrentUserApiFp(configuration).getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * [P0270]メールアドレス変更受付API UID・メールアドレス・有効期限の3つを含むメールアドレス変更用URLを生成し、ユーザの新しいメールアドレス宛にメールを送信するAPI。
         * @param {InlineObject3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRequestForUpdateCurrentUserEmail(body: InlineObject3, options?: any): AxiosPromise<void> {
            return CurrentUserApiFp(configuration).postRequestForUpdateCurrentUserEmail(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postResendEmail(options?: any): AxiosPromise<void> {
            return CurrentUserApiFp(configuration).postResendEmail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCurrentUser(options?: any): AxiosPromise<void> {
            return CurrentUserApiFp(configuration).removeCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchServiceUsages(options?: any): AxiosPromise<Array<ResServiceUsage>> {
            return CurrentUserApiFp(configuration).searchServiceUsages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReqCurrentUser} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUser(body?: ReqCurrentUser, options?: any): AxiosPromise<void> {
            return CurrentUserApiFp(configuration).updateCurrentUser(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject2} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUserEmail(body?: InlineObject2, options?: any): AxiosPromise<void> {
            return CurrentUserApiFp(configuration).updateCurrentUserEmail(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject1} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUserPassword(body?: InlineObject1, options?: any): AxiosPromise<void> {
            return CurrentUserApiFp(configuration).updateCurrentUserPassword(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrentUserApi - object-oriented interface
 * @export
 * @class CurrentUserApi
 * @extends {BaseAPI}
 */
export class CurrentUserApi extends BaseAPI {
    /**
     * 
     * @param {ReqCurrentUser} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public createCurrentUser(body?: ReqCurrentUser, options?: any) {
        return CurrentUserApiFp(this.configuration).createCurrentUser(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public getCurrentUser(options?: any) {
        return CurrentUserApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * [P0270]メールアドレス変更受付API UID・メールアドレス・有効期限の3つを含むメールアドレス変更用URLを生成し、ユーザの新しいメールアドレス宛にメールを送信するAPI。
     * @param {InlineObject3} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public postRequestForUpdateCurrentUserEmail(body: InlineObject3, options?: any) {
        return CurrentUserApiFp(this.configuration).postRequestForUpdateCurrentUserEmail(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public postResendEmail(options?: any) {
        return CurrentUserApiFp(this.configuration).postResendEmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public removeCurrentUser(options?: any) {
        return CurrentUserApiFp(this.configuration).removeCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public searchServiceUsages(options?: any) {
        return CurrentUserApiFp(this.configuration).searchServiceUsages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReqCurrentUser} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public updateCurrentUser(body?: ReqCurrentUser, options?: any) {
        return CurrentUserApiFp(this.configuration).updateCurrentUser(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject2} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public updateCurrentUserEmail(body?: InlineObject2, options?: any) {
        return CurrentUserApiFp(this.configuration).updateCurrentUserEmail(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject1} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public updateCurrentUserPassword(body?: InlineObject1, options?: any) {
        return CurrentUserApiFp(this.configuration).updateCurrentUserPassword(body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ElectricityApi - axios parameter creator
 * @export
 */
export const ElectricityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * でんき契約・請求取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElectricityContractInvoices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/electricity/contract_invoices`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * でんき請求明細取得
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElectricityInvoiceDetails: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getElectricityInvoiceDetails.');
            }
            const localVarPath = `/electricity/invoice_details/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ElectricityApi - functional programming interface
 * @export
 */
export const ElectricityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * でんき契約・請求取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElectricityContractInvoices(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResElectricityContractInvoice>>> {
            const localVarAxiosArgs = await ElectricityApiAxiosParamCreator(configuration).getElectricityContractInvoices(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * でんき請求明細取得
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElectricityInvoiceDetails(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResElectricityInvoiceDetail>>> {
            const localVarAxiosArgs = await ElectricityApiAxiosParamCreator(configuration).getElectricityInvoiceDetails(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ElectricityApi - factory interface
 * @export
 */
export const ElectricityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * でんき契約・請求取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElectricityContractInvoices(options?: any): AxiosPromise<Array<ResElectricityContractInvoice>> {
            return ElectricityApiFp(configuration).getElectricityContractInvoices(options).then((request) => request(axios, basePath));
        },
        /**
         * でんき請求明細取得
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElectricityInvoiceDetails(id: number, options?: any): AxiosPromise<Array<ResElectricityInvoiceDetail>> {
            return ElectricityApiFp(configuration).getElectricityInvoiceDetails(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ElectricityApi - object-oriented interface
 * @export
 * @class ElectricityApi
 * @extends {BaseAPI}
 */
export class ElectricityApi extends BaseAPI {
    /**
     * でんき契約・請求取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElectricityApi
     */
    public getElectricityContractInvoices(options?: any) {
        return ElectricityApiFp(this.configuration).getElectricityContractInvoices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * でんき請求明細取得
     * @param {number} id 請求ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ElectricityApi
     */
    public getElectricityInvoiceDetails(id: number, options?: any) {
        return ElectricityApiFp(this.configuration).getElectricityInvoiceDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * EnergyApi - axios parameter creator
 * @export
 */
export const EnergyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 電ガスのIDを結びつける際につかわれるcallback
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyCallback: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getEnergyCallback.');
            }
            const localVarPath = `/energycallback`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * トークンとUIDを結びつける
         * @param {ReqEnergyTokenStore} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEnergyTokenStore: async (body?: ReqEnergyTokenStore, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/energy/token_store`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnergyApi - functional programming interface
 * @export
 */
export const EnergyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 電ガスのIDを結びつける際につかわれるcallback
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnergyCallback(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await EnergyApiAxiosParamCreator(configuration).getEnergyCallback(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * トークンとUIDを結びつける
         * @param {ReqEnergyTokenStore} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEnergyTokenStore(body?: ReqEnergyTokenStore, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await EnergyApiAxiosParamCreator(configuration).postEnergyTokenStore(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EnergyApi - factory interface
 * @export
 */
export const EnergyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 電ガスのIDを結びつける際につかわれるcallback
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyCallback(token: string, options?: any): AxiosPromise<void> {
            return EnergyApiFp(configuration).getEnergyCallback(token, options).then((request) => request(axios, basePath));
        },
        /**
         * トークンとUIDを結びつける
         * @param {ReqEnergyTokenStore} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEnergyTokenStore(body?: ReqEnergyTokenStore, options?: any): AxiosPromise<void> {
            return EnergyApiFp(configuration).postEnergyTokenStore(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnergyApi - object-oriented interface
 * @export
 * @class EnergyApi
 * @extends {BaseAPI}
 */
export class EnergyApi extends BaseAPI {
    /**
     * 電ガスのIDを結びつける際につかわれるcallback
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public getEnergyCallback(token: string, options?: any) {
        return EnergyApiFp(this.configuration).getEnergyCallback(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * トークンとUIDを結びつける
     * @param {ReqEnergyTokenStore} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public postEnergyTokenStore(body?: ReqEnergyTokenStore, options?: any) {
        return EnergyApiFp(this.configuration).postEnergyTokenStore(body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 機能:[P0234]イベント在庫取得API 概要:イベントIDから、ユーザに依存しないイベント情報(残席数)を取得。 認証不要(Auth0トークン不要)とする。
         * @param {string} eventId イベントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent: async (eventId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling getEvent.');
            }
            const localVarPath = `/event/{event_id}`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能:[P0238]イベント申込履歴取得 概要:申込済みのイベント一覧を取得する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventEntry: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/event/entry`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能:[P0250]イベント消込API 概要:申し込み済みイベントに対して消し込み処理を実施する。
         * @param {Array<ReqPatchUseEventStatus>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUseEventStatus: async (body: Array<ReqPatchUseEventStatus>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling patchUseEventStatus.');
            }
            const localVarPath = `/event/use`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能:[P0236]イベント申込 概要:ソニーペイメントの与信売上計上を行い、DB にイベント申込データを登録する。
         * @param {ReqPostEventEntry} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEventEntry: async (body: ReqPostEventEntry, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postEventEntry.');
            }
            const localVarPath = `/event/entry`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 機能:[P0234]イベント在庫取得API 概要:イベントIDから、ユーザに依存しないイベント情報(残席数)を取得。 認証不要(Auth0トークン不要)とする。
         * @param {string} eventId イベントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvent(eventId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResGetEvent>>> {
            const localVarAxiosArgs = await EventApiAxiosParamCreator(configuration).getEvent(eventId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能:[P0238]イベント申込履歴取得 概要:申込済みのイベント一覧を取得する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventEntry(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResGetEventEntry>>> {
            const localVarAxiosArgs = await EventApiAxiosParamCreator(configuration).getEventEntry(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能:[P0250]イベント消込API 概要:申し込み済みイベントに対して消し込み処理を実施する。
         * @param {Array<ReqPatchUseEventStatus>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUseEventStatus(body: Array<ReqPatchUseEventStatus>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await EventApiAxiosParamCreator(configuration).patchUseEventStatus(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能:[P0236]イベント申込 概要:ソニーペイメントの与信売上計上を行い、DB にイベント申込データを登録する。
         * @param {ReqPostEventEntry} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEventEntry(body: ReqPostEventEntry, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await EventApiAxiosParamCreator(configuration).postEventEntry(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 機能:[P0234]イベント在庫取得API 概要:イベントIDから、ユーザに依存しないイベント情報(残席数)を取得。 認証不要(Auth0トークン不要)とする。
         * @param {string} eventId イベントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(eventId: string, options?: any): AxiosPromise<Array<ResGetEvent>> {
            return EventApiFp(configuration).getEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能:[P0238]イベント申込履歴取得 概要:申込済みのイベント一覧を取得する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventEntry(options?: any): AxiosPromise<Array<ResGetEventEntry>> {
            return EventApiFp(configuration).getEventEntry(options).then((request) => request(axios, basePath));
        },
        /**
         * 機能:[P0250]イベント消込API 概要:申し込み済みイベントに対して消し込み処理を実施する。
         * @param {Array<ReqPatchUseEventStatus>} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUseEventStatus(body: Array<ReqPatchUseEventStatus>, options?: any): AxiosPromise<void> {
            return EventApiFp(configuration).patchUseEventStatus(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能:[P0236]イベント申込 概要:ソニーペイメントの与信売上計上を行い、DB にイベント申込データを登録する。
         * @param {ReqPostEventEntry} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEventEntry(body: ReqPostEventEntry, options?: any): AxiosPromise<void> {
            return EventApiFp(configuration).postEventEntry(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * 機能:[P0234]イベント在庫取得API 概要:イベントIDから、ユーザに依存しないイベント情報(残席数)を取得。 認証不要(Auth0トークン不要)とする。
     * @param {string} eventId イベントID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEvent(eventId: string, options?: any) {
        return EventApiFp(this.configuration).getEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能:[P0238]イベント申込履歴取得 概要:申込済みのイベント一覧を取得する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEventEntry(options?: any) {
        return EventApiFp(this.configuration).getEventEntry(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能:[P0250]イベント消込API 概要:申し込み済みイベントに対して消し込み処理を実施する。
     * @param {Array<ReqPatchUseEventStatus>} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public patchUseEventStatus(body: Array<ReqPatchUseEventStatus>, options?: any) {
        return EventApiFp(this.configuration).patchUseEventStatus(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能:[P0236]イベント申込 概要:ソニーペイメントの与信売上計上を行い、DB にイベント申込データを登録する。
     * @param {ReqPostEventEntry} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public postEventEntry(body: ReqPostEventEntry, options?: any) {
        return EventApiFp(this.configuration).postEventEntry(body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * GasApi - axios parameter creator
 * @export
 */
export const GasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ガス契約・請求取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGasContractInvoices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/gas/contract_invoices`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ガス請求明細取得
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGasInvoiceDetails: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getGasInvoiceDetails.');
            }
            const localVarPath = `/gas/invoice_details/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GasApi - functional programming interface
 * @export
 */
export const GasApiFp = function(configuration?: Configuration) {
    return {
        /**
         * ガス契約・請求取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGasContractInvoices(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResGasContractInvoice>>> {
            const localVarAxiosArgs = await GasApiAxiosParamCreator(configuration).getGasContractInvoices(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ガス請求明細取得
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGasInvoiceDetails(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResGasInvoiceDetail>>> {
            const localVarAxiosArgs = await GasApiAxiosParamCreator(configuration).getGasInvoiceDetails(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GasApi - factory interface
 * @export
 */
export const GasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * ガス契約・請求取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGasContractInvoices(options?: any): AxiosPromise<Array<ResGasContractInvoice>> {
            return GasApiFp(configuration).getGasContractInvoices(options).then((request) => request(axios, basePath));
        },
        /**
         * ガス請求明細取得
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGasInvoiceDetails(id: number, options?: any): AxiosPromise<Array<ResGasInvoiceDetail>> {
            return GasApiFp(configuration).getGasInvoiceDetails(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GasApi - object-oriented interface
 * @export
 * @class GasApi
 * @extends {BaseAPI}
 */
export class GasApi extends BaseAPI {
    /**
     * ガス契約・請求取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GasApi
     */
    public getGasContractInvoices(options?: any) {
        return GasApiFp(this.configuration).getGasContractInvoices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ガス請求明細取得
     * @param {number} id 請求ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GasApi
     */
    public getGasInvoiceDetails(id: number, options?: any) {
        return GasApiFp(this.configuration).getGasInvoiceDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * InquireSendEmailApi - axios parameter creator
 * @export
 */
export const InquireSendEmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 問い合わせ用メール送信
         * @param {ReqInquireSendEmail} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquireSendEmail: async (body: ReqInquireSendEmail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling inquireSendEmail.');
            }
            const localVarPath = `/inquire_send_email`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InquireSendEmailApi - functional programming interface
 * @export
 */
export const InquireSendEmailApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 問い合わせ用メール送信
         * @param {ReqInquireSendEmail} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inquireSendEmail(body: ReqInquireSendEmail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await InquireSendEmailApiAxiosParamCreator(configuration).inquireSendEmail(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InquireSendEmailApi - factory interface
 * @export
 */
export const InquireSendEmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 問い合わせ用メール送信
         * @param {ReqInquireSendEmail} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquireSendEmail(body: ReqInquireSendEmail, options?: any): AxiosPromise<InlineResponse2003> {
            return InquireSendEmailApiFp(configuration).inquireSendEmail(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InquireSendEmailApi - object-oriented interface
 * @export
 * @class InquireSendEmailApi
 * @extends {BaseAPI}
 */
export class InquireSendEmailApi extends BaseAPI {
    /**
     * 問い合わせ用メール送信
     * @param {ReqInquireSendEmail} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquireSendEmailApi
     */
    public inquireSendEmail(body: ReqInquireSendEmail, options?: any) {
        return InquireSendEmailApiFp(this.configuration).inquireSendEmail(body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * PointDetailApi - axios parameter creator
 * @export
 */
export const PointDetailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReqPointDetail} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPointDetail: async (body: ReqPointDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createPointDetail.');
            }
            const localVarPath = `/point_details`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPointDetail: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling editPointDetail.');
            }
            const localVarPath = `/point_details/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointDetail: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPointDetail.');
            }
            const localVarPath = `/point_details/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePointDetail: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling removePointDetail.');
            }
            const localVarPath = `/point_details/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 完全マッチ
         * @param {string} [beginPointIssuingDt] ～以上
         * @param {string} [endPointIssuingDt] ～未満
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPointDetails: async (userId?: string, beginPointIssuingDt?: string, endPointIssuingDt?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/point_details`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (beginPointIssuingDt !== undefined) {
                localVarQueryParameter['begin_point_issuing_dt'] = beginPointIssuingDt;
            }

            if (endPointIssuingDt !== undefined) {
                localVarQueryParameter['end_point_issuing_dt'] = endPointIssuingDt;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointDetailApi - functional programming interface
 * @export
 */
export const PointDetailApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReqPointDetail} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPointDetail(body: ReqPointDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResPointDetail>> {
            const localVarAxiosArgs = await PointDetailApiAxiosParamCreator(configuration).createPointDetail(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editPointDetail(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResPointDetail>> {
            const localVarAxiosArgs = await PointDetailApiAxiosParamCreator(configuration).editPointDetail(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPointDetail(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResPointDetail>> {
            const localVarAxiosArgs = await PointDetailApiAxiosParamCreator(configuration).getPointDetail(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removePointDetail(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PointDetailApiAxiosParamCreator(configuration).removePointDetail(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [userId] 完全マッチ
         * @param {string} [beginPointIssuingDt] ～以上
         * @param {string} [endPointIssuingDt] ～未満
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPointDetails(userId?: string, beginPointIssuingDt?: string, endPointIssuingDt?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResPointDetail>>> {
            const localVarAxiosArgs = await PointDetailApiAxiosParamCreator(configuration).searchPointDetails(userId, beginPointIssuingDt, endPointIssuingDt, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PointDetailApi - factory interface
 * @export
 */
export const PointDetailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {ReqPointDetail} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPointDetail(body: ReqPointDetail, options?: any): AxiosPromise<ResPointDetail> {
            return PointDetailApiFp(configuration).createPointDetail(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPointDetail(id: string, options?: any): AxiosPromise<ResPointDetail> {
            return PointDetailApiFp(configuration).editPointDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPointDetail(id: string, options?: any): AxiosPromise<ResPointDetail> {
            return PointDetailApiFp(configuration).getPointDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removePointDetail(id: string, options?: any): AxiosPromise<void> {
            return PointDetailApiFp(configuration).removePointDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] 完全マッチ
         * @param {string} [beginPointIssuingDt] ～以上
         * @param {string} [endPointIssuingDt] ～未満
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPointDetails(userId?: string, beginPointIssuingDt?: string, endPointIssuingDt?: string, options?: any): AxiosPromise<Array<ResPointDetail>> {
            return PointDetailApiFp(configuration).searchPointDetails(userId, beginPointIssuingDt, endPointIssuingDt, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PointDetailApi - object-oriented interface
 * @export
 * @class PointDetailApi
 * @extends {BaseAPI}
 */
export class PointDetailApi extends BaseAPI {
    /**
     * 
     * @param {ReqPointDetail} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointDetailApi
     */
    public createPointDetail(body: ReqPointDetail, options?: any) {
        return PointDetailApiFp(this.configuration).createPointDetail(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointDetailApi
     */
    public editPointDetail(id: string, options?: any) {
        return PointDetailApiFp(this.configuration).editPointDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointDetailApi
     */
    public getPointDetail(id: string, options?: any) {
        return PointDetailApiFp(this.configuration).getPointDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointDetailApi
     */
    public removePointDetail(id: string, options?: any) {
        return PointDetailApiFp(this.configuration).removePointDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userId] 完全マッチ
     * @param {string} [beginPointIssuingDt] ～以上
     * @param {string} [endPointIssuingDt] ～未満
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointDetailApi
     */
    public searchPointDetails(userId?: string, beginPointIssuingDt?: string, endPointIssuingDt?: string, options?: any) {
        return PointDetailApiFp(this.configuration).searchPointDetails(userId, beginPointIssuingDt, endPointIssuingDt, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 機能:[P0224]サブスク登録情報取得 概要:サブスク登録中のユーザおよび過去に登録履歴があるユーザの、サブスク情報詳細と登録済みのクレジットカード情報（マスキングしたものをソニーペイメントから取得する）を返却する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscription/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能:[P0223]サブスク状態取得 概要:指定されたuidのサブスク状態を返却する。       アプリケーションがAuth0経由で認証をした時に、Auth0 Rulesが本APIを実行する。       Auth0 Rulesは認証したユーザのサブスク状態を本APIから取得し、IDトークンにその情報を付与する。       アプリケーションはそのIDトークン経由でサブスク状態を取得する。
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionStatus: async (uid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getSubscriptionStatus.');
            }
            const localVarPath = `/subscription/status/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-system-access"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能:[P0251]ユーザ用サブスク状態取得 概要:リクエストユーザのサブスク状態を返却する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionStatusForUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscription/status`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能:[P0221]サブスク登録 概要:ソニーペイメントから受領した決済トークンを用いて決済を行い、ユーザーをサブスク会員として登録する。
         * @param {ReqPostSubscription} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSubscription: async (body: ReqPostSubscription, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postSubscription.');
            }
            const localVarPath = `/subscription`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能:[P0228]サブスク登録カード更新 概要:サブスク決済用として登録したクレジットカード情報を更新する。
         * @param {InlineObject5} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionCard: async (body: InlineObject5, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSubscriptionCard.');
            }
            const localVarPath = `/subscription/card`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能:[P0226]サブスク解約予約 概要:次回のサブスク更新を実施しないステータスに更新する。本APIはパッケージ単位で実行される。即時のサブスク解約はできない。
         * @param {InlineObject4} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionUnsubscribe: async (body: InlineObject4, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSubscriptionUnsubscribe.');
            }
            const localVarPath = `/subscription/unsubscribe`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 機能:[P0224]サブスク登録情報取得 概要:サブスク登録中のユーザおよび過去に登録履歴があるユーザの、サブスク情報詳細と登録済みのクレジットカード情報（マスキングしたものをソニーペイメントから取得する）を返却する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResSubscriptionInfo>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).getSubscriptionInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能:[P0223]サブスク状態取得 概要:指定されたuidのサブスク状態を返却する。       アプリケーションがAuth0経由で認証をした時に、Auth0 Rulesが本APIを実行する。       Auth0 Rulesは認証したユーザのサブスク状態を本APIから取得し、IDトークンにその情報を付与する。       アプリケーションはそのIDトークン経由でサブスク状態を取得する。
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionStatus(uid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).getSubscriptionStatus(uid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能:[P0251]ユーザ用サブスク状態取得 概要:リクエストユーザのサブスク状態を返却する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionStatusForUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).getSubscriptionStatusForUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能:[P0221]サブスク登録 概要:ソニーペイメントから受領した決済トークンを用いて決済を行い、ユーザーをサブスク会員として登録する。
         * @param {ReqPostSubscription} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSubscription(body: ReqPostSubscription, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResPostSubscription>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).postSubscription(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能:[P0228]サブスク登録カード更新 概要:サブスク決済用として登録したクレジットカード情報を更新する。
         * @param {InlineObject5} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscriptionCard(body: InlineObject5, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).updateSubscriptionCard(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能:[P0226]サブスク解約予約 概要:次回のサブスク更新を実施しないステータスに更新する。本APIはパッケージ単位で実行される。即時のサブスク解約はできない。
         * @param {InlineObject4} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscriptionUnsubscribe(body: InlineObject4, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).updateSubscriptionUnsubscribe(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 機能:[P0224]サブスク登録情報取得 概要:サブスク登録中のユーザおよび過去に登録履歴があるユーザの、サブスク情報詳細と登録済みのクレジットカード情報（マスキングしたものをソニーペイメントから取得する）を返却する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionInfo(options?: any): AxiosPromise<ResSubscriptionInfo> {
            return SubscriptionApiFp(configuration).getSubscriptionInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 機能:[P0223]サブスク状態取得 概要:指定されたuidのサブスク状態を返却する。       アプリケーションがAuth0経由で認証をした時に、Auth0 Rulesが本APIを実行する。       Auth0 Rulesは認証したユーザのサブスク状態を本APIから取得し、IDトークンにその情報を付与する。       アプリケーションはそのIDトークン経由でサブスク状態を取得する。
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionStatus(uid: string, options?: any): AxiosPromise<Array<object>> {
            return SubscriptionApiFp(configuration).getSubscriptionStatus(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能:[P0251]ユーザ用サブスク状態取得 概要:リクエストユーザのサブスク状態を返却する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionStatusForUser(options?: any): AxiosPromise<Array<object>> {
            return SubscriptionApiFp(configuration).getSubscriptionStatusForUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 機能:[P0221]サブスク登録 概要:ソニーペイメントから受領した決済トークンを用いて決済を行い、ユーザーをサブスク会員として登録する。
         * @param {ReqPostSubscription} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSubscription(body: ReqPostSubscription, options?: any): AxiosPromise<ResPostSubscription> {
            return SubscriptionApiFp(configuration).postSubscription(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能:[P0228]サブスク登録カード更新 概要:サブスク決済用として登録したクレジットカード情報を更新する。
         * @param {InlineObject5} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionCard(body: InlineObject5, options?: any): AxiosPromise<void> {
            return SubscriptionApiFp(configuration).updateSubscriptionCard(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能:[P0226]サブスク解約予約 概要:次回のサブスク更新を実施しないステータスに更新する。本APIはパッケージ単位で実行される。即時のサブスク解約はできない。
         * @param {InlineObject4} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionUnsubscribe(body: InlineObject4, options?: any): AxiosPromise<void> {
            return SubscriptionApiFp(configuration).updateSubscriptionUnsubscribe(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * 機能:[P0224]サブスク登録情報取得 概要:サブスク登録中のユーザおよび過去に登録履歴があるユーザの、サブスク情報詳細と登録済みのクレジットカード情報（マスキングしたものをソニーペイメントから取得する）を返却する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getSubscriptionInfo(options?: any) {
        return SubscriptionApiFp(this.configuration).getSubscriptionInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能:[P0223]サブスク状態取得 概要:指定されたuidのサブスク状態を返却する。       アプリケーションがAuth0経由で認証をした時に、Auth0 Rulesが本APIを実行する。       Auth0 Rulesは認証したユーザのサブスク状態を本APIから取得し、IDトークンにその情報を付与する。       アプリケーションはそのIDトークン経由でサブスク状態を取得する。
     * @param {string} uid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getSubscriptionStatus(uid: string, options?: any) {
        return SubscriptionApiFp(this.configuration).getSubscriptionStatus(uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能:[P0251]ユーザ用サブスク状態取得 概要:リクエストユーザのサブスク状態を返却する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getSubscriptionStatusForUser(options?: any) {
        return SubscriptionApiFp(this.configuration).getSubscriptionStatusForUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能:[P0221]サブスク登録 概要:ソニーペイメントから受領した決済トークンを用いて決済を行い、ユーザーをサブスク会員として登録する。
     * @param {ReqPostSubscription} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public postSubscription(body: ReqPostSubscription, options?: any) {
        return SubscriptionApiFp(this.configuration).postSubscription(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能:[P0228]サブスク登録カード更新 概要:サブスク決済用として登録したクレジットカード情報を更新する。
     * @param {InlineObject5} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public updateSubscriptionCard(body: InlineObject5, options?: any) {
        return SubscriptionApiFp(this.configuration).updateSubscriptionCard(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能:[P0226]サブスク解約予約 概要:次回のサブスク更新を実施しないステータスに更新する。本APIはパッケージ単位で実行される。即時のサブスク解約はできない。
     * @param {InlineObject4} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public updateSubscriptionUnsubscribe(body: InlineObject4, options?: any) {
        return SubscriptionApiFp(this.configuration).updateSubscriptionUnsubscribe(body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminHealthGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/health`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-support-center", "ens-support-center"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReqAuth0Admin} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth0Admin: async (body: ReqAuth0Admin, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling auth0Admin.');
            }
            const localVarPath = `/auth0_admin`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebugInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/debug_info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * テスト用に設定された時刻を取得するAPI（環境変数でAPIの利用可否を決定する）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/time_config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * テスト用に時刻の設定を行うためのAPI（環境変数でAPIの利用可否を決定する）
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTimeConfig: async (body: InlineObject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postTimeConfig.');
            }
            const localVarPath = `/time_config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        record: async (body?: object, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/record`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ruby `SecureRandom.hex(24)`  を利用したAPIキー認証によるログ記録API - 利用一覧（2020.10更新）   - Auth0Rules
         * @summary 外部システム：エラー受信API
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemRecord: async (body?: object, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/record`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-API-KEY")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-API-KEY"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminHealthGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).adminHealthGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ReqAuth0Admin} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auth0Admin(body: ReqAuth0Admin, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAuth0Admin>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).auth0Admin(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDebugInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResDebugInfo>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getDebugInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * テスト用に設定された時刻を取得するAPI（環境変数でAPIの利用可否を決定する）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getTimeConfig(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).healthGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * テスト用に時刻の設定を行うためのAPI（環境変数でAPIの利用可否を決定する）
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTimeConfig(body: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).postTimeConfig(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async record(body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).record(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Ruby `SecureRandom.hex(24)`  を利用したAPIキー認証によるログ記録API - 利用一覧（2020.10更新）   - Auth0Rules
         * @summary 外部システム：エラー受信API
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemRecord(body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).systemRecord(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminHealthGet(options?: any): AxiosPromise<InlineResponse200> {
            return SystemApiFp(configuration).adminHealthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReqAuth0Admin} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth0Admin(body: ReqAuth0Admin, options?: any): AxiosPromise<ResAuth0Admin> {
            return SystemApiFp(configuration).auth0Admin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebugInfo(options?: any): AxiosPromise<ResDebugInfo> {
            return SystemApiFp(configuration).getDebugInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * テスト用に設定された時刻を取得するAPI（環境変数でAPIの利用可否を決定する）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeConfig(options?: any): AxiosPromise<InlineResponse2001> {
            return SystemApiFp(configuration).getTimeConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet(options?: any): AxiosPromise<InlineResponse200> {
            return SystemApiFp(configuration).healthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * テスト用に時刻の設定を行うためのAPI（環境変数でAPIの利用可否を決定する）
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTimeConfig(body: InlineObject, options?: any): AxiosPromise<InlineResponse2002> {
            return SystemApiFp(configuration).postTimeConfig(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        record(body?: object, options?: any): AxiosPromise<void> {
            return SystemApiFp(configuration).record(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Ruby `SecureRandom.hex(24)`  を利用したAPIキー認証によるログ記録API - 利用一覧（2020.10更新）   - Auth0Rules
         * @summary 外部システム：エラー受信API
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemRecord(body?: object, options?: any): AxiosPromise<void> {
            return SystemApiFp(configuration).systemRecord(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public adminHealthGet(options?: any) {
        return SystemApiFp(this.configuration).adminHealthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReqAuth0Admin} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public auth0Admin(body: ReqAuth0Admin, options?: any) {
        return SystemApiFp(this.configuration).auth0Admin(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getDebugInfo(options?: any) {
        return SystemApiFp(this.configuration).getDebugInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * テスト用に設定された時刻を取得するAPI（環境変数でAPIの利用可否を決定する）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getTimeConfig(options?: any) {
        return SystemApiFp(this.configuration).getTimeConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public healthGet(options?: any) {
        return SystemApiFp(this.configuration).healthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * テスト用に時刻の設定を行うためのAPI（環境変数でAPIの利用可否を決定する）
     * @param {InlineObject} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public postTimeConfig(body: InlineObject, options?: any) {
        return SystemApiFp(this.configuration).postTimeConfig(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public record(body?: object, options?: any) {
        return SystemApiFp(this.configuration).record(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ruby `SecureRandom.hex(24)`  を利用したAPIキー認証によるログ記録API - 利用一覧（2020.10更新）   - Auth0Rules
     * @summary 外部システム：エラー受信API
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public systemRecord(body?: object, options?: any) {
        return SystemApiFp(this.configuration).systemRecord(body, options).then((request) => request(this.axios, this.basePath));
    }

}


