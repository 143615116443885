


















































































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class StoreModal extends Vue {
  totalSlides = 0; // スロットの合計数
  currentSlide = 1; // 現在のスライドインデックス

  @Prop()
  readonly isShowLimit?: boolean;

  @Prop()
  readonly isShowLimitWeek?: boolean;

  @Prop({ required: true })
  readonly value!: boolean;

  get wrapperDialog() {
    return this.value;
  }

  set wrapperDialog(flag: boolean) {
    this.$emit('input', flag);
  }

  //スライド数を算出
  calculateTotalSlides(): number {
    let count = 0;
    for (let i = 1; i < 6; i++) {
      const slotName = `slide${String(i).padStart(2, '0')}`;
      if (this.$slots[slotName]) {
        count++;
      }
    }
    return count;
  }

  //任意のスライドへ移動
  goToSlide(index: number) {
    this.currentSlide = index;
  }

  mounted() {
    this.totalSlides = this.calculateTotalSlides();
  }
}
