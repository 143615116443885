

































import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class ConfirmAddress extends Vue {
  @Prop({ required: true })
  readonly form!: any;
}
