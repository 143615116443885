


























import { Component, PropSync, Vue, Ref } from 'vue-property-decorator';
import utils from '@/common/utils';

import { UserEditForm } from '@/models/forms/UserForm';
import ZipCodeForm from '@/components/profile/ZipCodeForm.vue';
import PrefForm from '@/components/profile/PrefForm.vue';
import CityForm from '@/components/profile/CityForm.vue';
import TownForm from '@/components/profile/TownForm.vue';
import BuildingForm from '@/components/profile/BuildingForm.vue';

@Component({
  components: {
    ZipCodeForm,
    PrefForm,
    CityForm,
    TownForm,
    BuildingForm
  }
})
export default class AddressForm extends Vue {
  @Ref() readonly ZipCodeForm!: ZipCodeForm;
  @Ref() readonly PrefForm!: PrefForm;
  @Ref() readonly CityForm!: CityForm;
  @Ref() readonly TownForm!: TownForm;
  @Ref() readonly BuildingForm!: BuildingForm;

  @PropSync('form', { required: true })
  syncedForm!: UserEditForm;

  zipErrMsg = '';

  async fetchAddress() {
    try {
      const res = await utils.fetchPostalCodeApi(
        this.syncedForm.zipcode1,
        this.syncedForm.zipcode2
      );
      this.zipErrMsg = '';
      this.syncedForm.pref = res.prefcode;
      this.syncedForm.city = res.ja.address1 + res.ja.address2;
      this.syncedForm.town = res.ja.address3;
      this.syncedForm.building = res.ja.address4;
    } catch (e) {
      this.zipErrMsg = this.$msg.get('2000005');
    }
  }

  async validateForm() {
    const result = Promise.all([
      this.ZipCodeForm.$validator.validateAll(),
      this.PrefForm.$validator.validateAll(),
      this.PrefForm.validatePref(),
      this.CityForm.$validator.validateAll(),
      this.TownForm.$validator.validateAll(),
      this.BuildingForm.$validator.validateAll()
    ]);
    return (await result).every(val => val);
  }
}
