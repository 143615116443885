





























































































































































import { Component, Vue } from 'vue-property-decorator';
import Message from '@/common/message';
import OnceButton from '@/components/OnceButton.vue';
import { OpCardRepository } from '@/repositories/OpCardRepository';
import { AxiosError } from 'axios';
import { UserRepository } from '@/repositories/UserRepository';
import { OpCardList } from '@/models/opcards/OpCard';
import { UnregistrationOpCard } from '@/models/opcards/UnregistrationOpCard';
import UnsubscribeDialog from '@/components/UnsubscribeDialog.vue';

@Component({
  components: {
    'once-button': OnceButton,
    'unsubscribe-dialog': UnsubscribeDialog
  }
})
export default class Unsubscribe extends Vue {
  confirmDisabled = false;
  confirmChecked = false;
  buttonLoading = false;
  errMsg = '';
  usersOpCards: Array<UnregistrationOpCard> = [];
  unregistrationOpCards: Array<UnregistrationOpCard> = [];
  isUnsubscribeDialogOpen = false;

  unsubscribe() {
    this.unregistrationOpCards = this.usersOpCards.filter(
      u => u.isUnregistration
    );
    if (this.unregistrationOpCards.length === 0) {
      this.submitUnsubscribe();
    } else {
      this.isUnsubscribeDialogOpen = true;
    }
  }

  submitUnsubscribe() {
    this.buttonLoading = true;
    this.confirmDisabled = true;
    const paramCards = this.unregistrationOpCards.map(o => o.odakyuCustomerNo);
    this.userRepo
      .removeUser(paramCards)
      .then(() => {
        const returnTo = `${window.location.origin}/unsubscribe-ok`;
        this.$auth.logout({
          returnTo: returnTo,
          opAppLogout: true,
          opAppLogoutMessage: Message.get('2000511')
        });
      })
      .catch((err: AxiosError) => {
        this.setErrMsg(err);
      })
      .finally(() => {
        this.buttonLoading = false;
        this.confirmDisabled = false;
        this.isUnsubscribeDialogOpen = false;
      });
  }

  created() {
    this.initialize();
  }

  initialize() {
    this.usersOpCards = this.opCards.list.map(
      // P専デジタルカード（エアカード）のみ退会時に必ずOPシステム退会するため、デフォルトで退会フラグをTrueにする
      o => new UnregistrationOpCard(o.isPointOnlyDigitalCard, o)
    );
  }

  get opCards() {
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    );
  }

  setErrMsg(err: AxiosError) {
    if (err.response?.status) {
      switch (err.response.status) {
        case 400:
          this.errMsg = this.$msg.get('2000012');
          break;
        case 408:
          this.errMsg = this.$msg.get('2000013');
          break;
        case 503:
          this.errMsg = this.$msg.get('2000010');
          break;
        default:
          this.errMsg = this.$msg.get('2000104');
      }
    }
  }

  back(): void {
    this.$router.push('/account-info');
  }

  get userRepo() {
    return new UserRepository();
  }

  get opCardRepo() {
    return new OpCardRepository();
  }
}
