






















import { Component, Vue } from 'vue-property-decorator';
import { UserSignupForm, OpappAirCard } from '@/models/forms/UserForm';
import EcopAuth from '@/pages/UserForm/EcopAuth.vue';
import SignupEdit from '@/pages/UserForm/SignupEdit.vue';
import SignupConfirm from '@/pages/UserForm/SignupConfirm.vue';
import { UserRepository } from '@/repositories/UserRepository';
import { AxiosError } from 'axios';
import { OpCardRepository } from '@/repositories/OpCardRepository';
import { OpUtils } from '@/common/OpUtils';
import { OpCardList } from '@/models/opcards/OpCard';

const infoFromOpappClaimKey = 'https://one-odakyu.com/infoFromOpapp';

const isOpwebUserKey = 'https://one-odakyu.com/isOpwebUser';

const isDummyUserKey = 'https://one-odakyu.com/isDummyUser';

Component.registerHooks(['beforeRouteEnter']);

const KURASAPO_FLAG_KEY = 'from_kurasapo';

const EVENTOS_FLAG_KEY = 'from_eventos';

@Component({
  components: {
    EcopAuth,
    SignupEdit,
    SignupConfirm
  }
})
export default class P0102 extends Vue {
  form = new UserSignupForm();
  digitalOpFlg = false;
  btnLoading = false;
  isPassedEcopAuthPage = false;
  isEcopAuthenticated = false;
  isUpdateUserFailed = false;
  transitionName = '';
  resErrMsg = '';
  opappOs = '00';

  // OPアプリからのOS種別パラメータ候補
  readonly OS_TYPE_ARRAY = ['01', '02'];

  readonly UNSYNCED_POINT_ONLY_REAL_CARD = 'unsynced-point-only-real';

  readonly CREDIT_CARD = 'credit';

  beforeRouteEnter(to: any, from: any, next: any) {
    // 直接 /sign-up#confirm にアクセスした場合は、トップページにリダイレクトさせる
    if (to.path === '/sign-up' && to.hash === '#confirm') {
      next('/');
    }
    next();
  }

  get subPage() {
    switch (this.$route.hash) {
      case '':
        if (this.isOpwebUser && !this.isPassedEcopAuthPage) {
          return EcopAuth;
        }
        return SignupEdit;
      case '#confirm':
        return SignupConfirm;
      default:
        return SignupEdit;
    }
  }

  back() {
    this.transitionName = 'slide-back';
    this.$router.push({ hash: '' });
  }

  next() {
    this.transitionName = 'slide-next';
    switch (this.subPage) {
      case EcopAuth:
        this.isPassedEcopAuthPage = true;
        break;
      case SignupEdit:
        this.$router.push({ hash: '#confirm' });
        break;
      case SignupConfirm:
        this.btnLoading = true;
        this.createUserAndUpdateIdToken()
          .then(() => {
            if (this.isDummyUser) {
              // ダミーユーザ（メールアドレス非保持会員）の場合、本登録完了後に即時退会し、トップページへ遷移する
              this.unsubscribe();
            } else if (localStorage.getItem(KURASAPO_FLAG_KEY)) {
              // 登録時にLocalStorageの値を削除し、くらさぽのページへリダイレクトする
              localStorage.removeItem(KURASAPO_FLAG_KEY);
              const kurasapoRedirectURL = `${process.env.VUE_APP_KURASAPO_URL}/one/login/`;
              window.location.href = kurasapoRedirectURL;
            } else if (localStorage.getItem(EVENTOS_FLAG_KEY)) {
              // 登録時にLocalStorageの値を削除し、Eventosのページへリダイレクトする
              localStorage.removeItem(EVENTOS_FLAG_KEY);
              const eventosRedirectURL = `${process.env.VUE_APP_EVENTOS_URL}`;
              window.location.href = eventosRedirectURL;
            } else {
              this.$router.replace({
                path: '/sign-up-complete',
                query: {
                  opappOs: this.opappOs,
                  cardType:
                    this.cardTypeToNoticeWhenComplete === this.CREDIT_CARD ||
                    (this.cardTypeToNoticeWhenComplete ===
                      this.UNSYNCED_POINT_ONLY_REAL_CARD &&
                      this.isUpdateUserFailed === true)
                      ? this.cardTypeToNoticeWhenComplete
                      : undefined
                }
              });
            }
          })
          .catch((err: AxiosError) => {
            this.handleSubmitErr(err);
          })
          .finally(() => {
            this.btnLoading = false;
          });
        break;
      default:
        this.$router.push('/sign-up');
        break;
    }
  }

  async createUserAndUpdateIdToken() {
    if (this.isFromOpapp) {
      const opappAirCard = new OpappAirCard(
        this.$auth.user[infoFromOpappClaimKey].OpNo,
        this.$auth.user[infoFromOpappClaimKey].SecurityCd
      );
      this.opappOs = this.$auth.user[infoFromOpappClaimKey].OsType;
      return this.createUserAndUpdateIdTokenForOpapp(opappAirCard);
    }
    return this.createUserAndUpdateIdTokenForOne();
  }

  async createUserAndUpdateIdTokenForOne() {
    return this.userRepository
      .createUser(this.form, this.digitalOpFlg)
      .then(async ({ data }) => {
        await this.$auth.getTokenSilently({ ignoreCache: true });
        if (!data.is_attr_registered_to_opsys) {
          await this.userRepository
            .updateUser(this.form)
            .then(() => {
              this.isUpdateUserFailed = false;
            })
            .catch(() => {
              this.isUpdateUserFailed = true;
            });
        }
        const res = await this.opCardRepository.getOpBalance();
        this.$store.commit('setOpBalance', res);
        this.$store.commit('setOpFetched', true);
        this.$store.commit('setLastName', this.form.lastName);
      });
  }

  async createUserAndUpdateIdTokenForOpapp(opappAirCard: OpappAirCard) {
    return this.userRepository
      .createUserFromOpapp(this.form, this.digitalOpFlg, opappAirCard)
      .then(async ({ data }) => {
        await this.$auth.getTokenSilently({ ignoreCache: true });
        if (!data.is_attr_registered_to_opsys) {
          await this.userRepository
            .updateUser(this.form)
            .then(() => {
              this.isUpdateUserFailed = false;
            })
            .catch(() => {
              this.isUpdateUserFailed = true;
            });
        }
        const res = await this.opCardRepository.getOpBalance();
        this.$store.commit('setOpBalance', res);
        this.$store.commit('setOpFetched', true);
        this.$store.commit('setLastName', this.form.lastName);
      });
  }

  async unsubscribe() {
    // ダミーユーザが紐付けるP専板カードはOPシステムから退会させてはならない。
    // エアカードを新規発番して紐づける場合を除き、OPシステム退会対象は空配列とする。
    // エアカードを新規発番して紐づける場合は、当該ユーザに紐付いているカード（新規登録直後のため、エアカードのみ）を全てOPシステム退会対象とする。
    const opNoList = this.digitalOpFlg
      ? this.opCards.list.map(o => o.odakyuCustomerNo)
      : [];
    this.userRepository
      .removeUser(opNoList)
      .catch(() => {})
      // 退会処理の成功・失敗に関わらずエラーページに遷移する仕様とする
      .finally(() => {
        // エアカードを新規発番して紐づける場合は、異なるエラーメッセージを表示する。
        const errorMsg = this.digitalOpFlg
          ? '会員登録に失敗しました。別のOPポイント専用カードで再度会員登録を行ってください。お客さまがお持ちのカードにポイントが残っている場合は、会員登録が完了したカードにポイントを移行してください。'
          : 'OPポイント専用カードの会員登録が完了しましたので、お手元のカードで貯めたポイントをご利用いただけます。';
        const errorPageURI = `${window.location.origin}/error?error_description=`;
        // Auth0にリダイレクトURIを設定する際に日本語は弾かれるためエンコードを行う
        const returnTo = errorPageURI + encodeURI(errorMsg);
        this.$auth.logout({
          returnTo: returnTo,
          opAppLogout: true
        });
      });
  }

  handleSubmitErr(err: AxiosError) {
    if (err.response) {
      switch (err.response.status) {
        case 400:
        case 409:
          this.set400ErrMsg(err.response.data.code);
          this.$router.push({ hash: '' });
          break;
        case 500:
          this.set500ErrMsg(err.response.data.code);
          this.$router.push({ hash: '' });
          break;
        case 503:
          {
            const maintenanceURL = `${window.location.origin}/maintenance.html`;
            window.location.href = maintenanceURL;
          }
          break;
        default:
          this.resErrMsg = this.$msg.get('2000011');
          this.$router.push({ hash: '' });
      }
    }
  }

  set400ErrMsg(code: number) {
    switch (code) {
      case 40000:
        this.resErrMsg = this.$msg.get('2000012');
        break;
      case 40001:
        this.resErrMsg = this.$msg.get('2000006');
        break;
      case 40004:
        this.resErrMsg = this.$msg.get('2000201');
        break;
      case 40006:
        this.resErrMsg = this.$msg.get('2000009');
        break;
      case 40024:
        this.resErrMsg = this.$msg.get('2000200');
        break;
      case 40900:
        this.resErrMsg = this.$msg.get('2000007');
        break;
      default:
        this.resErrMsg = this.$msg.get('2000011');
        break;
    }
  }

  set500ErrMsg(code: number) {
    switch (code) {
      case 50003:
        this.resErrMsg = this.$msg.get('2000202');
        break;
      default:
        this.resErrMsg = this.$msg.get('2000011');
        break;
    }
  }

  get userRepository() {
    return new UserRepository();
  }

  get opCardRepository() {
    return new OpCardRepository();
  }

  get opCardRepo() {
    return new OpCardRepository();
  }

  get isFromOpapp() {
    if (this.$auth.user[infoFromOpappClaimKey]) {
      return (
        this.$auth.user[infoFromOpappClaimKey].AppIdentifier === 'OPAPP' &&
        this.OS_TYPE_ARRAY.includes(
          this.$auth.user[infoFromOpappClaimKey].OsType
        )
      );
    }
    return false;
  }

  get isOpappAirCardExists() {
    if (this.$auth.user[infoFromOpappClaimKey]) {
      if (
        this.$auth.user[infoFromOpappClaimKey].OpNo &&
        this.$auth.user[infoFromOpappClaimKey].SecurityCd
      ) {
        return true;
      }
    }
    return false;
  }

  get isOpwebUser() {
    return this.$auth.user[isOpwebUserKey] === true;
  }

  get isDummyUser() {
    return this.$auth.user[isDummyUserKey] === true;
  }

  get opCards() {
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    );
  }

  get cardTypeToNoticeWhenComplete() {
    if (this.digitalOpFlg === true) {
      return undefined;
    }

    const opCardNo = [
      this.form.opCardNum1,
      this.form.opCardNum2,
      this.form.opCardNum3
    ].join('');

    return OpUtils.isPointOnlyRealCard(opCardNo)
      ? this.UNSYNCED_POINT_ONLY_REAL_CARD
      : this.CREDIT_CARD;
  }
}
