

























































































































import CouponListItemDialogStep1 from '@/components/PremiumCoupon/ExpendableCoupon/CouponListItemDialogStep1.vue';
import CouponListItemDialogStep2 from '@/components/PremiumCoupon/ExpendableCoupon/CouponListItemDialogStep2.vue';
import CouponListItemDialogStep3 from '@/components/PremiumCoupon/ExpendableCoupon/CouponListItemDialogStep3.vue';
import { E_CODE_INVALID_EXPENDITURE_CODE } from '@/components/PremiumCoupon/ErrorMessage.vue';
import { IPremiumExpendableCouponRepository } from '@/repositories/interface/IPremiumExpendableCouponRepository';
import { PremiumExpendableCoupon } from '@/models/PremiumExpendableCoupon';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';
import { SubscriptionInfo } from '@/models/Subscription';

type DialogNumber = 1 | 2 | 3;

@Component({
  components: {
    CouponListItemDialogStep1,
    CouponListItemDialogStep2,
    CouponListItemDialogStep3
  }
})
export default class Coupon extends Vue {
  @Prop() readonly dialog?: boolean;
  @Prop() readonly coupon!: PremiumExpendableCoupon;
  @Prop() readonly repository!: IPremiumExpendableCouponRepository;
  @Prop() readonly state!: 'AVAILABLE' | 'UNAVAILABLE';
  @Prop({ required: true }) readonly packageList!: Array<
    SubscriptionPackageItem
  >;
  @Prop({ required: true }) subscriptionInfo!: SubscriptionInfo;

  remainingQuantityBefore = this.coupon.remainingQuantity;
  count = 0;

  dialog1 = !!this.dialog;
  dialog2 = false;
  dialog3 = false;
  isProcessing = false;

  errorCodes: unknown[] = [];

  onClick(): void {
    this.remainingQuantityBefore = this.coupon.remainingQuantity;
    this.toggleDialog(1);

    this.$dataLayer.push({
      coupon_title: this.coupon.name,
      event: 'coupon-click'
    });
  }

  toggleDialog(n?: DialogNumber): void {
    this.dialog1 = false;
    this.dialog2 = false;
    this.dialog3 = false;
    switch (n) {
      case 1:
        this.dialog1 = true;
        break;
      case 2:
        this.dialog2 = true;
        break;
      case 3:
        this.dialog3 = true;
        break;
    }
  }

  back(n: DialogNumber): void {
    switch (n) {
      case 1:
        this.toggleDialog();
        break;
      case 2:
        this.toggleDialog(1);
        break;
      case 3:
        // in reality, the code never comes here
        this.toggleDialog();
        break;
    }
  }

  async forward(n: DialogNumber, arg: unknown): Promise<void> {
    switch (n) {
      case 1:
        // expect one arg of type number (count)
        (() => {
          const count = arg;
          if (typeof count !== 'number') {
            this.toggleDialog();
            return;
          }
          this.count = count;
          this.toggleDialog(2);
        })();
        break;
      case 2:
        // expect one arg of type string (expenditureCode)
        await (async () => {
          const expenditureCode = arg;
          if (typeof expenditureCode !== 'string') {
            this.toggleDialog();
            return;
          }
          if (!this.coupon.authorize(expenditureCode)) {
            this.errorCodes = [E_CODE_INVALID_EXPENDITURE_CODE];
            return;
          }
          try {
            await this.repository.use(
              this.coupon.id,
              this.count,
              this.coupon.remainingQuantity,
              expenditureCode
            );
            this.errorCodes = [];
            this.$emit('done', this.coupon.id, this.count);
            this.toggleDialog(3);
          } catch (error) {
            this.errorCodes = [error];
          }
        })();
        this.isProcessing = false;
        break;
      case 3:
        this.toggleDialog();
        break;
    }
  }
}
