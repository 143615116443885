// セブン銀行システム メンテナンス開始時刻 (日本時間 00:00)
export const SEVENBANK_MAINTENANCE_START_HOUR_UTC = 15;

// セブン銀行システム メンテナンス終了時刻 (日本時間 06:00)
export const SEVENBANK_MAINTENANCE_STOP_HOUR_UTC = 21;

// モバイル PASMO システム メンテナンス開始時刻
export const MOBILE_PASMO_MAINTENANCE_START = new Date(
  '2000-01-01T00:00:00+09:00'
);

// モバイル PASMO システム メンテナンス終了時刻
export const MOBILE_PASMO_MAINTENANCE_STOP = new Date(
  '2000-01-01T00:00:00+09:00'
);

//idTokenのサブスク状態取得用Key
export const INFO_SUBSCRIPTION_CLAIMKEY = 'https://one-odakyu.com/subscription';

// モバイルPASMOチャージ SF残高上限
export const MOBILE_PASMO_BALANCE_LIMIT = 20000;

// MR: https://ode.futurefraqta.io/gitlab/platform/sources/web-app/-/merge_requests/1726
// 政府の激変緩和措置によるガスの値引き金額と対象期間
export const GAS_DISCOUNT_AMOUNT_JPY: { [key: string]: number } = {
  '202302': 30,
  '202303': 30,
  '202304': 30,
  '202305': 30,
  '202306': 30,
  '202307': 30,
  '202308': 30,
  '202309': 30,
  '202310': 15,
  '202311': 15,
  '202312': 15,
  '202401': 15,
  '202402': 15,
  '202403': 15,
  '202404': 15,
  '202405': 15,
  '202406': 7.5,
  '202409': 17.5,
  '202410': 17.5,
  '202411': 10,
  '202502': 10,
  '202503': 10,
  '202504': 5
};

// サブスクリプション 特定商取引法 コンテンツID
export const ACT_ON_SCT_ID = 'tokushohou';

// サブスクパッケージ パッケージ終了日間近の契約の更新有無・請求パターン
export const SUBSCRIPTION_PACKAGE_NOT_UPDATE = '更新なし';
export const SUBSCRIPTION_PACKAGE_UPDATE_AND_PAY_ALL =
  '更新あり/お客さま支払（全額）';
export const SUBSCRIPTION_PACKAGE_UPDATE_AND_FOR_FREE = '更新あり/OER負担';

// サブスク契約が終了する時間
export const SUBSCRIPTION_CONTRACT_END_HOUR = 6;
