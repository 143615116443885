
















































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import ContactPanel from '@/components/ContactPanel.vue';
import StartNav from '@/components/StartNav.vue';

@Component({
  components: {
    ContactPanel,
    StartNav
  }
})
export default class P0905 extends Vue {
  private signup() {
    this.$auth.loginWithRedirect({ initialDisplay: 'signup' });
  }
}
