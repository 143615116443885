import { render, staticRenderFns } from "./MyPageMenuTiles.vue?vue&type=template&id=69162b35&"
import script from "./MyPageMenuTiles.vue?vue&type=script&lang=ts&"
export * from "./MyPageMenuTiles.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports