
































































































































































































import OpExchangeFooter from '@/components/OdakyuPoint/OpExchange/OpExchangeFooter.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { OpExchangeItem } from '@/models/OpExchangeItem';
import { OpExchangeItemCmsRepository } from '@/repositories/OpExchangeItemCmsRepository';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

@Component({ components: { OpExchangeFooter } })
export default class P0246 extends Vue {
  readonly repo = new OpExchangeItemCmsRepository(
    this.$store.state.isSP,
    this.$store.state.supportsWebP
  );

  readonly ITEMS_PER_PAGE = 24; // 1 ページあたりの表示件数
  readonly ORDER_NEW_AND_DEFAULT_MICROCMS_ORDER_ASC = 'system:default'; // 店舗順（Newマーク対象をTOPに表示し、それ以降はmicroCMSの表示順序）
  readonly ORDER_PUBLISHED_AT_DESC = '-publishedAt'; // 新着順
  readonly ORDER_POINT_AMOUNT_ASC = 'pointAmount'; // ポイント数・昇順
  readonly ORDER_POINT_AMOUNT_DESC = '-pointAmount'; // ポイント数・降順
  readonly CATEGORIES = [
    'グルメ・ホテル',
    'キッチングッズ・電化製品',
    'ヘルス＆ビューティー',
    'アウトドア・ゴルフ',
    '鉄道グッズ',
    '防災用品',
    'ペット商品',
    '電子特急券・タクシー利用券',
    'デジタルチケット',
    'その他'
  ];
  readonly NEW_CONTENT_PERIOD = 7; // microCMS コンテンツが new マーク対象となる期間

  pageNo = 1;

  /* microCMS より取得される値 */
  items: OpExchangeItem[] = [];
  totalCount = 0;

  /* microCMS 検索パラメータ */
  category = '';
  orders = this.ORDER_NEW_AND_DEFAULT_MICROCMS_ORDER_ASC;
  filters = '';
  q = '';
  sevenDaysAgoDt = '';

  created() {
    this.getContents();
  }

  async getContents() {
    switch (this.orders) {
      // 「店舗順」選択時のコンテンツ取得
      case this.ORDER_NEW_AND_DEFAULT_MICROCMS_ORDER_ASC:
        await this.handleContentsForNewAndDefaultMicrocmsOrder();
        break;
      default:
        await this.handleContentsForOrdinary();
    }
  }

  // 「店舗順」以外選択時のコンテンツ取得
  async handleContentsForOrdinary() {
    const resp = await this.opExchangeItemCmsRepo.getItems(
      this.ITEMS_PER_PAGE,
      this.ITEMS_PER_PAGE * (this.pageNo - 1),
      this.orders,
      this.category ? `categories[contains]${this.category}` : '',
      this.q
    );
    this.items = resp.items;
    this.totalCount = resp.totalCount;
  }

  async handleContentsForNewAndDefaultMicrocmsOrder() {
    // 現在時刻から new マーク対象期間を差し引いた日時を取得する（UTC）
    this.sevenDaysAgoDt = dayjs()
      .tz('UTC')
      .subtract(this.NEW_CONTENT_PERIOD, 'day')
      .toISOString();

    // new マーク対象コンテンツの取得
    const resNew = await this.getContentsForNewAndDefaultMicrocmsOrder(
      this.ITEMS_PER_PAGE,
      this.ITEMS_PER_PAGE * (this.pageNo - 1),
      true
    );

    // new マーク以外のコンテンツを取得
    const resNotNew = await this.getContentsForNewAndDefaultMicrocmsOrder(
      this.ITEMS_PER_PAGE - resNew.items.length,
      this.calculateNotNewContentsOffset(resNew.totalCount),
      false
    );
    this.totalCount = resNew.totalCount + resNotNew.totalCount;
    this.items = resNew.items.concat(resNotNew.items);
  }

  @Watch('category')
  @Watch('orders')
  async onChangeSearchCondition() {
    this.pageNo = 1;
    await this.getContents();
    window.scrollTo(0, 0);
  }

  @Watch('pageNo')
  async onChangePageNo() {
    await this.getContents();
    window.scrollTo(0, 0);
  }

  prevPage() {
    if (this.isPrevPageClickable) {
      this.pageNo--;
    }
  }

  nextPage() {
    if (this.isNextPageClickable) {
      this.pageNo++;
    }
  }

  get opExchangeItemCmsRepo() {
    return this.repo;
  }

  get isPrevPageClickable(): boolean {
    return this.pageNo > 1;
  }

  get isNextPageClickable(): boolean {
    return this.pageNo < this.totalPageNo;
  }

  get totalPageNo(): number {
    return Math.ceil(this.totalCount / this.ITEMS_PER_PAGE);
  }

  async getContentsForNewAndDefaultMicrocmsOrder(
    limit: number,
    offset: number,
    isNew: boolean
  ) {
    // 「店舗順」ソート選択時のクエリ組み立て
    const dateQuery = `publishedAt[${isNew ? 'greater_than' : 'less_than'}]${
      this.sevenDaysAgoDt
    }${isNew ? `[or]publishedAt[equals]${this.sevenDaysAgoDt}` : ''}`;
    // カテゴリ検索と結合
    const filterQuery = this.category
      ? `categories[contains]${this.category}[and]${dateQuery}`
      : dateQuery;

    // microCMS よりコンテンツ取得
    return await this.opExchangeItemCmsRepo.getItems(
      limit,
      offset,
      isNew ? '-publishedAt' : this.orders,
      filterQuery,
      this.q
    );
  }

  // new マーク対象外のコンテンツ取得時の offset 計算処理
  calculateNotNewContentsOffset(newTotalCount: number): number {
    return Math.max(0, (this.pageNo - 1) * this.ITEMS_PER_PAGE - newTotalCount);
  }
}
